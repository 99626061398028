import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function ShowMeetingTimeComponent({ meeting = {} }) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Typography variant="caption" variantMapping={{ caption: "span" }}>
        {format(
          new Date(meeting?.meetingJSON.start),
          getDateTimeFormat({
            startDate: meeting?.meetingJSON.start,
            endDate: meeting?.meetingJSON.end,
            datePosition: 1,
            dateContext: "meeting",
          })
        )}{" "}
        -{" "}
      </Typography>{" "}
      <Typography variant="caption" variantMapping={{ caption: "span" }}>
        {format(
          new Date(meeting?.meetingJSON.end),
          getDateTimeFormat({
            startDate: meeting?.meetingJSON.start,
            endDate: meeting?.meetingJSON.end,
            datePosition: 2,
            dateContext: "meeting",
          })
        )}
      </Typography>
    </Box>
  );
}
