// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------
import { alpha } from "@mui/material/styles";
export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z12,
          backgroundColor: theme.palette.grey[0],
          color: theme.palette.text.primary,
          paddingTop: 0,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      },
    },
  };
}
