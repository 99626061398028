import React, { useState } from "react";

const context = React.createContext({
  errorMessage: "",
  updateErrorMessage: (_errorMessage) => null,
});

export function getErrorContext() {
  return context;
}

export default function ChimeErrorHandlerProvider({ children }) {
  const [errorMessage, setErrorMessage] = useState("");
  const ChimeErrorHandlerContext = getErrorContext();

  const updateErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const providerValue = {
    errorMessage,
    updateErrorMessage,
  };
  return (
    <ChimeErrorHandlerContext.Provider value={providerValue}>
      {children}
    </ChimeErrorHandlerContext.Provider>
  );
}
