// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Box, Typography, List, Button, Paper, Chip } from "@mui/material";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  notificationTemplate,
  supportedNotificationTemplateList,
} from "blocks/views/Notifications/foundations";

import {
  useUserInfo,
  useOrgsAndSuitesForUser,
  useNotifications,
  useAppContext,
  getCreatedDateFromOrgsAndSuites,
  useHandleNotificationsActions,
} from "@app21/core";
import { Done } from "@mui/icons-material";
import { Timeline } from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { LoadingSpinner } from "blocks/atoms";
import toast from "react-hot-toast";

const SuiteActivityTimeline = ({ viewMode }) => {
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();

  const {
    data: loggedInUser,
    status: loggedInUserStatus,
    apiError: loggedInUserApiError,
  } = useUserInfo();
  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(loggedInUser?._id);
  const {
    data: notifications,
    status: notificationsStatus,
    apiError: notificationsApiError,
  } = useNotifications({
    orgsAndSuites: orgsAndSuites,
  });

  const dataFetchError =
    loggedInUserApiError || orgsAndSuitesApiError || notificationsApiError;
  const dataFetchLoading =
    loggedInUserStatus === "loading" ||
    orgsAndSuitesStatus === "loading" ||
    notificationsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const [hasMoreFlag, setHasMoreFlag] = React.useState(true);
  const numOfItemsToBeFetchedOnLoad = 5;
  const initialNumOfItems = 10;

  const { handleNotificationsActions, clearNotificationsStatus } =
    useHandleNotificationsActions();
  let notificationsCount = 0;

  const filteredSuiteNotifications =
    notifications?.suites
      ?.find((suite) => suite.id === selectedSuiteId)
      ?.notifications.reverse() ?? [];

  let suiteCreatedDate = React.useMemo(
    () =>
      getCreatedDateFromOrgsAndSuites({
        userId: loggedInUser._id,
        orgsAndSuites,
        organizationId: selectedOrganizationId,
        suiteId: selectedSuiteId,
        viewContext: "suite",
      }),
    [orgsAndSuites, selectedSuiteId, selectedOrganizationId, loggedInUser._id]
  );
  const additionalFilteredSuiteNotifications =
    filteredSuiteNotifications?.filter(
      (notification) =>
        new Date(notification.createdAt).getTime() >
        new Date(suiteCreatedDate).getTime()
    ) ?? [];

  const [shownActivitiesArray, setShownActivitiesArray] = React.useState(
    additionalFilteredSuiteNotifications?.slice(0, initialNumOfItems) ?? []
  );

  function fetchMoreActivities() {
    if (additionalFilteredSuiteNotifications) {
      if (
        shownActivitiesArray.length >=
          additionalFilteredSuiteNotifications.length ||
        shownActivitiesArray.length >= 200
      ) {
        setHasMoreFlag(false);
        return;
      }
      setShownActivitiesArray((prevArray) => [
        ...prevArray,
        ...(additionalFilteredSuiteNotifications?.slice(
          prevArray.length,
          prevArray.length + numOfItemsToBeFetchedOnLoad
        ) ?? []),
      ]);
    }
  }
  const handleClearAllNotifications = async () => {
    await handleNotificationsActions(
      {
        action: "CLEAR-NOTIFICATIONS",
        notifications: notifications,
        viewContext: "suite",
      },
      null,
      null
    );
  };

  if (dataFetchLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Box
      sx={{
        m: 0,
        pb: 2,
        width: "100%",
        maxHeight: "60vh",
      }}
    >
      <PerfectScrollbar onYReachEnd={fetchMoreActivities} options={{}}>
        {shownActivitiesArray?.length > 0 ? (
          <>
            <Timeline
              sx={{
                m: 0,
                p: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {shownActivitiesArray?.map((notification, index) => {
                let TemplateName = supportedNotificationTemplateList.includes(
                  notification.notificationKey
                )
                  ? notificationTemplate[notification.notificationKey]
                  : notificationTemplate["default"];

                return (
                  <TemplateName
                    key={index}
                    notification={notification}
                    viewContext={"timeline"}
                    index={index}
                  />
                );
              })}
            </Timeline>
            {!hasMoreFlag && (
              <Chip
                variant="outlined"
                size="small"
                label={<Done fontSize="small" color="success" />}
                sx={{ marginLeft: 17 }}
              />
            )}
          </>
        ) : (
          <Typography variant="body2" sx={{ textAlign: "center", my: 3 }}>
            No activities recorded yet
          </Typography>
        )}
      </PerfectScrollbar>

      {notificationsCount > 3 &&
        viewMode === "popup" &&
        (clearNotificationsStatus?.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Paper
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <Button onClick={handleClearAllNotifications}>Clear All</Button>
          </Paper>
        ))}
    </Box>
  );
};

export default SuiteActivityTimeline;
