import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getFileIcon, Label, UserAvatar } from "blocks/atoms";
import { format } from "date-fns";
import { DurationIcon } from "blocks/atoms/trrysticons";
import { msToTime } from "utils/timeHelperUtils";
import { ExpandMore } from "@mui/icons-material";
import path from "path";
import { getDateTimeFormat } from "utils/getDateFormats";
const FileActivityLogSnippet = ({ fileLog = {}, isSmallSize = true }) => {
  const fileEventType = fileLog.eventType?.split(":")[2];

  const getSnippetTitle = (fileLog) => {
    if (fileLog.data && fileLog?.data?.length > 0) {
      switch (fileEventType) {
        case "Read":
          return fileLog?.documentName;
        case "Create":
          if (fileLog?.data?.length > 1) {
            return `Added ${fileLog?.data?.length} file(s)`;
          } else {
            return `Added ${fileLog?.data[0]?.documentName}`;
          }
        case "Rename":
          if (fileLog?.data?.length > 1)
            return `Renamed ${fileLog?.data?.length} files`;
          else if (fileLog?.data?.length === 1)
            return (
              <>
                <Typography>
                  {getFileIcon({
                    fileExt: path
                      .extname(path.basename(fileLog?.data[0]?.data.key))
                      .replace(".", ""),
                    size: "lg",
                  })}{" "}
                  Renamed {path.basename(fileLog?.data[0]?.data.oldKey)} to{" "}
                  {path.basename(fileLog?.data[0]?.data.key)}
                </Typography>
              </>
            );

        case "Delete":
          if (fileLog?.data?.length > 1) {
            return `Deleted ${fileLog?.data?.length} file(s)`;
          } else {
            return `Deleted ${fileLog?.data[0]?.data?.documentName}`;
          }
        default:
          return `Changed ${fileLog?.data?.length} file(s)`;
      }
    }
  };
  const getDetailsSnippet = (fileData) => {
    const eventType = fileData.eventType?.split(":")[2];
    const documentName =
      fileData?.data?.documentName || path.basename(fileData?.data?.key);
    switch (eventType) {
      case "Rename":
        return (
          <Box sx={{ display: "flex", py: 2 }}>
            {getFileIcon({
              fileExt: path.extname(documentName).replace(".", ""),
              size: "lg",
            })}{" "}
            <Chip color="error" label={path.basename(fileData?.data?.oldKey)} />
            <ArrowForwardIcon fontSize="small" />
            <Chip color="success" label={documentName} />
          </Box>
        );
      case "Create":
        return (
          <Box sx={{ display: "flex", py: 2 }}>
            {getFileIcon({
              fileExt: path.extname(documentName).replace(".", ""),
              size: "lg",
            })}{" "}
            <Typography>{documentName}</Typography>
          </Box>
        );
      case "Delete":
        return `Deleted ${documentName}`;
      case "Delete":
        return `Read ${documentName}`;
    }
  };

  const getEventLabel = (fileEventType) => {
    switch (fileEventType) {
      case "Read":
        return "Viewed";
      case "Create":
        return "Added";
      case "Rename":
        return "Updated";
      case "Delete":
        return "Deleted";
      default:
        return "Changed";
    }
  };
  const MainWrapperComponent =
    fileLog.data && fileLog.data.length > 1 ? Accordion : Card;

  return (
    <MainWrapperComponent sx={{ my: 1, p: 0 }}>
      <AccordionSummary
        sx={{ p: 0, position: "relative" }}
        {...(fileLog.data && fileLog.data.length > 1 && fileEventType !== "Read"
          ? {
              expandIcon: (
                <ExpandMore
                  sx={{ mr: 3, mt: 1, color: "gray.main", fontSize: 14 }}
                />
              ),
            }
          : {})}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CardHeader
            avatar={<UserAvatar userId={fileLog.userId} />}
            title={getSnippetTitle(fileLog)}
            titleTypographyProps={{
              sx: {
                maxWidth: 150,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              },
            }}
            subheader={format(
              new Date(fileLog.date ?? null),
              getDateTimeFormat({
                startDate: fileLog.date,
                noTimeFlag: true,
              })
            )}
            sx={{ p: 1 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            top: 5,
            right: 5,
          }}
        >
          <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
            {getEventLabel(fileEventType)}
          </Typography>
          {fileEventType === "Read" &&
            Boolean(Number(fileLog.totalReadDuration)) && (
              <Label
                variant="ghost"
                color="info"
                sx={{ px: 2, py: 0.5, mb: 1 }}
              >
                {!isSmallSize && <DurationIcon sx={{ fontSize: 10 }} />}
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {msToTime(
                    Number(fileLog.totalReadDuration),
                    fileLog.totalReadDuration < 60000
                  )}
                </Typography>
              </Label>
            )}
        </Box>
      </AccordionSummary>
      {fileLog.data && fileLog?.data?.length > 1 && fileEventType !== "Read" && (
        <AccordionDetails>
          <ul>
            {fileLog.data.map((file, index) => (
              <li key={index}>{getDetailsSnippet(file)}</li>
            ))}
          </ul>
        </AccordionDetails>
      )}
    </MainWrapperComponent>
  );
};

export default React.memo(FileActivityLogSnippet);
