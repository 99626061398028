// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useEffect } from "react";

import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus,
  useLogger,
} from "amazon-chime-sdk-component-library-react";
import { useVideocallContext } from "../VideocallProvider";

const useMeetingEndRedirect = () => {
  const logger = useLogger();
  const dispatch = useNotificationDispatch();
  const meetingStatus = useMeetingStatus();
  const { setChimeRoute } = useVideocallContext();

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      logger.info("[useMeetingEndRedirect] Meeting ended");
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: "The meeting was ended by another attendee",
          autoClose: true,
          replaceAll: true,
        },
      });
      setChimeRoute("ended");
    }
  }, [meetingStatus]);
};

export default useMeetingEndRedirect;
