import React, { useContext, useMemo, useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import path from "path";
import {
  selectedFilesListAtom,
  selectedFileActionAtom,
  selectedUploadActionAtom,
  viewFilesContextAtom,
  aiPromptDialogOpenAtom,
} from "providers/FilesProvider/files-atoms";
import { useQueryClient } from "react-query";
import _ from "lodash";
import {
  useCreateFolderS3,
  useUploadFilesS3,
  useDeleteFilesS3,
  useMoveFilesS3,
  useRenameFilesS3,
  useDocketsInSuite,
  useUsersInSuite,
  useAppContext,
  checkIfNull,
  useFilesS3Graphql,
  useSuite,
  useDockets,
  executeApi,
  useHandleRouterPush,
  useSelectedBucketId,
  FileQueryBuilder,
  useHandleAddRemoveIdFromRoute,
  app21APIInstance,
} from "@app21/core";

import { useMediaQuery, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { DialogFormSingleTextField } from "blocks/atoms/uicomponents/textfields";
import DownloadFilesDialog from "blocks/atoms/DownloadFilesDialog";
import toast from "react-hot-toast";
import { constructTrrystFileMap } from "hooks";
import {
  constructFolderChain,
  reservedFolderNames,
  docketsDeepReplaceRecursive,
  MoveFilesDialog,
} from "blocks/modules/TrrystFiles/foundations";

import { UploadOmniModal } from "blocks/atoms/uicomponents/upload";
import { SIZELIMITS } from "blocks/atoms/constants";
import {
  getFileOrFoldernameFromS3Key,
  isFileViewerSupportedExtension,
  specialCharactersList,
} from "utils/fileHelperUtils";

// import { uploadFilesUsingSignedUrlToS3 } from "../../utils/uploadFileS3";

const FilesContext = React.createContext({});
export const useFilesContext = () => {
  const state = useContext(FilesContext);
  if (!state) {
    throw new Error("useFilesContext must be used within FilesProvider");
  }
  return state;
};
const FilesProvider = ({
  viewContext = "suite",
  isMobile = false,
  children,
}) => {
  const {
    meetingId,
    userInfo,
    chimeViewMode,
    viewFile,
    s3Prefix,
    selectedSuiteId,
    selectedOrganizationId,
    selectedDocketId,
    checkAccessRules,
    choice,
    setAsyncRequestStates,
  } = useAppContext();
  // const {} = useAppContext();
  let originalS3KeyFromRoute = null;
  const queryClient = useQueryClient();

  const router = useRouter();
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();
  const { loadRoute } = useHandleRouterPush();
  const confirm = useConfirm();

  const fileQueryBuilder = useMemo(() => new FileQueryBuilder.default(), []);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [aiPromptDialogOpen, setAiPromptDialogOpen] = useAtom(
    aiPromptDialogOpenAtom
  );
  const [uploadNotify, setUploadNotify] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadedFiles, setDownloadedFiles] = useState(false);
  const [moveFilesDialogOpen, setMoveFilesDialogOpen] = useState(false);
  const [createResourceDialogOpen, setCreateResourceDialogOpen] =
    useState(false);
  const [resourceName, setResourceName] = useState({ name: "", isDir: false });
  const [renameInfo, setRenameInfo] = useState(null);

  const createFolderS3 = useCreateFolderS3();
  const moveFilesS3 = useMoveFilesS3();
  const uploadFilesS3 = useUploadFilesS3();
  const deleteFilesS3 = useDeleteFilesS3();
  const renameFilesS3 = useRenameFilesS3();

  const fileViewerFlag = !checkIfNull(viewFile);
  let decodedS3Prefix = null;

  if (decodeURIComponent(s3Prefix)?.endsWith("/")) {
    decodedS3Prefix = decodeURIComponent(s3Prefix);
  } else {
    decodedS3Prefix = `${path.dirname(decodeURIComponent(s3Prefix))}/`;
    originalS3KeyFromRoute = decodeURIComponent(s3Prefix);
  }

  const [selectedFilesList, setSelectedFilesList] = useAtom(
    selectedFilesListAtom
  );
  const [selectedFileAction, setSelectedFileAction] = useAtom(
    selectedFileActionAtom
  );
  const [selectedUploadAction, setSelectedUploadAction] = useAtom(
    selectedUploadActionAtom
  );
  const [, setViewFilesContext] = useAtom(viewFilesContextAtom);
  React.useEffect(() => {
    setViewFilesContext(
      selectedDocketId || decodedS3Prefix?.includes("/dockets/")
        ? "dockets"
        : "suites"
    );
  }, []);
  const { data: docketsInSuite } = useDocketsInSuite(selectedSuiteId);
  const { data: selectedSuite } = useSuite(selectedSuiteId);
  const { data: selectedDocket } = useDockets(selectedDocketId);

  const isCreator =
    viewContext === "DOCKET"
      ? selectedDocket?.createdBy === userInfo._id
      : false;
  const isInvitee =
    viewContext === "DOCKET"
      ? (selectedDocket?.members ?? []).includes(userInfo?._id)
      : false;
  const selectedBucketId = useSelectedBucketId(null, true);
  const isDocket = viewContext === "DOCKET";
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;

  const { accessFlag: canAddNewFiles } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "ADD-FILES-INFOPACK" : "ADD-FILES-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });

  const { accessFlag: canAddNewFolders } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "ADD-FOLDERS-INFOPACK" : "ADD-FOLDERS-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const { accessFlag: canOrganizeFiles } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "ORGANIZE-FILES-INFOPACK" : "ORGANIZE-FILES-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });

  const { accessFlag: canDeleteFiles } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "DELETE-FILES-INFOPACK" : "DELETE-FILES-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const { accessFlag: canViewFiles } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "VIEW-FILES-INFOPACK" : "VIEW-FILES-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const { accessFlag: canDownloadFiles } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "DOWNLOAD-FILES-INFOPACK" : "DOWNLOAD-FILES-DATAROOM",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const { accessFlag: canUseAI } = checkAccessRules({
    entity: isDocket ? "SUITE" : "SUITE",
    action: isDocket ? "CAN-INVOKE-FILES-AI" : "CAN-INVOKE-FILES-AI",
    featureName: isDocket ? "DOCKET-INFOPACK" : "DATAROOM",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const canRenameFiles = canDeleteFiles;
  const canShareFiles = canDeleteFiles;
  const filesPermissions = React.useMemo(
    () => ({
      canAddNewFiles,
      canAddNewFolders,
      canDeleteFiles,
      canViewFiles,
      canDownloadFiles,
      canRenameFiles,
      canShareFiles,
      canUseAI,
      canOrganizeFiles,
      canDownloadFilesSuitePreference:
        (isDocket
          ? !Boolean(selectedDocket?.settings?.protectSuiteFilesDownload)
          : !Boolean(selectedSuite?.settings?.protectSuiteFilesDownload)) ??
        true,
      default: true,
    }),
    [
      canAddNewFiles,
      canAddNewFolders,
      canDeleteFiles,
      canDownloadFiles,
      canRenameFiles,
      canShareFiles,
      canUseAI,
      canViewFiles,
      canOrganizeFiles,
      selectedDocket?.settings,
      selectedSuite?.settings,
      isDocket,
    ]
  );

  useEffect(() => {
    if (viewContext === "DOCKET") {
      fileQueryBuilder.setId("docket", [`${selectedDocketId}`]);
    } else {
      fileQueryBuilder.setId("suite", [`${selectedSuiteId}`]);
    }
  }, [fileQueryBuilder, selectedDocketId, selectedSuiteId, viewContext]);

  const { data: allS3Keys, status: allS3KeysStatus } = useFilesS3Graphql(
    {},
    []
  );
  React.useEffect(() => {
    if (choice === "aiSummary") {
      setSelectedFilesList([originalS3KeyFromRoute]);
      setSelectedFileAction("generate_ai_summary");
      setAiPromptDialogOpen(true);
    }
  }, [choice]);

  React.useEffect(() => {
    const createFolder = async () => {
      await createFolderS3.mutate({
        key: decodedS3Prefix,
        bucket: selectedBucketId,
        s3Prefix: decodedS3Prefix,
      });
    };
    if (selectedDocketId && allS3Keys.listOfAllKeys) {
      // check if the s3 key corresponding to this docket exists.
      // should ideally be already there, unless there is a inconsistency
      // just create it if it is missing.

      // this code will also be triggered if the user deletes a docket folder
      // completely.
      const isDocketKeyFound = allS3Keys.listOfAllKeys.find(
        (item) => item.Key === decodedS3Prefix
      );
      if (!isDocketKeyFound) createFolder();
    }
  }, [selectedDocketId]);

  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const xsSize = useMediaQuery("(min-width:700px)");
  const mdSize = useMediaQuery("(min-width:1200px)");

  const smallSizeFlag =
    !xsSize || (!mdSize && chimeViewMode === "normal" && Boolean(meetingId));

  const clipTextLength = smallSizeFlag || viewContext !== "DOCKET" ? 75 : 100;

  const folderChain = React.useMemo(
    () =>
      constructFolderChain({
        decodedS3Prefix,
        filesPermissions,
        viewContext,
        selectedOrganizationId,
        selectedSuite,
        selectedSuiteId,
        docketsInSuite,
        selectedDocketId,
        clipTextLength,
      }),
    [
      clipTextLength,
      decodedS3Prefix,
      docketsInSuite,
      selectedDocketId,
      selectedOrganizationId,
      selectedSuite,
      selectedSuiteId,
      viewContext,
      filesPermissions,
    ]
  );
  const [shortURLSelectedFileData, setShortURLSelectedFileData] =
    React.useState(null);
  const [shortURLModalOpen, setShortURLModelOpen] = React.useState(false);

  const updatedTreeKeys = React.useMemo(
    () =>
      allS3Keys?.treeKeys &&
      docketsDeepReplaceRecursive(
        "dockets",
        "Breakouts",
        JSON.parse(allS3Keys.treeKeys),
        docketsInSuite
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allS3Keys]
  );
  const s3Files = React.useMemo(
    () =>
      constructTrrystFileMap(
        allS3Keys.listOfAllKeys,
        decodedS3Prefix,
        docketsInSuite,
        userInfo
      ),
    [allS3Keys.listOfAllKeys, decodedS3Prefix, docketsInSuite, userInfo]
  );
  const resetActionsList = () => {
    setSelectedFilesList([]);
    setSelectedFileAction("");
    setSelectedUploadAction("");
  };
  const handleRefreshFilesData = () => {
    queryClient.invalidateQueries(["allS3Files", `${selectedSuiteId}`], {
      refetchInactive: true,
    });
  };

  const handleAiDialogClose = () => {
    resetActionsList();
    router.push(removeIdFromRoute({ paramKey: "choice" }), { shallow: true });
    setAiPromptDialogOpen(false);
  };

  const handleDeleteFiles = async (filesList) => {
    if (selectedFilesList?.some((file) => file?.endsWith("dockets/"))) {
      toast.error("You cannot delete Breakouts as it is a System folder");
    } else if (!checkIfNull(selectedFilesList)) {
      confirm({
        content: (
          <div style={{ padding: 10 }}>
            <Typography variant="body1" sx={{ my: 2 }}>
              The following resources will be deleted. Are you sure you wish to
              proceed?
            </Typography>
            <ul style={{ marginLeft: 10 }}>
              {selectedFilesList.map((file, index) => (
                <li key={index}>{getFileOrFoldernameFromS3Key(file)}</li>
              ))}
            </ul>
          </div>
        ),
      })
        .then(async () => {
          deleteFilesS3.mutate({
            bucket: selectedBucketId,
            filesList: selectedFilesList,
            s3Prefix: `${path.dirname(selectedFilesList[0])}/`,
          });
          if (deleteFilesS3.others.isSuccess) {
            toast.success("Files Deleted");
          } else if (deleteFilesS3.others.isError) {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        })
        .finally(() => {
          resetActionsList();
        });
    }
  };
  const checkFileNamesOverlap = (newFileNamesList) => {
    let currentS3FilenamesList = s3Files.map((file) => file.name) ?? [];
    let commonFilesList =
      _.intersection(newFileNamesList, currentS3FilenamesList) ?? [];

    return Boolean(commonFilesList.length > 0);
  };
  const handleRenameFileSubmit = async (data) => {
    const checkFileNamesOverlapFlag = checkFileNamesOverlap([data.inputText]);
    if (checkFileNamesOverlapFlag) {
      confirm({
        description:
          "There are files/folders in the current directory that have the same name. If you proceed, those files/folders will be overwritten. Please confirm to proceed?",
      })
        .then(async () => {
          if (data.inputText) {
            if (renameInfo.endsWith("/")) {
              // trying to rename a directory
              const split = renameInfo.split("/");
              const path = split.slice(0, split.length - 2).join("/");
              await moveFilesS3.mutate({
                fileList: [renameInfo],
                bucket: selectedBucketId,
                targetPrefix: `${path}/${data?.inputText}/`,
                s3Prefix: decodedS3Prefix,
                toast,
              });
            } else {
              await renameFilesS3.mutate({
                bucket: selectedBucketId,
                oldKey: renameInfo,
                newKey: `${path.dirname(renameInfo)}/${data?.inputText}`,
                s3Prefix: decodedS3Prefix,
              });
            }
          }
        })
        .catch(() => {
          console.log("Rename cancelled.");
        })
        .finally(() => {
          setCreateResourceDialogOpen(false);
          setResourceName({ name: "", isDir: false });
          setRenameInfo(null);
          resetActionsList();
        });
    } else {
      if (data.inputText) {
        if (renameInfo.endsWith("/")) {
          // trying to rename a directory
          const split = renameInfo.split("/");
          const path = split.slice(0, split.length - 2).join("/");
          await moveFilesS3.mutate({
            fileList: [renameInfo],
            bucket: selectedBucketId,
            targetPrefix: `${path}/${data?.inputText}/`,
            s3Prefix: decodedS3Prefix,
            toast,
          });
        } else {
          await renameFilesS3.mutate({
            bucket: selectedBucketId,
            oldKey: renameInfo,
            newKey: `${path.dirname(renameInfo)}/${data?.inputText}`,
            s3Prefix: decodedS3Prefix,
          });
        }
      }
      setCreateResourceDialogOpen(false);
      setResourceName({ name: "", isDir: false });
      setRenameInfo(null);
      resetActionsList();
    }
  };

  const handleCreateFolder = async (folderName) => {
    setCreateResourceDialogOpen(true);
  };
  const handleCreateFolderSubmit = async (data) => {
    await createFolderS3.mutate({
      key: `${decodedS3Prefix}${data?.inputText}/`,
      bucket: selectedBucketId,
      s3Prefix: decodedS3Prefix,
    });
    setCreateResourceDialogOpen(false);
    setResourceName({ name: "", isDir: false });
    resetActionsList();
  };

  const getMembersToNotifyList = React.useCallback(() => {
    if (!viewContext) return [];
    switch (viewContext) {
      case "SUITE": {
        if (!usersInSuite) return [];
        return usersInSuite.map((e) => e._id);
      }
      case "DOCKET": {
        if (!selectedDocket || !selectedDocket.members) return [];
        return selectedDocket.members;
      }
    }
  }, [selectedDocket, usersInSuite, viewContext]);

  const handleUploadNotify = (notifyPreference) => {
    setUploadNotify(notifyPreference);
  };

  const handleUploadFiles = async (uploadFiles) => {
    // at this point the files have actually all been uploaded in the lower components, hence only
    // the MongoDB update and pubnub request function is triggered here.
    // await uploadFilesUsingSignedUrlToS3(
    //   uploadFiles,
    //   decodedS3Prefix,
    //   selectedBucketId,
    //   userInfo._id
    // );
    await uploadFilesS3.mutate({
      fileList: uploadFiles.map((f) => f.name), // just name here, actual file upload via SignedUrl
      bucket: selectedBucketId,
      key: decodedS3Prefix !== "/" ? decodedS3Prefix : "",
      notify: uploadNotify ? "suite" : null,
      s3Prefix: decodedS3Prefix,
      channels: ["EMAIL", "INTERNAL"], // TODO: get this from user
      membersToNotify: getMembersToNotifyList(),
    });

    setUploadDialogOpen(false);
  };

  const handleMoveSelectedFiles = async (targetPrefix) => {
    await moveFilesS3.mutate({
      fileList: selectedFilesList,
      bucket: selectedBucketId,
      targetPrefix,
      s3Prefix: decodedS3Prefix,
      toast,
    });
    resetActionsList();
  };

  const handleMoveDialogClose = () => {
    setMoveFilesDialogOpen(false);
    resetActionsList();
  };

  const fetchDownloadFileUrl = React.useCallback(
    async (file, protectFlag, protectText) => {
      let downloadUrlResponse = protectFlag
        ? await executeApi("PROTECT-FILE-S3", {
            bucket: selectedBucketId,
            key: file.id,
            calledBy: userInfo._id,
          })
        : await executeApi("FETCH-SIGNED-URL", {
            bucket: selectedBucketId,
            key: file.id,
            urlTypeRequested: "get",
            contentDisposition: "attachment",
            contentType: file.fileType, //FIXME: Raghu - can you check this.. content type is not there here..
          });

      return downloadUrlResponse;
    },
    [selectedBucketId, userInfo._id]
  );
  React.useEffect(() => {
    if (selectedDocketId) {
      setViewFilesContext("dockets");
    }
  }, [selectedDocketId]);

  React.useEffect(() => {
    setShortURLModelOpen(shortURLSelectedFileData !== null);
  }, [shortURLSelectedFileData]);

  React.useEffect(() => {
    switch (selectedFileAction) {
      case "open_selection":
        const selectedResource = s3Files.find(
          (file) => file.id === selectedFilesList[0]
        );

        if (!selectedResource?.isDir) {
          const fileName = selectedResource?.name;
          const fileExtension = fileName?.split(".").pop();
          let protectFlag =
            viewContext === "DOCKET"
              ? selectedDocket?.settings?.protectDocketFiles
              : selectedSuite?.settings?.protectSuiteFiles;
          let protectText =
            viewContext === "DOCKET"
              ? selectedDocket?.settings?.protectDocketText
              : selectedSuite?.settings?.protectSuiteText;
          if (isFileViewerSupportedExtension(fileExtension)) {
            if (viewContext === "DOCKET") {
              loadRoute("VIEW-DOCKETFILE", {
                s3Key: selectedResource.id ?? decodedS3Prefix,
                docketId: selectedDocketId,
                tabValue: "files",
              });
            } else {
              loadRoute("VIEW-FILE", {
                s3Key: selectedResource.id ?? decodedS3Prefix,
              });
            }
          } else {
            fetchDownloadFileUrl(selectedResource, protectFlag, protectText)
              .then((downloadUrlResponse) => {
                if (downloadUrlResponse?.signedUrl) {
                  window.open(downloadUrlResponse.signedUrl, "_blank");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          // it means this is a directory

          if (viewContext === "DOCKET") {
            loadRoute("VIEW-DOCKET", {
              s3Key: `${selectedResource.id.replace(/\/*$/, "")}/`,
              docketId: selectedDocketId,
              tabValue: "files",
            });
          } else {
            loadRoute("VIEW-DATAROOM", {
              s3Key: `${selectedResource.id.replace(/\/*$/, "")}/`,
            });
          }
        }
        resetActionsList();
        break;
      case "delete_files":
        handleDeleteFiles();
        break;
      case "rename_file":
        let renameFileInfo = !checkIfNull(selectedFilesList)
          ? s3Files.find((file) => file.id === selectedFilesList[0])
          : null;

        if (renameFileInfo) {
          setResourceName({
            name: renameFileInfo.name,
            isDir: renameFileInfo?.isDir,
          });

          setRenameInfo(selectedFilesList[0]);
          setCreateResourceDialogOpen(true);
        }
        break;
      case "generate_ai_summary":
        setAiPromptDialogOpen(true);
        break;

      case "download_zip":
        if (
          !checkIfNull(selectedFilesList) &&
          Array.isArray(selectedFilesList)
        ) {
          app21APIInstance
            .post("/zip-files-s3", {
              bucket: selectedBucketId,
              s3Keys: selectedFilesList,
              suiteId: selectedSuiteId,
              userId: userInfo._id,
              orgId: selectedOrganizationId,
            })
            .then(() => {
              resetActionsList();
            });
        }
        break;

      case "download_file":
        let fileToDownload =
          Array.isArray(selectedFilesList) && selectedFilesList[0];
        if (!fileToDownload) break;

        const fetchSignedUrl = async (file) => {
          let protectFlag =
            viewContext === "DOCKET"
              ? selectedDocket?.settings?.protectDocketFiles
              : selectedSuite?.settings?.protectSuiteFiles;

          let fileObj = s3Files.find((fileObj) => fileObj.id === file);
          let response;
          if (protectFlag) {
            response = await executeApi("PROTECT-FILE-S3", {
              bucket: selectedBucketId,
              key: file,
              calledBy: userInfo._id,
            });
          } else {
            response = await executeApi("FETCH-SIGNED-URL", {
              bucket: selectedBucketId,
              key: file,
              urlTypeRequested: "get",
              contentDisposition: "attachment",
              contentType: fileObj.fileType, // FIXME : raghu - can you check this.. most likey it needs the mime type rather than the png etc we have now..
            });
          }
          if (response?.signedUrl) {
            window.open(response.signedUrl, "_blank", "noopener noreferrer");
          }
        };
        fetchSignedUrl(fileToDownload);

        resetActionsList();
        break;

      // we are not using the below download_files case anymore and retiring it in favour of zip functionality. But keeping it as it might be useful for another use case in future
      case "download_files":
        let protectFlag =
          viewContext === "DOCKET"
            ? selectedDocket?.settings?.protectDocketFiles
            : selectedSuite?.settings?.protectSuiteFiles;

        let filesToDownload = [];
        setDownloadedFiles(null);
        if (
          !checkIfNull(selectedFilesList) &&
          Array.isArray(selectedFilesList)
        ) {
          filesToDownload = selectedFilesList;
        }

        const getAllSignedUrls = async () => {
          // No more await here

          const responses = filesToDownload.map(async (file) => {
            let response;
            let fileObj = s3Files.find((fileObj) => fileObj.id === file);

            if (file && !fileObj?.isDir) {
              if (protectFlag) {
                response = await executeApi("PROTECT-FILE-S3", {
                  bucket: selectedBucketId,
                  key: file,
                  calledBy: userInfo._id,
                });
              } else {
                response = await executeApi("FETCH-SIGNED-URL", {
                  bucket: selectedBucketId,
                  key: file,
                  urlTypeRequested: "get",
                  contentDisposition: "attachment",
                  contentType: fileObj.fileType, // FIXME : raghu - can you check this.. most likey it needs the mime type rather than the png etc we have now..
                });
              }

              if (response?.signedUrl) {
                return { file: file, url: response.signedUrl };
              }
            } //recursive directory traversing not done. needs to be enhanced in future.
          });

          // Await + Promise.all to wait for all promises to resolve
          const results = await Promise.all(responses);

          if (!checkIfNull(results)) {
            setDownloadedFiles(results);
            setDownloadDialogOpen(true);
          } else {
            toast.error("Sorry. Something went wrong");
          }
        };

        getAllSignedUrls();
        // window.open(response.signedUrl, '_blank');
        resetActionsList();
        break;

      case "move_files": {
        setMoveFilesDialogOpen(true);
        break;
      }

      case "share_files": {
        const selectedFilePayload = {
          keys: selectedFilesList,
          type: "FILES",
        };

        setShortURLSelectedFileData(selectedFilePayload);
        resetActionsList();
        break;
      }
      default:
        break;
    }
  }, [selectedFileAction]);

  React.useEffect(() => {
    switch (selectedUploadAction) {
      case "create_folder":
        handleCreateFolder();
        break;
      case "upload_files":
      case "upload_folder":
      case "copy_from_suite":
        setUploadDialogOpen(true);
        break;

      default:
        break;
    }
  }, [selectedUploadAction]);

  const filesContextValues = {
    s3Files,
    updatedTreeKeys,
    smallSizeFlag,
    viewContext,
    isMobile,
    isCreator,
    isInvitee,
    decodedS3Prefix,
    allS3Keys,
    allS3KeysStatus,
    fileViewerFlag,
    folderChain,
    docketsInSuite,
    shortURLSelectedFileData,
    setShortURLSelectedFileData,
    shortURLModalOpen,
    setShortURLModelOpen,
    loadRoute,
    meetingId,
    s3Prefix,
    xsSize,
    mdSize,
    handleRefreshFilesData,

    handleAiDialogClose,
    resetActionsList,
    filesPermissions,
  };

  return (
    <FilesContext.Provider value={{ ...filesContextValues }}>
      {children}
      <DialogFormSingleTextField
        open={createResourceDialogOpen}
        onClose={() => {
          setCreateResourceDialogOpen(false);
          setRenameInfo(null);
          resetActionsList();
        }}
        fullWidth={true}
        maxWidth="sm"
        sx={{ p: 1 }}
        title=""
        defaultValue={resourceName?.name}
        label={renameInfo ? "New File/Folder name" : "Name of Folder"}
        onSubmit={
          renameInfo ? handleRenameFileSubmit : handleCreateFolderSubmit
        }
        textFieldVariant="outlined"
        submitButtonTitle={renameInfo ? "Change" : "Create"}
        textfieldRules={{
          maxLength: {
            value: 100,
            message: "Sorry. Too Long. Please limit to 100 characters",
          },
          minLength: { value: 3, message: "Minimum size not met" },
          validate: {
            reservedWord: (value) =>
              !reservedFolderNames.includes(value) ||
              "Sorry. This word has already been used by the system internals. Please select another word or change casing in the word.",
            noExtension: (value) =>
              !checkIfNull(value.split(".").pop()) ||
              "Extension missing in filename (e.g. .pdf or .doc etc)",
            noSpecialChars: (value) => {
              const specialChars = specialCharactersList;
              const illegalChars = value.match(
                new RegExp(`[${specialChars.join("")}]`, "gi")
              );
              if (!illegalChars) return true;
              return `${illegalChars.join(", ")} are not allowed!`;
            },
          },
        }}
      />
      <DownloadFilesDialog
        onClose={() => setDownloadDialogOpen(false)}
        open={downloadDialogOpen}
        protectFlag={
          (viewContext === "DOCKET" &&
            selectedDocket?.settings?.protectDocketFiles) ||
          (viewContext !== "DOCKET" &&
            selectedSuite?.settings?.protectSuiteFiles)
        }
        viewContext={viewContext}
        filesForDownload={downloadedFiles}
      />
      {uploadDialogOpen && (
        <UploadOmniModal
          open={uploadDialogOpen}
          initialOption={selectedUploadAction}
          title="Add files to Upload"
          showPreview
          showNotify
          checkFileNamesOverlap={checkFileNamesOverlap}
          bucket={selectedBucketId}
          s3Prefix={decodedS3Prefix}
          handleNotify={handleUploadNotify}
          maxSize={SIZELIMITS.uploadMaxFileSize}
          onComplete={handleUploadFiles}
          onClose={() => {
            setUploadDialogOpen(false);
            resetActionsList();
          }}
        />
      )}
      {moveFilesDialogOpen && (
        <MoveFilesDialog
          open={moveFilesDialogOpen}
          onClose={handleMoveDialogClose}
          onMove={(targetPrefix) => handleMoveSelectedFiles(targetPrefix)}
        />
      )}
    </FilesContext.Provider>
  );
};
export default FilesProvider;
