// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, useEffect } from "react";
//import Cookies from 'js-cookie';
import { parseCookies, setCookie } from "nookies";

import { useIntl } from "react-intl";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import Portal from "@mui/base/Portal";

const WrapperDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette?.common.black,
  color: theme.palette?.common.white,
  maxWidth: 600,
  position: "fixed",
  bottom: 15,
  left: 15,
  margin: 15,
  padding: 15,
  outline: "none",
  zIndex: 2000,
}));

const CookiesNotification = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setCookie(null, "consent", "true", { maxAge: 30 * 24 * 60 * 60 });
    {
      /*Cookies.set('consent', 'true');*/
    }
    setOpen(false);
  };

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  useEffect(() => {
    const allCookies = parseCookies();
    const consent = allCookies?.consent ?? false;

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <WrapperDiv>
        <Typography variant="subtitle1" color="inherit">
          {`${t("cookiePolicyPrompt.text")}`}{" "}
          <NextMuiLink
            color="inherit"
            underline="always"
            href={`${process.env.ROOT_URL}/home/privacypolicy`}
            target="_blank"
          >
            {`${t("cookiePolicyPrompt.linkText")}`}
          </NextMuiLink>
          .
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleClose} color="primary" variant="contained">
            {`${t("cookiePolicyPrompt.agreeButtonText")}`}
          </Button>
        </Box>
      </WrapperDiv>
    </Portal>
  );
};

export default CookiesNotification;
