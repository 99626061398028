// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  List,
  Card,
  CardHeader,
  CardContent,
  Box,
  Avatar,
  Badge,
} from "@mui/material";
import { useIntl } from "react-intl";
import {
  UpcomingMeetingCard,
  RsvpMeetingCard,
  SurveyResponseNotificationCard,
} from "blocks/views/SuiteDashboard/foundations/AttentionActionCards";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const NeedingAttentionItems = ({ showTitleFlag = true }) => {
  const { upcomingMeetings, meetingsToRSVP, openSurveysAndResolutions } =
    useDashboardViewsDataContext();

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const numOfActionItems =
    (upcomingMeetings?.length ?? 0) +
    (meetingsToRSVP?.length ?? 0) +
    (openSurveysAndResolutions?.length ?? 0);

  return (
    <Card
      elevation={0}
      raised={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        height: "100%",
        flexGrow: 1,
        boxShadow: "none",
      }}
    >
      {showTitleFlag && (
        <CardHeader
          sx={{ p: 0, m: 0, mt: 2 }}
          disableTypography
          title={
            <Badge
              color="primary"
              badgeContent={numOfActionItems}
              sx={{
                "& .MuiBadge-badge": {
                  right: -10,
                  top: 0,

                  border: (theme) =>
                    `2px solid ${theme.palette.background.paper}`,
                },
              }}
            >
              <Typography variant="overline" color="primary.darker">
                {`${t("SuiteDashboard.NeedingAttentionItems.headline")}`}
              </Typography>
            </Badge>
          }
        />
      )}
      <PerfectScrollbar
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <CardContent sx={{ display: "flex", flexWrap: "wrap", gap: 2, p: 0 }}>
          {upcomingMeetings?.map((meeting, index) => (
            <UpcomingMeetingCard key={index} meeting={meeting} />
          ))}
          {meetingsToRSVP?.map((meeting, index) => (
            <RsvpMeetingCard key={index} meeting={meeting} />
          ))}
          {openSurveysAndResolutions?.map((survey, index) => (
            <SurveyResponseNotificationCard key={index} survey={survey} />
          ))}
        </CardContent>
      </PerfectScrollbar>
    </Card>
  );
};

export default NeedingAttentionItems;
