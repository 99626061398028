// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const FilesDeleted = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  let fileSummaryInfo;
  if (payload?.files?.length > 1) {
    fileSummaryInfo = `${payload.files?.length} files.`;
  } else {
    fileSummaryInfo = `${
      Array.isArray(payload.files) && payload?.files[0]?.fileName
    }`;
  }
  const data = {
    message: `${payload?.deletingUserFullName || "Suite member"} has deleted ${
      fileSummaryInfo || "file(s)"
    }`,
    avatar: getS3ImageWrapper(payload?.deletingUserImage, "user"),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default FilesDeleted;
