import React from "react";
import { useAppContext } from "@app21/core";
import { Box, Typography, Chip } from "@mui/material";
import { gql } from "@apollo/client";
import { LoadingSpinner } from "blocks/atoms";
import { Done } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  timelineTemplate,
  supportedTimelineTemplateList,
} from "blocks/views/Notifications/foundations";
import { Timeline } from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { useGraphqlQuery } from "hooks/useGraphql";
import toast from "react-hot-toast";

const getFeedItemsForUserInSuite = gql`
  query GetFeedItemsForUserInSuite($suiteId: ID!) {
    feedsForUserInSuite(suiteId: $suiteId) {
      textContent
      id
      sharedWith {
        fullName
        image
        id
      }
    }
  }
`;

export default function DataSuiteTimelinePanel({}) {
  const { selectedSuiteId } = useAppContext();

  const {
    isFetching: loading,
    data,
    error,
  } = useGraphqlQuery({
    query: getFeedItemsForUserInSuite,
    variables: { suiteId: selectedSuiteId },
  });

  const timelineData = data?.data?.feedsForUserInSuite ?? [];

  React.useEffect(() => {
    if (error && !loading) {
      toast.error(JSON.stringify(error));
    }
  }, [error]);

  const [hasMoreFlag, setHasMoreFlag] = React.useState(true);
  const numOfItemsToBeFetchedOnLoad = 5;
  const initialNumOfItems = 10;

  const [shownTimelineArray, setShownTimelineArray] = React.useState(
    timelineData?.slice(0, initialNumOfItems) ?? []
  );
  React.useEffect(() => {
    setShownTimelineArray(timelineData?.slice(0, initialNumOfItems) ?? []);
  }, [data]);

  function fetchMoreActivities() {
    if (timelineData?.length) {
      if (
        shownTimelineArray.length >= timelineData.length ||
        shownTimelineArray.length >= 200
      ) {
        setHasMoreFlag(false);
        return;
      }
      setShownTimelineArray((prevArray) => [
        ...prevArray,
        ...(timelineData?.slice(
          prevArray.length,
          prevArray.length + numOfItemsToBeFetchedOnLoad
        ) ?? []),
      ]);
    }
  }

  if (loading) {
    return <LoadingSpinner variant="exp" />;
  }
  if (!data) return null;
  return (
    <Box
      sx={{
        m: 0,
        pb: 2,
        width: "100%",
        maxHeight: "60vh",
      }}
    >
      <PerfectScrollbar onYReachEnd={fetchMoreActivities} options={{}}>
        {shownTimelineArray?.length > 0 ? (
          <>
            <Timeline
              sx={{
                m: 0,
                p: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {shownTimelineArray?.map((timelineItem, index) => {
                let TemplateName = supportedTimelineTemplateList.includes(
                  timelineItem.timelineType
                )
                  ? timelineTemplate[timelineItem.timelineType]
                  : timelineTemplate["default"];

                return (
                  <TemplateName
                    key={index}
                    timelineItem={timelineItem}
                    viewContext={"timeline"}
                    index={index}
                  />
                );
              })}
            </Timeline>
            {!hasMoreFlag && (
              <Chip
                variant="outlined"
                size="small"
                label={<Done fontSize="small" color="success" />}
                sx={{ marginLeft: 17 }}
              />
            )}
          </>
        ) : (
          <Typography variant="body2" sx={{ textAlign: "center", my: 3 }}>
            No activities recorded yet
          </Typography>
        )}
      </PerfectScrollbar>
    </Box>
  );
}
