import {
  useAudioVideo,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";
import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useAppContext } from "@app21/core";
import {
  DATA_MESSAGE_LIFETIME_MS,
  DATA_MESSAGE_TOPICS,
} from "providers/TrrystVideocallProvider/configs/constants";
import {
  DataMessagesActionType,
  initialState,
  reducer,
} from "providers/TrrystVideocallProvider/utils/messagesState";
import toast from "react-hot-toast";
import { useHandleLeaveVideocall } from "providers/TrrystVideocallProvider/hooks";
import data from "@iconify/icons-eva/close-circle-fill";

const VideocallActivityContext = createContext();

const VideocallActivityProvider = ({ children }) => {
  const { userInfo } = useAppContext();
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const [state, dispatch] = useReducer(reducer, initialState);
  const senderAttendeeId =
    meetingManager?.meetingSessionConfiguration?.credentials?.attendeeId;
  const { leaveVideocall } = useHandleLeaveVideocall();

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPICS.admin,
      handler
    );
    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
        DATA_MESSAGE_TOPICS.admin
      );
    };
  }, [audioVideo]);

  const processAdminMessages = async (receivedData) => {
    if (
      // one person will act on it and others will ignore the message
      receivedData.to ===
      meetingManager?.meetingSessionConfiguration?.credentials?.attendeeId
    ) {
      if (receivedData.action === "TOGGLE-MUTE") {
        if (!audioVideo.realtimeIsLocalAudioMuted()) {
          audioVideo.realtimeMuteLocalAudio();
          toast.error("You are being muted by Admin", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      if (receivedData.action === "KICK-OUT") {
        toast.error("You have been removed from this meeting by the Admin", {
          appearance: "error",
          autoDismiss: true,
        });
        leaveVideocall({ meetingManager });
      }
    }
  };

  const handler = (dataMessage) => {
    if (!dataMessage.throttled) {
      const isSelf =
        dataMessage.senderAttendeeId ===
        meetingManager.meetingSessionConfiguration?.credentials?.attendeeId;
      if (isSelf) {
        dispatch({
          type: DataMessagesActionType.ADD,
          payload: {
            message: new TextDecoder().decode(dataMessage.data),
            senderAttendeeId: dataMessage.senderAttendeeId,
            timestamp: dataMessage.timestampMs,
            senderName: dataMessage.senderExternalUserId,
            isSelf: true,
          },
        });
      } else {
        const data = dataMessage.json();
        processAdminMessages(data);
        dispatch({
          type: DataMessagesActionType.ADD,
          payload: {
            message: data.message,
            senderAttendeeId: dataMessage.senderAttendeeId,
            timestamp: dataMessage.timestampMs,
            senderName: data.senderName,
            isSelf: false,
          },
        });
      }
    } else {
      console.warn("DataMessage is throttled. Please resend");
    }
  };

  const handleAdminAction = (action, attendeeId) => {
    console.log(
      "sending message : ",
      action,
      attendeeId,
      meetingManager?.meetingSessionConfiguration
    );
    if (
      !meetingManager?.meetingSessionConfiguration?.credentials?.attendeeId ||
      !audioVideo
    ) {
      return;
    }

    let payload = {
      to: attendeeId,
      action: action,
      from: meetingManager?.meetingSessionConfiguration?.credentials.attendeeId,
    };

    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPICS.admin,
      payload,
      DATA_MESSAGE_LIFETIME_MS
    );
    // audioVideo.realtimeSendDataMessage(
    //   DATA_MESSAGE_TOPIC,
    //   payload,
    //   DATA_MESSAGE_LIFETIME_MS
    // );
  };

  const sendMessage = (message) => {
    if (
      !meetingManager?.meetingSessionConfiguration?.credentials?.attendeeId ||
      !audioVideo
    ) {
      return;
    }
    const payload = {
      message,
      senderName: userInfo?.fullName ?? "Trryst User",
    };

    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPICS.group,
      payload,
      DATA_MESSAGE_LIFETIME_MS
    );
    handler(
      new DataMessage(
        Date.now(),
        DATA_MESSAGE_TOPICS.admin,
        new TextEncoder().encode(message),
        senderAttendeeId,
        userInfo?.fullName ?? "Trryst User"
      )
    );
  };

  const value = {
    sendMessage,
    handleAdminAction,
    messages: state.messages,
  };
  return (
    <VideocallActivityContext.Provider value={value}>
      {children}
    </VideocallActivityContext.Provider>
  );
};

export default VideocallActivityProvider;

export const useVideocallActivity = () => {
  const meetingManager = useMeetingManager();
  const context = useContext(VideocallActivityContext);
  if (!meetingManager || !context) {
    throw new Error(
      "Use useVideocallActivity hook inside VideocallActivityProvider. Wrap VideocallActivityProvider under MeetingProvider."
    );
  }
  return context;
};
