// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  useAppContext,
  useHandleRouterPush,
  useUpdateChannels,
  checkIfNull,
} from "@app21/core";
import { isEqual } from "lodash";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import path from "path";
import { Button, Paper, Typography } from "@mui/material";
import { useVideocallContext } from "providers/TrrystVideocallProvider";

const LocalPubNubWrapper = ({ children, pubnubClient }) => {
  const queryClient = useQueryClient();
  const { loadRoute } = useHandleRouterPush();
  const { userInfo, isBasicDataReady, setAsyncRequestStates } = useAppContext();
  const router = useRouter();
  const updateChannels = useUpdateChannels();
  const { setEndMeetingForAllReceived } = useVideocallContext();
  const handlePubnubEvents = async (event) => {
    if (event.message.messageType === "UPDATEQUERY") {
      if (process.env.stage !== "prod") {
      }
      await Promise.all(
        event.message.data.map(async (item) => {
          const { query, keys } = item;
          switch (query) {
            case "FETCH-CHAT-CHANNELS":
              await queryClient.invalidateQueries(["chat-channels"]);
              break;
            case "FETCH-NOTIFICATIONS":
              await queryClient.invalidateQueries(["allNotifications"]);
              break;
            case "FETCH-ORGS-AND-SUITES-FOR-USER":
              await queryClient.invalidateQueries(["allOrgsAndSuites"]);
              break;
            case "FETCH-ALL-ORGANIZATIONS":
              if (!queryClient.isFetching(["allOrganizations"])) {
                await queryClient.invalidateQueries(["allOrganizations"]);
              }
              break;
            case "FETCH-ORGANIZATION-BY-ID":
              await queryClient.invalidateQueries([
                "allOrganizations",
                keys.organizationId,
              ]);
              break;
            case "FETCH-SUITES-IN-ORGANIZATION":
              await queryClient.invalidateQueries([
                "allSuites",
                keys.organizationId,
              ]);
              break;
            case "FETCH-USERS-IN-ORGANIZATION":
              await queryClient.invalidateQueries([
                "allUsers",
                keys.organizationId,
              ]);
              break;
            case "FETCH-SUITE-BY-ID":
              await queryClient.invalidateQueries(["suites", keys.suiteId]);
              break;
            case "FETCH-USERS-IN-SUITE":
              await queryClient.invalidateQueries(["allUsers", keys.suiteId]);
              break;
            case "FETCH-USERS-IN-ALL-SUITES":
              await queryClient.invalidateQueries(["allUsers"]);
              break;
            case "FETCH-DOCKETS-IN-SUITE":
              await queryClient.invalidateQueries(["allDockets", keys.suiteId]);
              break;
            case "FETCH-DOCKET-BY-ID":
              await queryClient.invalidateQueries(["dockets", keys.docketId]);
              break;
            case "FETCH-FILES":
              await queryClient.invalidateQueries(["allS3Files"]);
              break;
            case "FETCH-MEETINGS":
              // the users upcoming meeting is stored under this key. needs to be refreshed
              // irrespective of when FETCH-MEETINGS is sent.
              await queryClient.invalidateQueries([
                "allMeetings",
                `${userInfo._id}`,
              ]);
              if (keys.docketIds) {
                await queryClient.invalidateQueries([
                  "allMeetings",
                  keys.docketIds,
                ]);
              }
              if (keys.suiteId) {
                await queryClient.invalidateQueries([
                  "allMeetings",
                  keys.suiteId,
                  keys.groupMeetings,
                ]);
              }
              break;
            case "FETCH-MEETING-BY-ID":
              await queryClient.invalidateQueries([
                "allMeetings",
                keys.meetingId,
              ]);
              break;
            case "FETCH-RECORDINGMETADATA-BY-MEETING-ID":
              await queryClient.invalidateQueries([
                "recordingMetadata",
                keys.meetingId,
              ]);
              break;
            case "FETCH-TASKS-FOR-USER":
              await queryClient.invalidateQueries([
                "allTasks",
                keys.id,
                keys.type,
              ]);
              break;
            case "FETCH-RESOLUTION-BY-ID":
              await queryClient.invalidateQueries([
                "allResolutions",
                keys.resolutionId,
              ]);
              break;
            case "FETCH-RESOLUTIONS":
              if (keys.docketId) {
                await queryClient.invalidateQueries([
                  "allResolutions",
                  keys.docketId,
                ]);
              } else {
                await queryClient.invalidateQueries([
                  "allResolutions",
                  keys.suiteId,
                ]);
              }
              break;
            case "FETCH-USERINFO":
              await queryClient.invalidateQueries(["userInfo", keys.userId]);
              break;
            case "FETCH-RESOLUTIONS-IN-DOCKET":
              break;
            case "FETCH-TRANSCRIPTS":
              await queryClient.invalidateQueries([
                "allTranscripts",
                keys.meetingId,
              ]);
              break;
            case "FETCH-ACCESS-RULES":
              await queryClient.invalidateQueries([
                "allAccessRules",
                keys.organizationId,
              ]);
              break;
            case "MEETING-ENDED-FOR-ALL":
              if (keys.meetingEndedBy !== userInfo._id)
                setEndMeetingForAllReceived(true);
              break;
            case "TRANSCRIPT-REQUEST-QUEUED":
              toast.success(`Transcription Request Successfully Queued`, {
                icon: "✅",
                duration: 3000,
                style: {
                  minWidth: "250px",
                },
              });
              break;
            case "TRANSCRIPT-REQUEST-ALREADY-IN-PROCESS":
              toast.error(`Transcription Request is already in Queue`, {
                icon: "⚠️",
                duration: 3000,
                style: {
                  minWidth: "250px",
                },
              });
              break;
            case "TRANSCRIPT-GENERATED-SUCCESSFULLY":
              toast.custom(
                <Paper
                  sx={{
                    display: "flex",
                    border: "1px solid #606060",
                    borderRadius: 5,
                    alignItems: "center",
                    pl: 3,
                    pr: 1,
                  }}
                >
                  <Typography variant="body2">
                    Your Transcript Summary and Actions have been generated
                    successfully
                  </Typography>
                  <Button
                    sx={{ mx: 2 }}
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      console.log("need to deep link to transcript here...")
                    }
                  >
                    View
                  </Button>
                </Paper>,
                {
                  style: {
                    minWidth: "250px",
                    background: "#fafafa",
                  },
                  duration: 10000,
                }
              );
              break;
            case "AI-SUMMARY-GENERATED-SUCCESS":
              const { userId, key } = keys ?? {};
              let keySplit = key?.split("/");
              const orgId = keySplit && keySplit[0];
              const suiteId = keySplit && keySplit[1];
              if (userId === userInfo?._id) {
                setAsyncRequestStates((prevState) => ({
                  ...prevState,
                  fileAIRequestStatus: "success",
                }));
                toast.custom(
                  <Paper
                    sx={{
                      display: "flex",
                      border: "1px solid #606060",
                      borderRadius: 5,
                      alignItems: "center",
                      pl: 3,
                      pr: 1,
                    }}
                  >
                    <Typography variant="body2">
                      Your AI prompt has been generated successfully
                    </Typography>
                    <Button
                      sx={{ mx: 2 }}
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        loadRoute("VIEW-AISUMMARY", {
                          orgId: orgId,
                          suiteId: suiteId,
                          s3Key: key,
                          choice: "aiSummary",
                        })
                      }
                    >
                      View
                    </Button>
                  </Paper>,
                  {
                    style: {
                      minWidth: "250px",
                      background: "#fafafa",
                    },
                    duration: 10000,
                  }
                );
              }
              // else {
              //   toast.success(
              //     `Document Summary Generated by ${userId} on file ${key}`,
              //     {
              //       style: {
              //         minWidth: "250px",
              //       },

              //       duration: 5000,
              //       icon: "🤖",
              //     }
              //   );
              // }
              break;
            case "UPDATE-ZIP-PROGRESS-ENTRY":
              {
                const { jobId, filename, timestamp, totalFilesProcessed } =
                  keys;

                toast.loading(`Zipping File ${path.basename(filename)}`, {
                  id: jobId,
                  style: { minWidth: "250px" },
                });
              }
              break;
            case "UPDATE-ZIP-PROGRESS-SUCCESS":
              {
                const {
                  jobId,
                  timestamp,
                  destinationS3Key,
                  totalFilesProcessed,
                  totalMilliseconds,
                  downloadURL,
                } = keys;
                toast.dismiss(jobId);
                toast.success(
                  `Zip Completed, Downloading files (${totalFilesProcessed})`,
                  {
                    duration: 5000,
                  }
                );
                if (downloadURL) {
                  const a = document.createElement("a");
                  a.setAttribute("href", downloadURL);
                  a.setAttribute("download", true);
                  a.click();
                }
              }
              break;
            case "UPDATE-ZIP-PROGRESS-ERROR": {
              const {
                jobId,
                timestamp,
                totalFilesProcessed,
                errorCode,
                errorData,
                errorMessage,
                errorName,
              } = keys;
            }
            default:
              break;
          }
        })
      );
    }
  };

  const pubnubActions = async (userInfo) => {
    pubnubClient.setUUID(userInfo?._id);
    pubnubClient.addListener({ message: handlePubnubEvents });

    let channelIds = [];
    if (checkIfNull(userInfo))
      await updateChannels.mutate({ channelIds: ["CSUITECOMMON"] });
    else {
      userInfo?.interlinks?.map(async (link) => {
        if (link.organization) {
          channelIds.push(`${link.organization._id}-UPDATES`);
        }
        if (link.suite) {
          channelIds.push(`${link.suite._id}-UPDATES`);
        }
      });
      channelIds.push("CSUITECOMMON");
      channelIds.push(userInfo?._id);

      channelIds.sort();
      userInfo?.channelIds?.sort();

      pubnubClient.subscribe({
        channels: channelIds,
        withPresence: false,
      });
      if (!isEqual(channelIds, userInfo.channelIds))
        await updateChannels.mutate({ channelIds });
    }

    // const activeUsers = await pubnubClient?.hereNow({
    //   channels: ["CSUITECOMMON"],
    //   includeState: true,
    // });
    // setActiveUsers(activeUsers);
  };
  useEffect(() => {
    // if there is a meetingId in the url, subscribe to that channel as well.
    // any updates to the meeting object will be published there by the fargate container.
    let lastIds;
    if (typeof window !== "undefined")
      lastIds = JSON.parse(localStorage.getItem("lastIds"));
    if (lastIds?.meetingId) {
      pubnubClient.subscribe({
        channels: [`${lastIds.meetingId}-UPDATES`],
        withPresence: false,
      });
    }
  }, [router]);

  useEffect(() => {
    // we have a dependency on userInfo to enable the registration of listeners and channels.
    // however when the userProfile itself is updated, the userInfo changes as well which
    // leads to the listeners being registered again. This cycle repeats everytime the user profile
    // gets updated which is a problem. So there is a forced remove all listeners here when userInfo changes
    // the first time it is useless, but from the subsequent times, it will remove one set of listeners
    // which the next line of code will put back.

    if (isBasicDataReady && userInfo) {
      pubnubClient.removeAllListeners();
      pubnubActions(userInfo);
    }
  }, [userInfo, isBasicDataReady]);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () =>
  //     pubnubClient?.unsubscribeAll()
  //   );
  // }, [pubnubClient]);

  return <>{children}</>;
};

export default LocalPubNubWrapper;
