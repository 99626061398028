// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import SimpleTimelineItem from "blocks/views/Notifications/foundations/SimpleTimelineItem";

const GenericTimelineTemplate = ({ timelineItem = {}, viewContext, index }) => {
  const data = {
    message: `Incoming notification`,
    subHeader: timelineItem.createdAt,
  };
  return (
    <SimpleTimelineItem
      data={data}
      timelineItem={timelineItem}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default GenericTimelineTemplate;
