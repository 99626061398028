// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const UserAddedSuite = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  const data = {
    message: `${payload?.addedUserFullName || "New User"} has been added to ${
      payload?.suiteFullName || "Suite"
    }`,
    avatar: getS3ImageWrapper(payload?.addedUserImage),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default UserAddedSuite;
