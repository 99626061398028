// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import {
  Box,
  Skeleton,
  Grid,
  Card,
  CardContent,
  Stack,
  CardHeader,
  useMediaQuery,
  Paper,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",
    flexGrow: 1,
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",

      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  };
});

export default function DataSuiteDashboardViewLoading() {
  const mobileMinWidth = useMediaQuery("(min-width:1200px)");
  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      <Grid item xs={12}>
        <RootStyle ownerState={{}}>
          <CardHeader
            sx={{ p: 1 }}
            avatar={
              <Skeleton
                sx={{ height: 90, width: 70, backgroundColor: "#ffffff" }}
              />
            }
            title={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  minHeight: 130,
                }}
              >
                <Skeleton
                  variant="text"
                  sx={{
                    mt: 1,
                    p: "5px 10px",
                    backgroundColor: "#ffffff",
                  }}
                />
                <Stack direction="row" spacing={mobileMinWidth ? 2 : 1}>
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={50}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: `10px 10px 10px 10px`,
                      // border: '1px solid #cfcfcf',
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={50}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: `10px 10px 10px 10px`,
                      // border: '1px solid #cfcfcf',
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={50}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: `10px 10px 10px 10px`,
                      // border: '1px solid #cfcfcf',
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={50}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: `10px 10px 10px 10px`,
                      // border: '1px solid #cfcfcf',
                    }}
                  />
                  {/* <Tooltip title="Refresh Dashboard Data">
                      <IconButton disabled={isRefreshing} onClick={handleRefreshData} color="secondary">
                        {isRefreshing ? <MoreHoriz /> : <Cached />}
                      </IconButton>
                    </Tooltip> */}
                </Stack>
              </Box>
            }
          />
        </RootStyle>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{
            mt: 6,
            height: 50,
            backgroundColor: "#ffffff",
            borderRadius: 3,
          }}
        />
      </Grid>
      <Paper
        elevation={1}
        sx={{
          flexGrow: 1,
          height: 350,
          pt: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          mt: 2,
          ml: 2,
        }}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          alignItems="stretch"
          justifyContent={"center"}
          sx={{}}
        >
          {Array.from(new Array(6)).map((_, index) => (
            <Grid item key={index} xs={3} sx={{ mx: 1, mt: 1 }}>
              <Skeleton
                width={"100%"}
                variant="rectangular"
                sx={{ height: 90, backgroundColor: "#ffffff" }}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
}
