// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import ShowLoginRequiredComponent from "./ShowLoginRequiredComponent";
import { AppProvider, useUserInfo } from "@app21/core";
import AccessCheckWrapper from "wrappers/AccessCheckWrapper";
import LocalPubNubWrapper from "wrappers/LocalPubNubWrapper";
import ChatCommsWrapper from "wrappers/ChatCommsWrapper";
import { datadogRum } from "@datadog/browser-rum";
// import { serialize, deserialize } from "bson";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import { useTheme, IconButton, alpha } from "@mui/material";
import {
  VideocallProvider,
  APIErrorHandlerProvider,
  OrgsAndSuitesInfoProvider,
  TrrystChatProvider,
} from "providers";

import ProcessRouteSwitch from "blocks/modules/ProcessRouteSwitch";
import LayoutProvider from "providers/LayoutProvider";
import { ConfirmProvider } from "material-ui-confirm";
import NotistackProvider from "providers/NotistackProvider";
import { Close } from "@mui/icons-material";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import AppLayout from "blocks/views/Layouts/AppLayout";
import Dexie from "dexie";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// import { persistQueryClient } from "react-query/persistQueryClient-experimental";
// import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      notifyOnChangeProps: "tracked",
      useErrorBoundary: true,
      refetchOnWindowFocus: true,
    },
  },
});

const clientSideDB = new Dexie("CSuiteDB");
clientSideDB.version(2).stores({
  routes: "++id, pathname, query,userId,timestamp",
  signedurls: "++id, s3Key, url, &urlGeneratedAt",
});
const publishKey = process.env.PUBNUB_PUBLISH_KEY;
const subscribeKey = process.env.PUBNUB_SUBSCRIBE_KEY;

const pubnub = new PubNub({
  publishKey: publishKey,
  subscribeKey: subscribeKey,
  logVerbosity: false,
  ssl: false,
  presenceTimeout: 36000,
  heartbeatInterval: 3600,
  suppressLeaveEvents: true,
  keepAlive: true,
  uuid: "initialUserId",
  restore: true,
});

const pubnubChatClient = new PubNub({
  publishKey: process.env.PUBNUB_CHAT_PUBLISH_KEY,
  subscribeKey: process.env.PUBNUB_CHAT_SUBSCRIBE_KEY,
  logVerbosity: false,
  ssl: false,
  presenceTimeout: 36000,
  heartbeatInterval: 3600,
  suppressLeaveEvents: true,
  keepAlive: true,
  uuid: "initialUserId",
  restore: true,
});

const TrackWithDataDog = () => {
  const { pathname } = useRouter();
  const { data: userInfo } = useUserInfo();
  const {
    _id = null,
    fullName = null,
    emailId = null,
  } = userInfo ?? {
    _id: null,
    fullName: null,
    emailId: null,
  };

  const currentPathQueryParams = React.useMemo(() => {
    if (pathname && typeof window !== "undefined") {
      return localStorage.getItem("lastIds");
    }
    return null;
  }, [pathname]);

  React.useEffect(() => {
    if (!_id || emailId === "app21tester@trryst.com") return;
    datadogRum.setUser({
      id: _id,
      name: fullName,
      email: emailId,
    });
  }, [_id, fullName, emailId]);

  React.useEffect(() => {
    if (!_id || emailId === "app21tester@trryst.com") return;
    if (currentPathQueryParams)
      datadogRum.addAction(`User Navigation: ${currentPathQueryParams}`);
  }, [currentPathQueryParams, _id, emailId]);

  React.useEffect(() => {
    if (!_id || emailId === "app21tester@trryst.com") return;
    let dataDogInitStatus = false;
    if (typeof window !== "undefined") {
      dataDogInitStatus = JSON.parse(localStorage.getItem("datadogInitStatus"));
    }
    !dataDogInitStatus &&
      datadogRum.init({
        applicationId: "1cbadf10-b0a5-43be-b6a2-37cbbe851481",
        clientToken: "pub821e18f75b57b3cb8656216f0ea3748e",
        site: "datadoghq.eu",
        service: "trryst",
        version: "1234",
        sampleRate: 100,
        env: process.env.STAGE,
      });
    //datadogRum.startSessionReplayRecording();
    if (typeof window !== "undefined") {
      localStorage.setItem("datadogInitStatus", true);
    }
  }, [_id, emailId]);

  return <></>;
};
export const CombinedProvidersForAuthorizedRoute = ({ children }) => {
  const theme = useTheme();
  if (typeof window !== "undefined") {
    window.onblur = () =>
      localStorage.setItem("app21-lost-focus-at", Date.now());
    window.onfocus = () => {
      if (
        (Date.now() - localStorage.getItem("app21-lost-focus-at")) / 1000 >
        60 * 15
      )
        queryClient.invalidateQueries();
      console.log(
        "Window out of focus for (seconds): ",
        (Date.now() - localStorage.getItem("app21-lost-focus-at")) / 1000
      );
    };
  }

  React.useEffect(() => {
    if (typeof window !== "undefined" && navigator.cookieEnabled) {
      // const localStoragePersistor = createWebStoragePersistor({
      //   key: "APP21-DATA",
      //   storage: window.localStorage,
      //   throttleTime: 300000, // 5 minutes
      //   serialize: (client) => JSON.stringify({ d: serialize(client) }),
      //   deserialize: (value) =>
      //     deserialize(Buffer.from(JSON.parse(value).d.data)),
      // });
      // persistQueryClient({
      //   queryClient,
      //   persistor: localStoragePersistor,
      // });
    }
  }, []);

  const { getIdTokenClaims } = useAuth0();
  const [rawAuth0UserIdJwtToken, setRawAuth0UserIdJwtToken] =
    React.useState(null);

  const apolloClient = React.useMemo(
    () =>
      new ApolloClient({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_SERVER_URL,
        cache: new InMemoryCache(),
        headers: {
          Authorization: rawAuth0UserIdJwtToken
            ? `Bearer ${rawAuth0UserIdJwtToken}`
            : undefined,
        },
      }),
    [rawAuth0UserIdJwtToken]
  );

  React.useEffect(() => {
    async function fetchAuth0IdToken() {
      setRawAuth0UserIdJwtToken((await getIdTokenClaims())?.__raw);
    }
    fetchAuth0IdToken();
  }, [getIdTokenClaims]);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <LoadingSpinner
            flowScreenFlag={true}
            variant="exp"
            randomSeedString="SuspenseFallback"
          />
        }
      >
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            duration: 5000,
            style: {
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.divider}`,
              boxShadow: theme.customShadows.z20,
              padding: "10px",
            },

            // Default options for specific types
            success: {
              duration: 5000,
              iconTheme: {
                primary: theme.palette.success.main,
              },
              style: {
                background: theme.palette.success.lighter,
                primary: theme.palette.success.main,
                secondary: theme.palette.secondary.main,
              },
            },
            error: {
              duration: 10000,
              iconTheme: {
                primary: theme.palette.error.main,
              },
              style: {
                background: alpha(theme.palette.error.lighter, 0.98),
                primary: theme.palette.error.main,
                secondary: theme.palette.secondary.main,
              },
            },
            apiError: {
              duration: 5000,
              iconTheme: {
                primary: theme.palette.warning.main,
              },
              style: {
                background: alpha(theme.palette.warning.light, 0.08),
                primary: "green",
                secondary: "black",
              },
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <IconButton
                      size="small"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      <Close fontSize="inherit" />{" "}
                    </IconButton>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        <ReactQueryDevtools
          initialIsOpen
          panelProps={{ style: { backgroundColor: "black", color: "white" } }}
        />
        <ConfirmProvider
          defaultOptions={{
            confirmationText: "Proceed",
            cancellationButtonProps: {
              variant: "contained",
              color: "primary",
              autoFocus: true,
            },
            dialogProps: { fullWidth: true, maxWidth: "sm" },
          }}
        >
          <NotistackProvider>
            <AppProvider clientDB={clientSideDB}>
              <TrackWithDataDog />
              <APIErrorHandlerProvider>
                <ChatCommsWrapper>
                  <VideocallProvider>
                    <LocalPubNubWrapper pubnubClient={pubnub}>
                      <PubNubProvider client={pubnubChatClient}>
                        <LayoutProvider>
                          <TrrystChatProvider>
                            <AppLayout>
                              <AccessCheckWrapper>
                                <ProcessRouteSwitch />
                                <OrgsAndSuitesInfoProvider>
                                  <ApolloProvider client={apolloClient}>
                                    {children}
                                  </ApolloProvider>
                                </OrgsAndSuitesInfoProvider>
                              </AccessCheckWrapper>
                            </AppLayout>
                          </TrrystChatProvider>
                        </LayoutProvider>
                      </PubNubProvider>
                    </LocalPubNubWrapper>
                  </VideocallProvider>
                </ChatCommsWrapper>
              </APIErrorHandlerProvider>
            </AppProvider>
          </NotistackProvider>
        </ConfirmProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export const AuthRedirectWrapper = ({
  children,
  Component = {},
  pageProps,
}) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const router = useRouter();

  if (isLoading || !router.isReady)
    return (
      <LoadingSpinner
        flowScreenFlag={true}
        variant="exp"
        randomSeedString="AuthRedirectWrapper"
      />
    );
  else if (
    Component.name === "FourOhFour" ||
    router.pathname === "/" ||
    router.pathname.startsWith("/main") ||
    router.pathname.startsWith("/home") ||
    router.pathname.startsWith("/signuplink") ||
    router.pathname.startsWith("/onboard") ||
    router.pathname.startsWith("/login") ||
    router.pathname.startsWith("/clickaction")
  )
    return <Component {...pageProps} />;
  else if (
    router.pathname.startsWith("/auth0_callback") ||
    router.pathname.startsWith("/l")
  ) {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools
          initialIsOpen
          panelProps={{
            style: {
              backgroundColor: "black",
              color: "white",
            },
          }}
        />
        {children}
      </QueryClientProvider>
    );
  } else if (
    router.pathname.startsWith("/dashboard") ||
    router.pathname.startsWith("/suite") ||
    router.pathname.startsWith("/user") ||
    router.pathname.startsWith("/apps") ||
    router.pathname.startsWith("/joininvite") ||
    router.pathname.startsWith("/meeting") ||
    router.pathname.startsWith("/pubnub") ||
    router.pathname.startsWith("/analytics")
  ) {
    if (isAuthenticated)
      return (
        <CombinedProvidersForAuthorizedRoute>
          {children}
        </CombinedProvidersForAuthorizedRoute>
      );
    else
      return (
        <ShowLoginRequiredComponent
          viewContext={
            router.pathname.startsWith("/meeting") ? "meeting" : "catchAll"
          }
        />
      );
  } else
    return (
      <CombinedProvidersForAuthorizedRoute>
        {children}
      </CombinedProvidersForAuthorizedRoute>
    );
};
