import React from "react";
import {
  useActiveSpeakersState,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";

const TrrystBotObserver = () => {
  const isClient = React.useMemo(() => typeof window !== "undefined", []);
  const { roster } = useRosterState();
  const activeSpeaker = useActiveSpeakersState();

  const addActiveSpeakerEventToLocalStorage = React.useCallback(
    (event) => {
      if (!isClient) return;
      if (localStorage.getItem("meetingEvents")) {
        const existingItem = JSON.parse(localStorage.getItem("meetingEvents"));
        const lastEntry = existingItem.slice(-1).pop();
        if (
          lastEntry.EventParameters.AttendeeId !==
          event.EventParameters.AttendeeId
        )
          localStorage.setItem(
            "meetingEvents",
            JSON.stringify([...existingItem, event])
          );
      } else {
        localStorage.setItem("meetingEvents", JSON.stringify([event]));
      }
    },
    [isClient]
  );

  React.useEffect(() => {
    const activeSpeakerChimeAttendeeId = activeSpeaker[0];
    const attendee = roster[activeSpeakerChimeAttendeeId];
    if (!attendee) return;

    const event = {
      EventType: "ActiveSpeaker",
      Timestamp: Date.now(),
      EventParameters: {
        AttendeeId: attendee.chimeAttendeeId,
        userId: attendee.externalUserId,
      },
    };
    addActiveSpeakerEventToLocalStorage(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSpeaker]);

  return <></>;
};

export default TrrystBotObserver;
