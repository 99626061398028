// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Switch(theme) {
  const isLight = theme.palette.mode === "light";

  return {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: theme.customShadows.z3,
          border: "1px solid Lightgrey",
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
        switchBase: {
          left: 0,
          right: "auto",
          "&:not(:.Mui-checked)": {
            color: theme.palette.grey[isLight ? 100 : 300],
          },
          // <<<<<<< HEAD
          //           '& .Mui-checked.Mui-disabled, & .Mui-disabled': {
          //             // color: theme.palette.grey[isLight ? 400 : 600],
          //             color: theme.palette.grey[0],
          // =======
          "& .Mui-checked .Mui-disabled, & .Mui-disabled": {
            color: theme.palette.grey[isLight ? 400 : 600],
            // >>>>>>> v4dev
          },
          "& .Mui-disabled+.MuiSwitch-track": {
            opacity: 1,
            backgroundColor: `${theme.palette.gray.light} !important`,
          },
        },
      },
    },
  };
}
