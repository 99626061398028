// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
