// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  CardHeader,
  Box,
  Typography,
  Grid,
  Divider,
  lighten,
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import {
  useAppContext,
  useHandleRouterPush,
  getS3ImageWrapper,
  useSuite,
} from "@app21/core";
import { DashboardActionButtonsStack } from "blocks/views/SuiteDashboard/foundations";

import { useIntl } from "react-intl";
import useIsMobileBreakpoint from "hooks/useIsMobileBreakpoint";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",
    flexGrow: 1,
    height: "100%",

    backgroundImage: `linear-gradient(45deg, ${alpha(
      theme.palette.dataSuite.main,
      1
    )} 15%, ${alpha(
      theme.palette.dataSuite.lighter,
      0.5
    )} 99%), url(${backgroundPicture})`,
    backgroundPosition: "center",
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      position: "absolute",
      backgroundColor: (theme) =>
        alpha(lighten(theme.palette.primary.main, 0.8), 0.9),
      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  };
});

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const DataSuiteWelcomeCard = ({ isRefreshing, handleRefreshData }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { selectedSuiteId } = useAppContext();
  const { data: selectedSuite } = useSuite(selectedSuiteId);
  const { isMobile } = useIsMobileBreakpoint();
  return (
    <RootStyle
      ownerState={{
        backgroundPicture: getS3ImageWrapper(
          selectedSuite?.image,
          "initials",
          selectedSuite?.fullName
        ),
        opacity: 0.1,
      }}
    >
      <CardHeader
        sx={{ p: 1, pb: 2 }}
        title={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Grid item container justifyContent={"space-between"}>
              <Grid
                item
                xs={12}
                sm={4}
                lg={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h3"
                  color="primary.contrastText"
                  sx={{
                    mt: 1,
                    p: "5px 10px",
                    textShadow: "0 1px #555",
                    textAlign: "left",
                  }}
                >
                  Data Suite
                </Typography>
                <Divider
                  sx={{
                    width: 160,
                    ml: 3,
                    bgcolor: (theme) => theme.palette.grey[0],
                  }}
                />
                <Typography
                  gutterBottom
                  variant={isMobile ? "body2" : "body1"}
                  color="primary.contrastText"
                  sx={{
                    mt: 3,
                    p: "5px 10px",
                    textAlign: "left",
                  }}
                >
                  Share Data, Collaborate, Discuss, Analyze and Meet securely
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                lg={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ m: 5 }}></Box>
                <DashboardActionButtonsStack
                  buttonsList={["meeting", "files", "discuss", "insights"]}
                />
              </Grid>
            </Grid>
          </Box>
        }
        // avatar={
        //   <Avatar
        //     variant="rounded"
        //     src={getS3ImageWrapper(
        //       selectedSuite?.image,
        //       "initials",
        //       selectedSuite?.fullName
        //     )}
        //     sx={{
        //       // height: isMobile ? 50 : 70,
        //       // width: isMobile ? 50 : 70,
        //       height: 70,
        //       width: 70,
        //       backgroundColor: (theme) =>
        //         alpha(theme.palette.primary.dark, 0.4),
        //     }}
        //   />
        // }
      />
    </RootStyle>
  );
};

export default DataSuiteWelcomeCard;
