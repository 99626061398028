// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
export const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

export const GRAY = {
  lighter: GREY[300],
  light: GREY[400],
  main: GREY[500],
  dark: GREY[700],
  darker: GREY[800],
};

export const BLACK = {
  lighter: "#B6B6B6",
  light: "#6A707E",
  main: "#1A2744",
  dark: "#444C5F",
  darker: "#1A2744",
  contrastText: "#fff",
};

export const PRIMARY = {
  lighter: "#36a092",
  light: "#189887",
  main: "#048171",
  dark: "#005045",
  darker: "#07493C",
  contrastText: "#Ffffff",
};
export const SECONDARY = {
  lighter: "#fad2a0",
  light: "#FFc55e",
  main: "#ee9a01",
  dark: "#bd7a00",
  darker: "#945f00",
  contrastText: "#ffffff",
};
export const INFO = {
  lighter: alpha("#336b87", 0.27),
  light: "#90afc5",
  main: "#336b87",
  dark: "#1e5874",
  darker: "#2a3132",
  contrastText: "#fff",
};
export const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};
export const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};
export const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

export const ACCENT = {
  lighter: "#F5f5f5",
  light: "#e8e8e8",
  main: "#dcdcdc",
  dark: "#c0c0c0",
  darker: "#a8a8a8",
  contrastText: GREY[900],
};
export const boardSuite = {
  lighter: "#4B2D5F",
  light: "#3B1D4F",
  main: "#4C2665",
  dark: "#2E0F2F",
  darker: "#1E0619",
  contrastText: "#fff",
};
export const dataSuite = {
  lighter: "#6b2936",
  light: "#5a1825",
  main: "#490714",
  dark: "#2E0F2F",
  darker: "#1E0619",
  contrastText: "#fff",
};
export const salesSuite = {
  lighter: "#2295e9",
  light: "#1184d8",
  main: "#0073C7",
  dark: "#005A9E",
  darker: "#004578",
  contrastText: "#fff",
};
export const execSuite = {
  lighter: "#FFAB43",
  light: "#FFAB43",
  main: "#FFAB43",
  dark: "#FFAB43",
  darker: "#FFAB43",
  contrastText: GREY[900],
};
export const personalSuite = {
  lighter: "#BA2C73",
  light: "#BA2C73",
  main: "#BA2C73",
  dark: "#BA2C73",
  darker: "#BA2C73",
  contrastText: "#fff",
};

export const SUITES = {
  boardSuite: "#4C2665",
  dataSuite: "#490714",
  salesSuite: "#0073C7",
  execSuite: "#FFAB43",
  personalSuite: "#BA2C73",
};
export const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  accent: createGradient(ACCENT.light, ACCENT.main),
  boardSuite: createGradient(SUITES.boardSuite, alpha(SUITES.boardSuite, 0.7)),
  dataSuite: createGradient(SUITES.dataSuite, alpha(SUITES.dataSuite, 0.7)),
  salesSuite: createGradient(SUITES.salesSuite, alpha(SUITES.salesSuite, 0.7)),
  execSuite: createGradient(SUITES.execSuite, alpha(SUITES.execSuite, 0.7)),
  personalSuite: createGradient(
    SUITES.personalSuite,
    alpha(SUITES.personalSuite, 0.7)
  ),
};

export const SCROLLBAR = {
  track: "#DFE3E8",
  thumb: "#999",
  hover: "#336b87",
  width: 10,
  hoveredWidth: 15,
};

export const COMMON = {
  common: {
    black: "#1A2744",
    white: "#fff",
    grey: "#787878",
    darkGrey: "#383838",
  },
  suites: { ...SUITES },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  accent: { ...ACCENT },
  grey: GREY,
  gray: GRAY,
  black: BLACK,
  boardSuite: { ...boardSuite },
  dataSuite: { ...dataSuite },
  salesSuite: { ...salesSuite },
  execSuite: { ...execSuite },
  personalSuite: { ...personalSuite },
  scrollbar: { ...SCROLLBAR },
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      primary: "#2a3132",
      secondary: GREY[600],
      disabled: GREY[500],
      accent: "#f6d55c",
      light: GREY[100],
      dark: GREY[900],
    },
    background: { paper: "#fff", default: "#fafafa", neutral: GREY[100] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
      accent: "#f6d55c",
      light: GREY[100],
      dark: GREY[900],
    },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
