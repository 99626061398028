// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  Grid,
  Card,
  Button,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useIsMobileBreakpoint } from "hooks";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const EngagementPanel = ({ suiteType = "BOARD" }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const isMobile = useIsMobileBreakpoint();
  const {
    suiteViews,
    fileViews,
    docketEngagements,
    totalChimeMinutes,
    viewAnalyticsOption,
    handleChipToggle,
  } = useDashboardViewsDataContext();
  return (
    <Card elevation={0} sx={{ flexGrow: 1, height: "100%" }}>
      <CardHeader
        sx={{ pt: 1 }}
        disableTypography
        title={
          <Typography
            variant="overline"
            color="primary.darker"
            sx={{ lineHeight: 2 }}
          >
            {`${t("SuiteDashboard.EngagementBox.subtitle")}`}
          </Typography>
        }
        // action={
        //   suiteType === "PERSONAL" ? null : (
        //     <Button
        //       size="small"
        //       margin="dense"
        //       color={viewAnalyticsOption === "All" ? "primary" : "info"}
        //       variant="contained"
        //       onClick={handleChipToggle}
        //       endIcon={<Autorenew />}
        //       disabled="true"
        //     >
        //       <Typography variant="caption">{viewAnalyticsOption}</Typography>
        //     </Button>
        //   )
        // }
      />
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            xs={6}
            sm={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>{`${t(
                "SuiteDashboard.EngagementBox.suitecheckinsLabel"
              )}`}</Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="h5"
                  color={viewAnalyticsOption === "All" ? "primary" : "info"}
                  style={{ marginRight: isMobile ? 10 : 10 }}
                >
                  {suiteViews}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            lg={3}
            sm={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>{`${t(
                "SuiteDashboard.EngagementBox.filesViewedLabel"
              )}`}</Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  // color="primary"
                  variant="h5"
                  color={viewAnalyticsOption === "All" ? "primary" : "info"}
                  style={{ marginRight: isMobile ? 10 : 10 }}
                >
                  {fileViews}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            lg={3}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>{`${t(
                "SuiteDashboard.EngagementBox.docketEngagementsLabel"
              )}`}</Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  // color="primary"
                  variant="h5"
                  color={viewAnalyticsOption === "All" ? "primary" : "info"}
                  style={{ marginRight: isMobile ? 10 : 10 }}
                >
                  {docketEngagements}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            lg={3}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                flexDirection: "column",
              }}
            >
              {viewAnalyticsOption === "All" ? (
                <Typography variant="body2" sx={{ textAlign: "center" }}>{`${t(
                  "SuiteDashboard.EngagementBox.videocallAllTimeLabel"
                )}`}</Typography>
              ) : (
                <Typography variant="body2" sx={{ textAlign: "center" }}>{`${t(
                  "SuiteDashboard.EngagementBox.videocallTimeLabel"
                )}`}</Typography>
              )}
              <Box sx={{ mt: 1 }}>
                <Typography
                  // color="primary"
                  variant="h5"
                  color={viewAnalyticsOption === "All" ? "primary" : "info"}
                  style={{ marginRight: isMobile ? 10 : 10 }}
                >
                  {totalChimeMinutes
                    ? Math.round(totalChimeMinutes / 6) / 10
                    : 0}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EngagementPanel;
