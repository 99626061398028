import { atom } from "jotai";

export const showFilesSidebarFlagAtom = atom(false);
export const viewFilesContextAtom = atom("suites");
export const selectedFilesListAtom = atom([]);
export const selectedFileActionAtom = atom("");
export const selectedUploadActionAtom = atom("");
export const aiPromptDialogOpenAtom = atom(false);
export const isItemSelectedAFolderAtom = atom(false);
export const numOfFilesSelectedAtom = atom(0);
