// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { createContext, useContext } from "react";
import { useRouter } from "next/router";

import * as deviceSettings from "react-device-detect";

import useMediaQuery from "@mui/material/useMediaQuery";

import { IntlProvider } from "react-intl";
import flatten from "flat";
import enLandingJson from "public/static/locales/en/landingPage.json";
import frLandingJson from "public/static/locales/fr/landingPage.json";
import deLandingJson from "public/static/locales/de/landingPage.json";
import enMenusJson from "public/static/locales/en/menuTopics.json";
import frMenusJson from "public/static/locales/fr/menuTopics.json";
import deMenusJson from "public/static/locales/de/menuTopics.json";
import enDashboardJson from "public/static/locales/en/dashboard.json";
import frDashboardJson from "public/static/locales/fr/dashboard.json";
import deDashboardJson from "public/static/locales/de/dashboard.json";
import enModulesJson from "public/static/locales/en/appModules.json";
import frModulesJson from "public/static/locales/fr/appModules.json";
import deModulesJson from "public/static/locales/de/appModules.json";

const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: "default",
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: "purple",
    lighter: "#EBD6FD",
    light: "#B985F4",
    main: "#7635dc",
    dark: "#431A9E",
    darker: "#200A69",
    contrastText: "#fff",
  },
  // CYAN
  {
    name: "Cyan",
    lighter: "#D1FFFC",
    light: "#76F2FF",
    main: "#1CCAFF",
    dark: "#0E77B7",
    darker: "#053D7A",
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: "blue",
    lighter: "#CCDFFF",
    light: "#6697FF",
    main: "#0045FF",
    dark: "#0027B7",
    darker: "#00137A",
    contrastText: "#fff",
  },
  // ORANGE
  {
    name: "orange",
    lighter: "#FEF4D4",
    light: "#FED680",
    main: "#fda92d",
    dark: "#B66816",
    darker: "#793908",
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: "red",
    lighter: "#FFE3D5",
    light: "#FFC1AC",
    main: "#FF3030",
    dark: "#B71833",
    darker: "#7A0930",
    contrastText: "#fff",
  },
];
const initialState = {
  themeMode: "light",
  themeColor: "default",
  onChangeMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  setColor: PRIMARY_COLOR[0],
  colorOption: [],
};

import { useLocalStorage } from "@app21/core";
import palette from "styles/theme/palette";

const SettingsContext = createContext(initialState);
export const useSettingsContext = () => {
  const state = useContext(SettingsContext);

  if (!state) {
    throw new Error("useSettingsContext must be used within SettingsProvider");
  }

  return state;
};

function setColor(themeColor) {
  let color;
  const DEFAULT = PRIMARY_COLOR[0];
  const PURPLE = PRIMARY_COLOR[1];
  const CYAN = PRIMARY_COLOR[2];
  const BLUE = PRIMARY_COLOR[3];
  const ORANGE = PRIMARY_COLOR[4];
  const RED = PRIMARY_COLOR[5];

  switch (themeColor) {
    case "purple":
      color = PURPLE;
      break;
    case "Cyan":
      color = CYAN;
      break;
    case "blue":
      color = BLUE;
      break;
    case "orange":
      color = ORANGE;
      break;
    case "red":
      color = RED;
      break;
    default:
      color = DEFAULT;
  }
  return color;
}
const SettingsProvider = ({ children }) => {
  const [defaultSettings, setDefaultsettings] = React.useState({
    themeMode: "light",
    themeDirection: "ltr",
    themeColor: "default",
    responsiveFontSizes: true,
  });
  const [localSettings, setLocalSettings] = useLocalStorage("settings", {
    themeMode: "light",
    themeDirection: "ltr",
    themeColor: "default",
    responsiveFontSizes: true,
  });
  const settings = localSettings ?? defaultSettings;
  const [scrollY, setScrollY] = React.useState(0);

  const onChangeMode = (themeMode) => {
    setLocalSettings({
      ...localSettings,
      themeMode: themeMode,
    });
  };

  const onChangeDirection = (themeDirection) => {
    setLocalSettings({
      ...localSettings,
      themeDirection: themeDirection,
    });
  };

  const onChangeColor = (event) => {
    setLocalSettings({
      ...localSettings,
      themeColor: event.target.value,
    });
  };

  const onChangeResponsiveFontSizes = (value) => {
    setLocalSettings({
      ...localSettings,
      responsiveFontSizes: value,
    });
  };
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  const router = useRouter() ?? {};

  //setting base for react-intl
  const languages = {
    en: flatten(
      {
        ...enLandingJson,
        ...enDashboardJson,
        ...enMenusJson,
        ...enModulesJson,
      },
      { safe: true }
    ),
    fr: flatten(
      {
        ...frLandingJson,
        ...frDashboardJson,
        ...frMenusJson,
        ...frModulesJson,
      },
      { safe: true }
    ),
    de: flatten(
      {
        ...deLandingJson,
        ...deDashboardJson,
        ...deMenusJson,
        ...deModulesJson,
      },
      { safe: true }
    ),
  };

  const { locale, defaultLocale } = router;
  const messages = languages[locale];
  return (
    <SettingsContext.Provider
      value={{
        settings,

        // Mode
        onChangeMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        onChangeResponsiveFontSizes,
        setColor: setColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main,
        })),
        deviceSettings,
        isDarkModeEnabled,
        scrollY,
        setScrollY,
      }}
    >
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={defaultLocale}
      >
        {children}
      </IntlProvider>
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsProvider;
