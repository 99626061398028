// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },

      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  };
}
