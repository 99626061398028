import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { Box, Typography } from "@mui/material";
import DocketsProvider from "providers/DocketsProvider";
import DocketsSuiteDashboardListView from "blocks/modules/Dockets/features/DocketsSuiteDashboardListView";

export default function DashboardBreakoutsList({}) {
  return (
    <FlowBox sx={{ py: 2, px: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="overline" color="primary.darker">
          Your Breakouts
        </Typography>
      </Box>
      <DocketsProvider viewContext={"dashboard"} actionContext={"list"}>
        <DocketsSuiteDashboardListView />
      </DocketsProvider>
    </FlowBox>
  );
}
