// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { SnackbarProvider } from "notistack";
import infoFill from "@iconify/icons-eva/info-fill";
import alertCircleFill from "@iconify/icons-eva/alert-circle-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
// material
import { alpha, useTheme } from "@mui/material/styles";
import { Box, GlobalStyles, IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = true; //theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        "#root": {
          "& .SnackbarContent-root": {
            width: "100%",
            padding: theme.spacing(1.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            "&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo":
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
          },
          "& .SnackbarItem-message": {
            padding: "0 !important",
            fontWeight: theme.typography.fontWeightMedium,
          },
          "& .SnackbarItem-action": {
            marginRight: 0,
            color: theme.palette.action.active,
            "& svg": { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

NotistackProvider.propTypes = {
  children: PropTypes.node,
};

export default function NotistackProvider({ children }) {
  const notistackRef = React.useRef();
  const onClickDismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={5000}
        ref={notistackRef}
        action={(key) => {
          return (
            <IconButton color="secondary" onClick={() => onClickDismiss(key)}>
              <Icon icon={closeFill} width={24} height={24} />
            </IconButton>
          );
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        iconVariant={{
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          error: <SnackbarIcon icon={infoFill} color="error" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          info: <SnackbarIcon icon={alertCircleFill} color="info" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
