// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  Button,
  Dialog,
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import { useAppContext, getS3ImageWrapper, useSuite } from "@app21/core";
import { ScheduleMeetingDropdownButton } from "blocks/atoms";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;

  return {
    textAlign: "center",
    flexGrow: 1,
    height: "100%",
    backgroundImage: `linear-gradient(to bottom right, rgba(0,0,0,0.8), ${alpha(
      theme.palette.primary.main,
      0.5
    )}), url(${backgroundPicture})`,

    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
  };
});

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const SuiteDashboardWelcomeCard = () => {
  const { userInfo } = useAppContext();
  const { selectedSuiteId } = useAppContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);

  React.useEffect(() => {
    if (selectedSuiteApiError && selectedSuiteStatus != "loading") {
      toast.error(selectedSuiteApiError.message);
    }
  }, [selectedSuiteApiError]);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <RootStyle
      ownerState={{
        backgroundPicture: getS3ImageWrapper(
          selectedSuite?.image,
          "initials",
          selectedSuite?.fullName
        ),
      }}
    >
      <CardContent
        sx={{
          pt: 1,
          height: "100%",
          width: "100%",
          minHeight: 100,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Typography gutterBottom variant="overline" color="primary.lighter">
            {`${t("SuiteDashboard.SuiteWelcomeMessage.mainOverline")}`}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary.contrastText"
            sx={{ mt: 1 }}
          >
            {`${t(
              "SuiteDashboard.SuiteWelcomeMessage.greetingSuiteDashboard"
            )}`}
            , {userInfo.fullName}
          </Typography>
        </Box>
        {/* <Typography
          variant="body2"
          color="primary.contrastText"
          sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480 }}
        >
          {`${t("SuiteDashboard.SuiteWelcomeMessage.mainSuiteMessage")}`}
        </Typography> */}

        <ScheduleMeetingDropdownButton isMobile={false} docketId={null} />
      </CardContent>
    </RootStyle>
  );
};

export default SuiteDashboardWelcomeCard;

{
  /*<Card className={clsx(classes.cardRoot, globalClasses.glassPaper)}>
      <CardContent className={classes.cardContent}>
        <Typography variant="overline" color="primary">
          {`${t('SuiteDashboard.SuiteWelcomeMessage.mainOverline')}`}
        </Typography>
        <Typography variant="h5">
          {`${t('SuiteDashboard.SuiteWelcomeMessage.greetingSuiteDashboard')}`}, {userInfo.fullName}
        </Typography>
        <Typography variant="body1">{`${t('SuiteDashboard.SuiteWelcomeMessage.mainSuiteMessage')}`}</Typography>
      </CardContent>
      <CardMedia
        className={classes.backdropImage}
        image={getS3ImageWrapper(selectedSuite?.image, 'initials', selectedSuite?.fullName)}
        title="Board Meeting"
      />
    </Card>
  );
*/
}
