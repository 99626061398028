import React, { useContext } from "react";
import { useIntl } from "react-intl";
import {
  useAppContext,
  useOrgsAndSuitesForUser,
  useUserRecentItems,
  useSubscriptions,
  checkIfNull,
  checkIfAnyOrgAdmin,
} from "@app21/core";
import {
  mainDashboardSuiteSearchQueryAtom,
  mainDashboardSuiteSearchSortMethodAtom,
  mainDashboardSuiteSearchFilterListAtom,
} from "./main-atoms";
import { sort } from "fast-sort";
import _ from "lodash";
import { constantCase } from "change-case";
import { useIsMobileBreakpoint } from "hooks";
import { useAtom } from "jotai";

const OrgsAndSuitesInfoContext = React.createContext({});

export const useOrgsAndSuitesInfoContext = () => {
  const state = useContext(OrgsAndSuitesInfoContext);
  if (!state) {
    throw new Error(
      "useOrgsAndSuitesInfoContext must be used within OrgsAndSuitesInfoProvider"
    );
  }
  return state;
};

const sortOptions = [
  {
    value: "count|desc",
    label: "Default (most visited suites first)",
  },
  {
    value: "suite.fullName|asc",
    label: "Suite Name (A -> Z)",
  },
  {
    value: "suite.fullName|desc",
    label: "Suite Name (Z -> A)",
  },
  {
    value: "org.fullName|asc",
    label: "Organization Name (A -> Z)",
  },
  {
    value: "org.fullName|desc",
    label: "Organization Name (Z -> A)",
  },
];

const applySort = (orgSuites, sortMethod) => {
  const [orderBy, order] = sortMethod.split("|");

  const sortedOrgsAndSuitesArray =
    order === "desc"
      ? sort(orgSuites).desc((obj) => _.get(obj, orderBy))
      : sort(orgSuites).asc((obj) => _.get(obj, orderBy));

  return sortedOrgsAndSuitesArray;
};
const applyFilters = (orgSuites, query, filters = []) => {
  const filteredOrgSuites = filters.length
    ? orgSuites.filter((orgSuite) => filters.includes(orgSuite.org.fullName))
    : [];

  return filteredOrgSuites.filter((orgSuite) => {
    let matches = true;
    if (query) {
      const properties = ["fullName"];
      let containsQuery = false;
      // the regex expression is to avoid parsing through html expressions in minutes etc
      // regex expression not being used.. use if needed.. stringSum.toLowerCase().replace(/<[^>]+>/g, '').includes(query.toLowerCase())
      {
        /* if (stringSum.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }*/
      }
      properties.forEach((property) => {
        if (
          orgSuite.org &&
          orgSuite.org[property]?.toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        } else if (
          orgSuite.suite &&
          orgSuite.suite[property]?.toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const OrgsAndSuitesInfoProvider = ({ children }) => {
  const { userInfo = {} } = useAppContext();
  const [mainDashboardSuiteSearchQuery, setMainDashboardSuiteSearchQuery] =
    useAtom(mainDashboardSuiteSearchQueryAtom);
  const [
    mainDashboardSuiteSearchSortMethod,
    setMainDashboardSuiteSearchSortMethod,
  ] = useAtom(mainDashboardSuiteSearchSortMethodAtom);
  const [
    mainDashboardSuiteSearchFilterList,
    setMainDashboardSuiteSearchFilterList,
  ] = useAtom(mainDashboardSuiteSearchFilterListAtom);
  const [subscriptionContext, setSubscriptionContext] = React.useState("RETRY");
  const isMobile = useIsMobileBreakpoint();

  const minSuitesForSearchDisplay = isMobile ? 4 : 8;

  const {
    data: allAnalytics = [],
    status: allAnalyticsStatus,
    apiError: allAnalyticsApiError,
  } = useUserRecentItems();

  const {
    data: orgsAndSuites = [],
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo._id);

  const [openSubscriptionDialog, setOpenSubscriptionDialog] =
    React.useState(false);
  const [targetOrgId, setTargetOrgId] = React.useState(null);
  const [targetSuiteId, setTargetSuiteId] = React.useState(null);

  const handleSubscriptionDialogClose = () => {
    setOpenSubscriptionDialog(false);
    setTargetOrgId(null);
    setTargetSuiteId(null);
    setSubscriptionContext("RETRY");
  };

  const handleSubscriptionDialogOpen = (orgId, suiteId, context = "RETRY") => {
    setTargetOrgId(orgId);
    setTargetSuiteId(suiteId);
    setOpenSubscriptionDialog(true);
    setSubscriptionContext(context);
  };
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleSearchClear = () => setMainDashboardSuiteSearchQuery("");

  const handleSearchQueryChange = (event) => {
    event.persist();
    setMainDashboardSuiteSearchQuery(event.target.value);
  };

  let personalOrgs = React.useMemo(() => {
    return (
      orgsAndSuites?.filter(
        (org) =>
          org.orgType === "PERSONAL" &&
          (org?.usersInOrg ?? []).some(
            (user) =>
              user._id === userInfo._id &&
              constantCase(user?.roleInfo?.role ?? "") === "ADMIN"
          )
      ) ?? []
    );
  }, [orgsAndSuites, userInfo._id]);

  let mainOrgs = React.useMemo(() => {
    return orgsAndSuites?.filter((org) => org.orgType !== "PERSONAL") ?? [];
  }, [orgsAndSuites]);

  const combinedMainOrgsAndSuitesArray = React.useMemo(() => {
    let returnArray = [];
    mainOrgs.forEach((org) => {
      if (checkIfNull(org?.suitesInOrg)) {
        returnArray.push({
          org: org,
          suite: null,
          count: 0,
        });
      } else {
        org?.suitesInOrg?.forEach((countSuite) => {
          let foundSuite = allAnalytics?.recentSuites?.find(
            (suite) => countSuite._id === suite._id
          );
          returnArray.push({
            org: org,
            suite: countSuite,
            count: foundSuite?.count ?? 0,
          });
        });
      }
    });
    return returnArray;
  }, [allAnalytics?.recentSuites, mainOrgs]);

  const allOrgNamesList =
    _.uniq(
      combinedMainOrgsAndSuitesArray.map((orgSuite) => orgSuite.org.fullName)
    ) ?? [];

  // React.useEffect(() => {
  //   if (mainDashboardSuiteSearchFilterList?.length === 0) {
  //     setMainDashboardSuiteSearchFilterList(allOrgNamesList);
  //   }
  // }, []);

  const {
    data: allSubscriptionsData,
    status: allSubscriptionsDataStatus,
    apiError: allSubscriptionsDataApiError,
  } = useSubscriptions({
    scope: "SUITE",
    list: combinedMainOrgsAndSuitesArray.map((orgSuite) => orgSuite.suite?._id),
  });

  const dataFetchError =
    allSubscriptionsDataApiError ||
    orgsAndSuitesApiError ||
    allAnalyticsApiError;
  const dataFetchLoading =
    allSubscriptionsDataStatus === "loading" ||
    allAnalyticsStatus === "loading" ||
    orgsAndSuitesStatus === "loading";

  const handleFilterSelect = (newList) =>
    setMainDashboardSuiteSearchFilterList(newList ?? []);

  const handleSortSelect = (value) =>
    setMainDashboardSuiteSearchSortMethod(value);

  const handleFilterClear = () =>
    setMainDashboardSuiteSearchFilterList(allOrgNamesList);

  const handleAllClear = () => {
    handleSearchClear();
    handleFilterClear();
  };

  const filteredOrgsAndSuitesArray = React.useMemo(
    () =>
      applyFilters(
        combinedMainOrgsAndSuitesArray,
        mainDashboardSuiteSearchQuery,
        mainDashboardSuiteSearchFilterList
      ),
    [
      combinedMainOrgsAndSuitesArray,
      mainDashboardSuiteSearchQuery,
      mainDashboardSuiteSearchFilterList,
    ]
  );
  const sortedAndFilteredOrgsAndSuitesArray = React.useMemo(
    () =>
      applySort(filteredOrgsAndSuitesArray, mainDashboardSuiteSearchSortMethod),
    [filteredOrgsAndSuitesArray, mainDashboardSuiteSearchSortMethod]
  );
  let filteredResultsFlag = Boolean(
    combinedMainOrgsAndSuitesArray.length - filteredOrgsAndSuitesArray.length
  );

  const fetchUserInfo = React.useCallback(
    (userId) => {
      let foundUserObj = null;
      for (let i = 0; i < combinedMainOrgsAndSuitesArray.length; i++) {
        const usersInSuite =
          combinedMainOrgsAndSuitesArray[i]?.suite?.usersInSuite ?? [];
        for (let j = 0; j < usersInSuite.length; j++) {
          if (usersInSuite[j]._id === userId) {
            return usersInSuite[j];
          }
        }
      }

      return foundUserObj;
    },
    [combinedMainOrgsAndSuitesArray]
  );
  const fetchDocketInfo = React.useCallback(
    (docketId) => {
      let foundDocketObj = null;
      for (let i = 0; i < combinedMainOrgsAndSuitesArray.length; i++) {
        const docketsInSuite =
          combinedMainOrgsAndSuitesArray[i]?.suite?.docketsInSuite ?? [];
        for (let j = 0; j < docketsInSuite.length; j++) {
          if (docketsInSuite[j]._id === docketId) {
            return docketsInSuite[j];
          }
        }
      }

      return foundDocketObj;
    },
    [combinedMainOrgsAndSuitesArray]
  );

  const fetchSuiteInfo = React.useCallback(
    (suiteId) => {
      let foundSuiteObj = null;
      for (let i = 0; i < combinedMainOrgsAndSuitesArray.length; i++) {
        if (combinedMainOrgsAndSuitesArray[i]?.suite?._id === suiteId) {
          return combinedMainOrgsAndSuitesArray[i]?.suite;
        }
      }
      return foundSuiteObj;
    },
    [combinedMainOrgsAndSuitesArray]
  );
  const fetchOrgInfo = React.useCallback(
    (orgId) => {
      let foundOrgObj = null;
      for (let i = 0; i < combinedMainOrgsAndSuitesArray.length; i++) {
        if (combinedMainOrgsAndSuitesArray[i]?.org?._id === orgId) {
          return combinedMainOrgsAndSuitesArray[i]?.org;
        }
      }
      return foundOrgObj;
    },
    [combinedMainOrgsAndSuitesArray]
  );
  const orgsAndSuitesInfoContextValues = {
    fetchUserInfo,
    fetchDocketInfo,
    fetchSuiteInfo,
    fetchOrgInfo,

    mainDashboardSuiteSearchQuery,
    setMainDashboardSuiteSearchQuery,
    mainDashboardSuiteSearchSortMethod,
    setMainDashboardSuiteSearchSortMethod,
    mainDashboardSuiteSearchFilterList,
    setMainDashboardSuiteSearchFilterList,
    subscriptionContext,
    setSubscriptionContext,
    sortOptions,
    isMobile,
    minSuitesForSearchDisplay,
    allAnalytics,
    allAnalyticsStatus,
    allAnalyticsApiError,
    orgsAndSuites,
    orgsAndSuitesStatus,
    orgsAndSuitesApiError,
    openSubscriptionDialog,
    setOpenSubscriptionDialog,
    targetOrgId,
    setTargetOrgId,
    targetSuiteId,
    setTargetSuiteId,
    handleSubscriptionDialogClose,
    handleSubscriptionDialogOpen,
    t,
    handleSearchClear,
    handleSearchQueryChange,
    personalOrgs,
    mainOrgs,
    combinedMainOrgsAndSuitesArray,
    allOrgNamesList,
    allSubscriptionsData,
    allSubscriptionsDataStatus,
    allSubscriptionsDataApiError,
    dataFetchError,
    dataFetchLoading,
    handleFilterSelect,
    handleSortSelect,
    handleFilterClear,
    handleAllClear,
    filteredOrgsAndSuitesArray,
    sortedAndFilteredOrgsAndSuitesArray,
    filteredResultsFlag,
  };

  return (
    <OrgsAndSuitesInfoContext.Provider
      value={{ ...orgsAndSuitesInfoContextValues }}
    >
      {children}
    </OrgsAndSuitesInfoContext.Provider>
  );
};
export default OrgsAndSuitesInfoProvider;
