// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box } from "@mui/material";
import {
  useAppContext,
  useDataSuiteAnalytics,
  useAnalytics,
  checkIfNull,
} from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useQueryClient } from "react-query";
import { DataSuiteDashboardViewLoading } from "blocks/views/LoadingPanels";
import { useLayoutContext } from "providers/LayoutProvider";
import { reservedFolderNames } from "blocks/modules/TrrystFiles/foundations";
import { getHumanisedTimeFormat } from "utils/formatTimeUtils";

import {
  DataSuiteWelcomeCard,
  DataSuiteTabbedOptionsPanel,
} from "blocks/views/SuiteDashboard";
import { formatBytes } from "utils";
import path from "path";
import { sort } from "fast-sort";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";
import PerfectScrollbar from "react-perfect-scrollbar";

const DataSuiteDashboardView = () => {
  const {
    isMobile,
    userInfo,
    totalChimeMinutes,
    upcomingMeetings,
    meetingsToRSVP,
    openSurveysAndResolutions,
    isSuiteManager,
    setAdditionalDataSuiteDashboardContextValues,
  } = useDashboardViewsDataContext();

  const queryClient = useQueryClient();
  const { selectedSuiteId, creditUsageDetails } = useAppContext();

  const {
    data: analyticsData,
    status: analyticsStatus,
    isFetching: dataSuiteAnalyticsFetching,
  } = useDataSuiteAnalytics({
    type: "ALL",
    suiteId: selectedSuiteId,
    userId: null,
  });

  const {
    data: storageAnalyticsData,
    status: storageAnalyticsStatus,
    isFetching: analyticsFetching,
  } = useAnalytics({
    scope: {
      scope: "SUITE",
      suiteId: selectedSuiteId,
    },
  });

  const analyticsDataDelayedTimeHumanized = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return undefined;

    const updatedAt = analyticsData.updatedAt;

    if (!updatedAt) return undefined;
    return getHumanisedTimeFormat(new Date(updatedAt));
  }, [analyticsData, analyticsStatus]);

  const handleRefreshData = React.useCallback(
    () => queryClient.invalidateQueries("dataAnalytics"),
    [queryClient]
  );

  const isDataFetching = React.useMemo(
    () => analyticsFetching || dataSuiteAnalyticsFetching,
    [analyticsFetching, dataSuiteAnalyticsFetching]
  );

  const totalStorageUsedInSuite = React.useMemo(() => {
    if (storageAnalyticsStatus !== "success" || !storageAnalyticsData)
      return null;
    if (
      !storageAnalyticsData.SUITE ||
      !storageAnalyticsData.SUITE.totals ||
      !storageAnalyticsData.SUITE.totals.totalS3StorageUsedBytes
    )
      return null;
    return storageAnalyticsData.SUITE.totals.totalS3StorageUsedBytes;
  }, [storageAnalyticsData, storageAnalyticsStatus]);

  const fileTypeAnalyticsInSuite = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return null;
    const fileTypeAnalytics = analyticsData.fileTypeAnalytics;
    if (!fileTypeAnalytics) return null;
    return fileTypeAnalytics;
  }, [analyticsData, analyticsStatus]);

  const totalFileViewsInSuite = React.useMemo(() => {
    if (!fileTypeAnalyticsInSuite || !analyticsData) return null;
    return fileTypeAnalyticsInSuite.reduce((a, b) => a + b.totalViews, 0);
  }, [analyticsData, fileTypeAnalyticsInSuite]);

  const keyContributorsInSuite = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return null;
    const keyContributors = analyticsData.keyContributors ?? [];
    if (checkIfNull(keyContributors)) return null;
    return keyContributors.map((e) => ({
      userId: e.userId,
      ...e.userInfo[0],
    }));
  }, [analyticsData, analyticsStatus]);

  const filesActivityData = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return [];
    const suiteTimelineData = analyticsData.suiteTimelineData ?? [];
    if (checkIfNull(suiteTimelineData)) return [];
    return suiteTimelineData.map((e = {}) => ({
      eventType: e.eventType,
      userId: e.userId,
      date: e.date,
      mimeType: e.mimeType ?? e.contentType,
      key: e.key,
      totalReadDuration: e.totalReadDuration,
      documentName:
        e.documentName ??
        (e.key ? e.key.split("/")[e.key.split("/").length - 1] : null),
      data: e.data,
    }));
  }, [analyticsData, analyticsStatus]);

  const suiteFileAnalytics = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return null;
    const fileAnalytics = analyticsData.fileAnalytics ?? [];
    if (checkIfNull(fileAnalytics)) return null;
    return fileAnalytics.map((e) => ({
      averageViewTimeMs: e.averageViewTimeMs,
      documentKey: e.documentKey,
      documentName: e.documentName,
      documentType: e.documentType,
      totalViews: e.totalViews,
    }));
  }, [analyticsData, analyticsStatus]);

  const checkIfReservedFolder = React.useCallback((folderName) => {
    let match = false;
    reservedFolderNames.forEach((reservedFolderName) => {
      if (folderName?.includes(`/${reservedFolderName}/`)) {
        match = true;
      }
    });
    return match;
  }, []);

  const currentFilesInSuite = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return [];
    if (checkIfNull(analyticsData?.fileAnalytics)) return [];
    let filteredData = analyticsData.fileAnalytics
      .filter((e) => !checkIfReservedFolder(e.documentKey))
      .map((e) => {
        const documentName = path.basename(e.documentKey);
        const fileExtensionSplit = documentName.split(".");
        const fileExtension = fileExtensionSplit[fileExtensionSplit.length - 1];
        return {
          key: e.documentKey,
          uploadedBy: e?.metadata?.userId,
          fileName: documentName,
          createdAt: e.createdAt,
          lastUpdated: e.updatedAt,
          fileType: e.mimeType || fileExtension,
          viewsCount: e.totalViews,
          averageViewTimeMs: e.averageViewTimeMs,
          totalViewTimeMs: e.totalViewTimeMs,
          fileSize: e?.properties?.totalSizeBytes ?? 0,
          isDeleted: false, //deleted or active | // you'll only get active files
        };
      });

    const sortedData = sort(filteredData).desc(
      (f) => f.lastUpdated || f.createdAt
    );

    return sortedData;
  }, [analyticsData, analyticsStatus, checkIfReservedFolder]);

  const fileAnalyticsMap = React.useMemo(() => {
    const map = new Map();
    if (!suiteFileAnalytics) return map;
    for (const file of suiteFileAnalytics) {
      map.set(file.documentKey, file);
    }
    return map;
  }, [suiteFileAnalytics]);

  // const deletedFilesInSuite = React.useMemo(() => {
  //   if (analyticsStatus !== "success" || !analyticsData) return null;
  //   if (!filesActivityData) return null;
  //   return filesActivityData.filter((e) => e.event === "app21:S3:Delete");
  // }, [analyticsData, analyticsStatus, filesActivityData]);

  const totalFilesInSuite = React.useMemo(() => {
    if (analyticsStatus !== "success" || !analyticsData) return null;
    if (
      !analyticsData.totalFilesInSuite ||
      analyticsData.totalFilesInSuite.length < 0
    )
      return null;
    const totalFiles = analyticsData.totalFilesInSuite[0];
    if (!totalFiles) return null;
    return (totalFiles.totalPut || 0) - (totalFiles.totalDelete || 0);
  }, [analyticsData, analyticsStatus]);

  const additionalDataSuiteData = {
    totalFilesInSuite,
    totalFileViewsInSuite,
    keyContributorsInSuite,
    filesActivityData,
    currentFilesInSuite,
    checkIfReservedFolder,
    handleRefreshData,
    suiteFileAnalytics,
    fileTypeAnalyticsInSuite,
    totalStorageUsedInSuite,
    isDataFetching,
    fileAnalyticsMap,
    analyticsDataDelayedTimeHumanized,
  };

  React.useEffect(() => {
    setAdditionalDataSuiteDashboardContextValues({
      ...additionalDataSuiteData,
    });
  }, [
    isDataFetching,
    totalFilesInSuite,
    totalFileViewsInSuite,
    keyContributorsInSuite,
    filesActivityData,
    currentFilesInSuite,
    checkIfReservedFolder,
    handleRefreshData,
    suiteFileAnalytics,
    fileTypeAnalyticsInSuite,
    totalStorageUsedInSuite,
    analyticsDataDelayedTimeHumanized,
    fileAnalyticsMap,
  ]);

  if (analyticsStatus === "loading" || storageAnalyticsStatus === "loading") {
    return <DataSuiteDashboardViewLoading />;
  } else {
    return (
      <FlowBox sx={{ gap: 3, overflow: "hidden" }}>
        <Box>
          <DataSuiteWelcomeCard />
        </Box>
        <DataSuiteTabbedOptionsPanel />

        {/* 
          needingAttentionItemsFlag={needingAttentionItemsFlag}
          fileMap={fileAnalyticsMap}
          recentFiles={currentFilesInSuite}
          contributors={keyContributorsInSuite}
          totalChimeMinutes={totalChimeMinutes}
          totalFileViews={totalFileViewsInSuite}
          filesActivityData={filesActivityData}
          isSuiteManager={isSuiteManager}
          totalFiles={totalFilesInSuite}
          creditUsageDetails={creditUsageDetails}
          totalSpaceUsed={formatBytes(
            totalStorageUsedInSuite ? totalStorageUsedInSuite : 0
          )}
        
        
        
        {needingAttentionItemsFlag ? (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", pt: 1 }}
            >
              
            </Grid>
          ) : (
            <Grid item />
          )}
          <Grid item xs={12}>
            <DataSuiteFileHighlightsPanel
              fileMap={fileAnalyticsMap}
              recentFiles={currentFilesInSuite}
              contributors={keyContributorsInSuite}
              totalChimeMinutes={totalChimeMinutes}
              totalFileViews={totalFileViewsInSuite}
              filesActivityData={filesActivityData}
              isSuiteManager={isSuiteManager}
              totalFiles={totalFilesInSuite}
              creditUsageDetails={creditUsageDetails}
              totalSpaceUsed={formatBytes(
                totalStorageUsedInSuite ? totalStorageUsedInSuite : 0
              )}
            />
          </Grid>

          < item xs={12}>
            {/* {analyticsDataDelayedTimeHumanized && (
              <Typography sx={{ my: 1 }}>
                Last Refreshed: {analyticsDataDelayedTimeHumanized}
              </Typography>
            )} 
          </Grid>*/}

        {/* <Grid
          item
          xs={12}
          md={isLargeScreen ? 3 : 12}
          justifyContent="stretch"
          alignItems="stretch"
        >
          {/* {isSuiteManager && (
            <Grid item xs={12} sx={{ flex: 0 }}>
              <SuiteCreditDetailsPanel
                isRefreshing={isRefreshing}
                handleRefreshData={handleRefreshData}
              />
            </Grid>
          )} */}
        {/* <DashboardRightSidebar /> */}
        {/* <DataSuiteActivityLogsPanel
              timelineData={suiteTimelineData}
              dataView={dataView}
              handleDataViewToggle={handleDataViewToggle}
            /> 
        </Grid> */}
      </FlowBox>
    );
  }
};

export default DataSuiteDashboardView;
