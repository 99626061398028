// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import {
  Box,
  Skeleton,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",
    flexGrow: 1,
    height: "100%",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      top: 0,
      content: "''",
      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      bgcolor: (theme) => alpha(theme.palette.grey[600], 0.72),
    },
  };
});

export default function BoardroomDashboardViewLoading() {
  const mobileMinWidth = useMediaQuery("(min-width:1100px)");
  return (
    <Box
      sx={{
        pt: 1,
        pl: 1,
        pb: 2,
        weight: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        <Grid
          item
          xs={mobileMinWidth && true ? 8 : 12}
          sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}
        >
          <RootStyle
            ownerState={{
              backgroundPicture: "",
            }}
          >
            <CardContent
              sx={{
                pt: 1,
              }}
            >
              <Skeleton
                variant="text"
                height={30}
                sx={{
                  backgroundColor: "#ffffff",
                  mt: 2,
                }}
              />
              <Skeleton
                variant="text"
                width={300}
                height={30}
                sx={{
                  backgroundColor: "#ffffff",
                  mt: 1,
                }}
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: `10px 10px 10px 10px`,
                  boxShadow: "0px 3px 15px rgba(120,120,120,0.1)",
                  mt: 1,
                }}
              />
            </CardContent>
          </RootStyle>
          <Skeleton
            variant="rectangular"
            width={250}
            height={175}
            sx={{
              backgroundColor: "#ffffff",

              borderRadius: `14px 14px 14px 14px`,
              ml: 1,
            }}
          />
        </Grid>
        {true && (
          <Grid
            item
            xs={mobileMinWidth && true ? 8 : 12}
            sx={{ display: "flex", flexDirection: "row", flexGrow: 1, mt: 1 }}
          >
            <RootStyle
              ownerState={{
                backgroundPicture: "",
              }}
            >
              <Skeleton
                variant="rectangular"
                height={150}
                width={300}
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: `14px 14px 14px 14px`,
                }}
              />
            </RootStyle>
            <Skeleton
              variant="rectangular"
              width={600}
              height={150}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: `14px 14px 14px 14px`,
                ml: 3,
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={mobileMinWidth && !false ? 8 : 12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: `10px 10px 10px 10px`,
              border: "1px solid #ffffff",
              mt: 1,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
