// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import FileViewer from "blocks/modules/FileViewer";
import { useAppContext } from "@app21/core";
import {
  BoardroomDashboardViewLoading,
  DataSuiteDashboardViewLoading,
  ExecutiveSuiteDashboardViewLoading,
  PersonalSuiteDashboardViewLoading,
} from "blocks/views/LoadingPanels";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";
import {
  BoardroomDashboardView,
  DataSuiteDashboardView,
  PersonalSuiteDashboardView,
  ExecutiveSuiteDashboardView,
} from "blocks/views/SuiteDashboard";

const SuiteDashboardViewWrapper = () => {
  const { viewFile } = useAppContext();
  const { dataFetchLoading, selectedSuite } = useDashboardViewsDataContext();
  const getSuiteDashboardLoaderComponent = (suiteType) => {
    switch (true) {
      case suiteType?.includes("EXEC"):
        return <ExecutiveSuiteDashboardViewLoading />;
      case suiteType?.includes("PERSONAL"):
        return <PersonalSuiteDashboardViewLoading />;
      case suiteType?.includes("BOARD"):
        return <BoardroomDashboardViewLoading />;
      case suiteType?.includes("DATA"):
        return <DataSuiteDashboardViewLoading />;
      default:
        return <PersonalSuiteDashboardViewLoading />;
    }
  };

  const getSuiteDashboardViewComponent = (suiteType) => {
    switch (true) {
      case suiteType?.includes("DATA"):
        return <DataSuiteDashboardView />;
      case suiteType?.includes("EXEC"):
        return <ExecutiveSuiteDashboardView />;
      case suiteType?.includes("PERSONAL"):
        return <PersonalSuiteDashboardView />;
      case suiteType?.includes("BOARD"):
        return <BoardroomDashboardView />;

      default:
        return <PersonalSuiteDashboardView />;
    }
  };
  if (viewFile) {
    return <FileViewer viewContext={"SUITE"} />;
  } else if (dataFetchLoading) {
    return getSuiteDashboardLoaderComponent(selectedSuite?.suiteType);
  } else return getSuiteDashboardViewComponent(selectedSuite?.suiteType);
};

export default SuiteDashboardViewWrapper;
