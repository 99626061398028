//import { Buffer } from "buffer";
import { checkIfNull } from "@app21/core";
import mime from "mime-types";

export function fileUploadProcessor(data, prefixTimestampToFilename = false) {
  let dataWithTimeStampAddedToFilename = prefixTimestampToFilename
    ? data.map((file) => {
        const newFile = new File([file], `${Date.now()}-${file.name}`);
        return Object.assign(newFile, {
          path: `${Date.now()}-${file.path || file.name}`,
        });
      })
    : data;

  let dataWithPreview = dataWithTimeStampAddedToFilename?.map((file) => {
    return Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
  });
  let dataWithConfirmedContentType = dataWithPreview?.map((file) => {
    if (checkIfNull(file?.type)) {
      return Object.defineProperty(file, "type", {
        value: mime.lookup(file.name?.split(".")?.pop()),
        writable: true,
        enumerable: true,
        configurable: true,
      });
    } else {
      return file;
    }
  });
  return dataWithConfirmedContentType;
}

// export function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(",");
//     var mime = arr[0].match(/:(.*?);/)[1];
//     var bstr = Buffer.from(arr[1], 'base64').toString('utf8');
//     var n = bstr.length,
//     var u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], filename, { type: mime });
// }

// export function handleCopyPaste(e) {
//     try {
//       const clipboardData = e.clipboardData;
//       const items = clipboardData.items;
//       if (items) {
//         for (let i = 0; i < items.length; i++) {
//           if (items[i].type.indexOf("image") !== -1) {
//             const blob = items[i].getAsFile();
//             const reader = new FileReader();
//             reader.onload = (event) => {
//               const image = new Image();
//               image.src = event.target.result;
//               image.onload = () => {
//                 const canvas = document.createElement("canvas");
//                 const ctx = canvas.getContext("2d");
//                 canvas.width = image.width;
//                 canvas.height = image.height;
//                 ctx.drawImage(image, 0, 0);
//                 const dataURL = canvas.toDataURL("image/png");
//                 const file = dataURLtoFile(dataURL, "image.png");
//               //  setFile({ file: file, name: file.name });
//                 console.log(file);
//               };
//             };
//             reader.readAsDataURL(blob);
//           }
//         }
//       }
//     } catch (e) {
//     //  onError(e);
//     }
//   }
