import React from "react";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  AverageViewIcon,
  ArrowSquareDownIcon,
  FilterIcon,
  TextalignJustifycenterIcon,
  GridIcon,
  IncreaseIcon,
  ReceiptSearchIcon,
  DurationIcon,
  KeyContributorsIcon,
  FileEngagementIcon,
  FilesDownloadedIcon,
  UpdatedFilesIcon,
  SearchStatusIcon,
  DirectBoxReceiveIcon,
  TimeLineIcon,
  DropDownArrowIcon,
} from "blocks/atoms/trrysticons";
import DataroomSearchPanel from "../DataroomSearchPanel";
import DataSuiteFileActivitySummaryPanel from "./DataSuiteFileActivitySummaryPanel";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

export default function DataSuiteHiglightsPanel({}) {
  const [viewType, setViewType] = React.useState("gridView");
  const { isSuiteManager } = useDashboardViewsDataContext();

  const handleViewType = (event, newValue) => {
    setViewType(newValue);
  };

  return (
    <FlowBox sx={{ p: 2 }}>
      <Box sx={{ display: "flex", gap: 3, py: 1 }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", gap: 3, minWidth: 350 }}>
          <DataroomSearchPanel />
          <ToggleButtonGroup
            value={viewType}
            color="primary"
            exclusive
            onChange={handleViewType}
            aria-label="File List view mode"
          >
            <Tooltip title="List View" placement="top">
              <ToggleButton value="listView" aria-label="List mode">
                <TextalignJustifycenterIcon fontSize="inherit" />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Grid View" placement="top">
              <ToggleButton value="gridView" aria-label="Grid mode">
                <GridIcon fontSize="inherit" />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <DataSuiteFileActivitySummaryPanel viewType={viewType} />
    </FlowBox>
  );
}
