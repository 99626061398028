// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Stepper(theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
  };
}
