// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  useAppContext,
  executeApi,
  app21APIInstance,
  useHandleSuiteActions,
  useHandleOrganizationActions,
} from "@app21/core";
import { useConfirm } from "material-ui-confirm";

export default function useSuiteStatusHandlers({
  suiteId = null,
  orgId = null,
  docketId = null,
}) {
  const { userInfo, selectedOrganizationId, selectedSuiteId } = useAppContext();
  const confirm = useConfirm();
  const { handleSuiteActions } = useHandleSuiteActions();
  const { handleOrganizationActions } = useHandleOrganizationActions();

  const handleRetryPayment = async () => {
    const { data: response } = await app21APIInstance.post(
      "/stripe/payments/sessions/create",
      {
        type: "RETRY-SUITE-PAYMENT",
        suiteId: suiteId,
        calledBy: userInfo._id,

        orgId: orgId,
      }
    );
    // also include above.. subscriptionChoice,
    // couponCode,
    if (response && response.data && response.data.paymentURL) {
      window.location.href = response.data.paymentURL;
    }
  };

  const handleSuiteReactivation = async () => {
    try {
      await confirm({
        description:
          "You are within the 14-day grace window to reactivate this suite and restore back to normal subscription model. As a manager of this suite, are you sure you want to proceed with reactivation ?",
      });
      await handleSuiteActions(
        {
          action: "UPDATE-SUITE",
          suiteId: suiteId,
          organizationId: orgId,
          changeData: {
            reactivateSuite: true,
          },
        },
        null,
        null
      );
      window.location.reload();
    } catch (err) {
      console.log("Nevermind!!");
    }
  };

  const handleDeleteSuite = async () => {
    // Raghu to wire this up and close asana if all working
    //Also, raghu to check the process around handling cleanup of orphaned orgs and suites

    confirm({
      description:
        "Are you sure you want to delete the suite. Please note that the action is irreversible and you will lose all Data within the suite immediately?",
    })
      .then(async () => {
        await handleOrganizationActions(
          {
            action: "DETACH-SUITE",
            suiteId: suiteId,
            organizationId: orgId,
            deleteImmediately: true,
          },
          null,
          null
        );
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      })
      .finally(() => null);
  };

  const handleSuiteLeave = async () => {
    confirm({
      description:
        "Are you sure you want to exit this Suite? You cannot join back unless someone adds you back in.",
    })
      .then(async () => {
        await handleSuiteActions({
          action: "DETACH-USER",
          userId: userInfo?._id,
          suiteId: suiteId,
        });
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      })
      .finally(() => {
        // if anyting to be done
      });
  };

  const handleOrgLeave = async () => {
    confirm({
      description:
        "Are you sure you want to leave the organization? You cannot join back unless someone invites you into the organizaion.",
    })
      .then(async () => {
        await handleOrganizationActions({
          action: "DETACH-USER",
          userId: userInfo?._id,
          organizationId: orgId,
        });
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      })
      .finally(() => {
        // if anyting to be done
      });
  };

  return {
    handleRetryPayment,
    handleSuiteReactivation,
    handleDeleteSuite,
    handleOrgLeave,
    handleSuiteLeave,
  };
}
