// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const UserRoleUpdatedSuite = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  const data = {
    message: `${
      payload?.detachedSuiteFullName || "Suite"
    } has been detached from ${
      payload?.organizationFullName || "Organization"
    }`,
    avatar: getS3ImageWrapper(payload?.detachedSuiteImage, "user"),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default UserRoleUpdatedSuite;
