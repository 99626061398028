import React from "react";
import { Box, Typography, Divider, Grid, Card, Stack } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { Label, UserAvatar, AvatarGroupWithMore } from "blocks/atoms";
import {
  KeyContributorsIcon,
  FileEngagementIcon,
  FilesDownloadedIcon,
  UpdatedFilesIcon,
  SearchStatusIcon,
  DirectBoxReceiveIcon,
  TimeLineIcon,
  DropDownArrowIcon,
} from "blocks/atoms/trrysticons";
import { checkIfNull, useHandleRouterPush, useAppContext } from "@app21/core";
import DataSuiteEngagementInsightCard from "./DataSuiteEngagementInsightCard";
import FileActivityLogSnippet from "./FileActivityLogSnippet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useIsMobileBreakpoint } from "hooks";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

export default function DataSuiteUsageActivityPanel({}) {
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();
  const {
    filesActivityData = [],
    contributors = [],
    totalFileViews = 0,
    isSuiteManager = false,
    totalChimeMinutes = 0,
    totalFiles = 0,
    totalSpaceUsed = 0,
    creditUsageDetails = {},
  } = useDashboardViewsDataContext();
  const isSmallSize = useIsMobileBreakpoint(1300, true);
  const { loadRoute } = useHandleRouterPush();
  const engagementInsightsDataList = [
    {
      Icon: FileEngagementIcon,
      title: "File Engagements",
      value: totalFileViews,
      units: "views",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/analytics?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}`,
        }),
    },
    {
      Icon: KeyContributorsIcon,
      title: "Key Contributors",
      value: contributors?.length ? (
        <AvatarGroupWithMore max={6} size={20} sx={{ ml: 1 }}>
          {contributors?.map((e) => (
            <UserAvatar
              userId={e?.userId}
              key={e.userId}
              alt={e?.fullName}
              size={20}
            />
          ))}
        </AvatarGroupWithMore>
      ) : null,
      units: "",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/analytics?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}`,
        }),
    },
    {
      Icon: SearchStatusIcon,
      title: "# of Files",
      value: totalFiles,
      units: "",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/analytics?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}`,
        }),
    },
    // {
    //   Icon: FilesDownloadedIcon,
    //   title: "Downloads",
    //   value: "",
    //   units: "",
    //   uptrendFlag: false,
    //   downtrendFlag: false,
    //   subheader: null,
    // },
    {
      Icon: FilesDownloadedIcon,
      title: "Meeting Time",
      value: Math.round(totalChimeMinutes / 60),
      units: "Person-Hrs",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/manage?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}&tabValue=suiteanalytics`,
        }),
    },

    {
      Icon: UpdatedFilesIcon,
      title: "Space Used",
      value: totalSpaceUsed,
      units: "",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/manage?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}&tabValue=suiteanalytics`,
        }),
    },
    {
      Icon: DirectBoxReceiveIcon,
      title: "Credits Used",
      value: creditUsageDetails.availableMonthlyCredits
        ? Math.round(
            (creditUsageDetails?.totalUsedCredits /
              creditUsageDetails?.availableMonthlyCredits ?? 0) * 100
          )
        : 0,
      units: "% used",
      uptrendFlag: false,
      downtrendFlag: false,
      subheader: null,
      onClick: () =>
        loadRoute("GOTO-LINK", {
          hrefLink: `/user/organization/suite/manage?suiteId=${selectedSuiteId}&orgId=${selectedOrganizationId}&tabValue=suiteanalytics`,
        }),
    },
    // {
    //   Icon: DirectBoxReceiveIcon,
    //   title: "Deleted Files",
    //   value: "4",
    //   units: "",
    //   uptrendFlag: false,
    //   downtrendFlag: false,
    //   subheader: null,
    // },
  ];

  return (
    <FlowBox>
      <Grid container spacing={4} alignItems="flex-start" sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} container spacing={4}>
          {engagementInsightsDataList.map((item, index) => {
            return (
              <Grid key={index} item xs={6}>
                <DataSuiteEngagementInsightCard {...item} />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} md={6} sx={{ overflow: "hidden" }}>
          <FlowBox sx={{ maxHeight: 400 }}>
            <PerfectScrollbar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Files Activity</Typography>
                <Box>
                  {/* <Button
                    startIcon={<FilterIcon />}
                    sx={{ borderRadius: 1 }}
                    variant="outlined"
                    size="small"
                  >
                    Filter by : Author Name
                  </Button> */}
                </Box>
              </Box>
              <Box sx={{ height: 450, pr: 2 }}>
                {filesActivityData?.map((fileLog, index) => {
                  if (checkIfNull(fileLog)) return null;
                  return (
                    <FileActivityLogSnippet
                      key={index}
                      fileLog={fileLog}
                      isSmallSize={isSmallSize}
                    />
                  );
                })}
              </Box>
            </PerfectScrollbar>
          </FlowBox>
        </Grid>
      </Grid>
    </FlowBox>
  );
}

{
  /*
        <Grid item xs={12} lg={6} sm={6} md={6}>
         
        </Grid>
      </Grid>


*/
}
