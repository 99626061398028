// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------
import { pxToRem } from "utils/styleHelpers";
import {
  IBM_Plex_Sans,
  // Hanken_Grotesk,
  Poppins,
  Signika,
} from "@next/font/google";

const ibmPlexSans = IBM_Plex_Sans({
  weight: ["500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Roboto", "Arial", "sans-serif"],
});

// const hankenGrotesk = Hanken_Grotesk({
//   weight: ["variable"],
//   subsets: ["latin"],
//   display: "swap",
//   fallback: ["Roboto", "Arial", "sans-serif"],
// });

const signika = Signika({
  weight: ["variable"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Roboto", "Arial", "sans-serif"],
});
const poppins = Poppins({
  weight: ["300", "400", "500"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Roboto", "Arial", "sans-serif"],
});

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:960px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1280px)": {
      fontSize: pxToRem(lg),
    },
  };
}
const BASE_FONT_SIZE = 13;

const FONT_SUBTITLES = signika.style.fontFamily; // Google Font
const FONT_HEADINGS = ibmPlexSans.style.fontFamily;
const FONT_BODYTEXT = poppins.style.fontFamily;
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_BODYTEXT,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 700,
    fontSize: pxToRem(2.5 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: pxToRem(2.1 * BASE_FONT_SIZE),
      md: pxToRem(2.3 * BASE_FONT_SIZE),
      lg: pxToRem(2.5 * BASE_FONT_SIZE),
    }),
  },
  h2: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 600,
    lineHeight: 64 / 48,
    fontSize: pxToRem(2.3 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: 1.9 * BASE_FONT_SIZE,
      md: 2.1 * BASE_FONT_SIZE,
      lg: 2.3 * BASE_FONT_SIZE,
    }),
  },
  h3: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(1.7 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: 1.5 * BASE_FONT_SIZE,
      md: 1.6 * BASE_FONT_SIZE,
      lg: 1.7 * BASE_FONT_SIZE,
    }),
  },
  h4: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(1.4 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: 1.3 * BASE_FONT_SIZE,
      md: 1.35 * BASE_FONT_SIZE,
      lg: 1.4 * BASE_FONT_SIZE,
    }),
  },
  h5: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(1.2 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: 1.1 * BASE_FONT_SIZE,
      md: 1.15 * BASE_FONT_SIZE,
      lg: 1.2 * BASE_FONT_SIZE,
    }),
  },
  h6: {
    fontFamily: FONT_HEADINGS,
    fontWeight: 500,
    lineHeight: 26 / 18,
    fontSize: pxToRem(1.1 * BASE_FONT_SIZE),
    ...responsiveFontSizes({
      sm: 1 * BASE_FONT_SIZE,
      md: 1.1 * BASE_FONT_SIZE,
      lg: 1.1 * BASE_FONT_SIZE,
    }),
  },
  subtitle1: {
    fontFamily: FONT_SUBTITLES,
    fontWeight: 550,
    lineHeight: 1.5,
    fontSize: pxToRem(1.1 * BASE_FONT_SIZE),
  },
  subtitle2: {
    fontFamily: FONT_SUBTITLES,
    fontWeight: 550,
    lineHeight: 20 / 14,
    fontSize: pxToRem(BASE_FONT_SIZE),
  },
  body1: {
    fontFamily: FONT_BODYTEXT,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(BASE_FONT_SIZE),
  },
  body2: {
    fontFamily: FONT_BODYTEXT,
    fontWeight: 400,
    lineHeight: 20 / 14,
    fontSize: pxToRem(0.9 * BASE_FONT_SIZE),
  },
  caption: {
    fontFamily: FONT_BODYTEXT,
    fontWeight: 400,
    lineHeight: 20 / 14,
    fontSize: pxToRem(0.7 * BASE_FONT_SIZE),
  },
  overline: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontFamily: FONT_SUBTITLES,
    fontSize: pxToRem(0.8 * BASE_FONT_SIZE),
    letterSpacing: 1.1,
    textTransform: "uppercase",
  },
  button: {
    fontFamily: FONT_SUBTITLES,
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(1 * BASE_FONT_SIZE),
    textTransform: "capitalize",
  },
};

export default typography;
