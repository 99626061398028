// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useContext, useState } from "react";
import {
  useAppContext,
  checkIfNull,
  useUsersInSuite,
  useMeeting,
  executeApi,
  useHandleAddRemoveIdFromRoute,
} from "@app21/core";
import { Typography } from "@mui/material";
import { DisplayMeetingAttendeesList } from "blocks/modules/TrrystVideocall";
import { useConfirm } from "material-ui-confirm";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useRouter } from "next/router";

const VideocallContext = React.createContext({});

export const useVideocallContext = () => {
  const state = useContext(VideocallContext);

  if (!state) {
    throw new Error(
      "useVideocallContext must be used within VideocallProvider"
    );
  }

  return state;
};

const VideocallProvider = ({ children }) => {
  const { meetingId, userInfo, checkAccessRules, setChimeViewMode } =
    useAppContext();
  const { data: meeting, apiError: meetingAPIError } = useMeeting(meetingId);

  const { errorReason: meetingAPIErrorReason } = meetingAPIError ?? {};

  const { data: usersInMeetingSuite } = useUsersInSuite(meeting?.suiteId);
  const isBotJoining = userInfo?.emailId === "app21chimerecorder@trryst.com";
  const isMeetingCreator =
    userInfo?._id === meeting?.data?.meetingJSON?.createdBy;

  const chimeNotistackRef = React.useRef();
  const [chimeAttendeeId, setChimeAttendeeId] = useState(null);
  const [chimeRoute, setChimeRoute] = useState("base");
  const [isUserTriggeredConference, setIsUserTriggeredConference] =
    React.useState(false);
  const [isGuestUser, setIsGuestUser] = React.useState(false);
  const [isRecordingBotEnabled, setIsRecordingBotEnabled] =
    React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [isRecordingBotReady, setIsRecordingBotReady] = React.useState(false);
  const [isRecordingBotError, setIsRecordingBotError] = React.useState(false);
  const [isCollaborationActive, setIsCollaborationActive] =
    React.useState(false);
  const [collaborationPaused, setCollaborationPaused] = React.useState(false);
  const [guestAccessStatus, setGuestAccessStatus] = React.useState("NEEDED");
  const [isChatModalOpen, setIsChatModalOpen] = React.useState(false);
  const [joinInfo, setJoinInfo] = useState();
  const [elementDimensionsWidth, setElementDimensionsWidth] =
    React.useState(100);
  const [elementDimensionsHeight, setElementDimensionsHeight] =
    React.useState(100);

  const [endMeetingForAllReceived, setEndMeetingForAllReceived] =
    React.useState(false);

  const recommendedButtonSize = React.useMemo(
    () => Math.min(Math.max(Math.round(elementDimensionsWidth / 9), 20), 40),
    [elementDimensionsWidth]
  );

  const confirm = useConfirm();
  const [existingMeetingInfo, setExistingMeetingInfo] = React.useState(null);
  const [meetingChecksCompleted, setMeetingChecksCompleted] =
    React.useState(false);

  const router = useRouter();
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  // need to gather the users of the suiteId to which this meeting belongs.
  // cant be selectedSuiteId as in the case of guest users the selectedSuiteId will be equal to
  // personal suiteId

  const { accessFlag: canOperateRecording } = checkAccessRules({
    entity: "MEETINGS",
    action: "RECORD-VIDEOCALL",
    featureName: "VIDEOCALL-RECORDING",
    isCreator: isMeetingCreator,
    isInvitee: true,
  });

  const chimePermissions = {
    canOperateRecording,
    default: true,
  };

  const [meetingEndedForAllUsers, setMeetingEndedForAllUsers] =
    React.useState(false);

  React.useEffect(() => {
    if (
      meetingAPIErrorReason === "meetingid-does-not-exist" ||
      meetingAPIErrorReason === "meeting-ended" ||
      meetingAPIErrorReason === "meeting-deleted"
    ) {
      // need to remove the meeting id from route
      router.push(removeIdFromRoute({ paramKey: "meetingId" }), {
        shallow: true,
      });
    }
  }, [meeting]);

  useDeepCompareEffect(() => {
    if (meeting?.data?.collaborationLogs.length === 0)
      setIsCollaborationActive(false);

    const lastCollabLogsEntry =
      meeting?.data?.collaborationLogs?.slice(-1).pop() || {};

    if (lastCollabLogsEntry.status === "ACTIVE") {
      setIsCollaborationActive(true);
      setChimeViewMode("fullscreen");
    } else if (lastCollabLogsEntry.status === "STOPPED") {
      setIsCollaborationActive(false);
      setChimeViewMode("normal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting ?? {}]);

  React.useEffect(() => {
    if (!meetingId) setExistingMeetingInfo(null);
    if (!existingMeetingInfo && meetingId) {
      executeApi("FETCH-CHIME-MEETING", {
        meetingId: meetingId,
        userId: userInfo?._id,
      }).then((result) => {
        setExistingMeetingInfo(result);
      });
    }
  }, [existingMeetingInfo, meetingId]);

  React.useEffect(() => {
    if (guestAccessStatus === "NEEDED") {
      setChimeRoute("base");
      setMeetingChecksCompleted(true);
    } else if (existingMeetingInfo?.data) {
      const { isMeetingActive, attendeesInMeeting, userInMeetingId } =
        existingMeetingInfo.data;

      //excluding chime bot from the list of attendees in dev and prod
      const filteredAttendeesInMeeting =
        attendeesInMeeting?.filter(
          (attendee) =>
            attendee.ExternalUserId !==
            (process.env.STAGE !== "prod"
              ? "61cccc61c55a67549e5eae1c"
              : "61d5b972219ecb0ab1461954")
        ) ?? [];
      if (isMeetingActive && userInMeetingId === meetingId) {
        setMeetingChecksCompleted(true);
      } else if (
        meetingId &&
        isMeetingActive &&
        userInMeetingId !== meetingId
      ) {
        if (filteredAttendeesInMeeting.length > 0) {
          confirm({
            description: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body2">
                  The meeting is active with the following participants. Do you
                  wish to join?
                </Typography>
                <DisplayMeetingAttendeesList
                  attendees={filteredAttendeesInMeeting}
                />
              </div>
            ),
            title: "Please Confirm",
          })
            .then(async () => {
              setMeetingChecksCompleted(true);
            })
            .catch(async () => {
              router.push(removeIdFromRoute({ paramKey: "meetingId" }), {
                shallow: true,
              });
            });
        } else setMeetingChecksCompleted(true);
      } else {
        setMeetingChecksCompleted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingMeetingInfo, meetingId]);

  React.useEffect(() => {
    if (isBotJoining) return;
    if (!checkIfNull(meeting) && !checkIfNull(usersInMeetingSuite)) {
      let isMember = false;
      let guestVisitorAccessStatus = "NEEDED";

      if (meeting?.data?.meetingType === "GROUP-MEETING") {
        // for group meetings decision of a user being a guest or not depends on whether
        // the user is a member of the suite or not.
        usersInMeetingSuite.map((user) => {
          if (userInfo && user._id === userInfo?._id) isMember = true;
        });
      } else {
        // for CLOSED-MEETINGS isMember when you are part of the attendees else you are a guest.
        isMember = meeting?.data?.meetingJSON?.members?.some(
          (user) => userInfo._id === user._id
        );
      }

      guestVisitorAccessStatus =
        meeting?.data?.meetingJSON?.visitors?.find(
          (user) => userInfo._id === user._id
        )?.status ?? "NEEDED";

      if (isMember) {
        setIsGuestUser(false);
        setGuestAccessStatus("GRANTED");
      } else {
        setIsGuestUser(true);
        setGuestAccessStatus(guestVisitorAccessStatus);
      }
    }
  }, [isBotJoining, meeting, usersInMeetingSuite, userInfo]);

  const providerValues = {
    meeting,
    usersInMeetingSuite,
    chimeRoute,
    setChimeRoute,
    chimeNotistackRef,
    chimeAttendeeId,
    setChimeAttendeeId,
    isRecordingBotEnabled,
    setIsRecordingBotEnabled,
    isRecordingBotError,
    setIsRecordingBotError,
    isRecording,
    setIsRecording,
    isRecordingBotReady,
    setIsRecordingBotReady,
    isGuestUser,
    setIsGuestUser,
    guestAccessStatus,
    setGuestAccessStatus,
    isBotJoining,
    isUserTriggeredConference,
    setIsUserTriggeredConference,
    collaborationPaused,
    setCollaborationPaused,
    isCollaborationActive,
    setIsCollaborationActive,
    meetingEndedForAllUsers,
    setMeetingEndedForAllUsers,
    elementDimensionsWidth,
    setElementDimensionsWidth,
    elementDimensionsHeight,
    setElementDimensionsHeight,
    recommendedButtonSize,
    chimePermissions,
    isMeetingCreator,
    isChatModalOpen,
    setIsChatModalOpen,
    joinInfo,
    setJoinInfo,
    endMeetingForAllReceived,
    setEndMeetingForAllReceived,
  };

  return (
    <VideocallContext.Provider value={providerValues}>
      {children}
    </VideocallContext.Provider>
  );
};

export default VideocallProvider;
