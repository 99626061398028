// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export function getNumberFromString(str = "", mod) {
  // get a predictable number between 0 to 255 given a string
  let modulus = mod ?? 255;

  const sum = str
    .split("")
    .map((c) => c.charCodeAt(0))
    .reduce((a, b) => a + b);

  return sum % modulus;
}
