import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useAtom } from "jotai";
import {
  showSurveysSidebarFlagAtom,
  selectedSurveysListAtom,
  selectedSurveyActionAtom,
  viewSurveysFilterContextAtom,
} from "blocks/modules/Surveys/surveys-atoms";

import {
  useUsersInSuite,
  useAppContext,
  useSuite,
  useDockets,
  executeApi,
  useSelectedBucketId,
  useHandleRouterPush,
  checkIfNull,
  useHandleResolutionActions,
  useResolutionsInDocket,
  useResolutionsInSuite,
  useDocketsInSuite,
} from "@app21/core";
import useDeepCompareEffect from "use-deep-compare-effect";
import { constantCase } from "change-case";

import { useMediaQuery, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import toast from "react-hot-toast";
import path from "path";
import { UploadOmniModal } from "blocks/atoms/uicomponents/upload";
import { SIZELIMITS } from "blocks/atoms/constants";

const SurveysContext = React.createContext({});

export const useSurveysContext = () => {
  const state = useContext(SurveysContext);
  if (!state) {
    throw new Error("useSurveysContext must be used within SurveysProvider");
  }
  return state;
};
const SurveysProvider = ({
  viewContext = "survey",
  actionContext = "list",
  isMobile = false,
  children,
}) => {
  const {
    meetingId,
    checkAccessRules,
    userInfo,
    chimeViewMode,
    viewFile,
    selectedSuiteId,
    selectedOrganizationId,
    selectedDocketId,
    decisionId: selectedDecisionId,
  } = useAppContext();

  const confirm = useConfirm();
  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const { data: baseDockets, status: baseDocketsStatus } =
    useDocketsInSuite(selectedSuiteId);
  console.log(baseDockets, "baseDockets, baseDocketsStatus");
  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(selectedSuiteId);
  const selectedBucketId = useSelectedBucketId(null, true);

  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);

  const isDocket = viewContext === "resolution" && Boolean(selectedDocketId);
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;
  const [resolutions, setResolutions] = React.useState([]);
  const [decisionsData, setDecisionsData] = React.useState(null);
  const { loadRoute } = useHandleRouterPush();
  const { handleResolutionActions } = useHandleResolutionActions();

  const fileViewerFlag = !checkIfNull(viewFile);

  const [selectedSurveysList, setSelectedSurveysList] = useAtom(
    selectedSurveysListAtom
  );
  const [selectedSurveyAction, setSelectedSurveyAction] = useAtom(
    selectedSurveyActionAtom
  );

  const isCreator =
    viewContext === "DOCKET"
      ? selectedDocket?.createdBy === userInfo._id
      : false;
  const isInvitee =
    viewContext === "DOCKET"
      ? (selectedDocket?.members ?? []).includes(userInfo?._id)
      : false;
  const xsSize = useMediaQuery("(min-width:700px)");
  const mdSize = useMediaQuery("(min-width:1200px)");

  const smallSizeFlag =
    !xsSize || (!mdSize && chimeViewMode === "normal" && Boolean(meetingId));

  const clipTextLength = smallSizeFlag || viewContext !== "DOCKET" ? 10 : 30;

  const { data: resolutionsInDocket, status: resolutionsInDocketStatus } =
    useResolutionsInDocket(selectedDocketId);
  const { data: resolutionsInSuite, status: resolutionsInSuiteStatus } =
    useResolutionsInSuite(selectedSuiteId, false);

  const { accessFlag: canAddNew } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "CREATE-RESOLUTION" : "CREATE-SURVEY",
    featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });

  const checkCanEdit = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "UPDATE-RESOLUTION" : "UPDATE-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const checkCanDelete = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "DELETE-RESOLUTION" : "DELETE-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const checkCanView = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "VIEW-RESOLUTION" : "VIEW-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const surveysPermissions = {
    canAddNew,
    checkCanEdit,
    checkCanDelete,
    checkCanView,
    default: true,
  };

  React.useEffect(() => {
    setResolutions((isDocket ? resolutionsInDocket : resolutionsInSuite) ?? []);
  }, [selectedDocketId, resolutionsInDocket, resolutionsInSuite]);

  const surveyStatusOptions = [];
  if (isDocket) {
    surveyStatusOptions.push({ label: "Draft Tabled", value: "DRAFT" });
  }
  surveyStatusOptions.push({ label: "Opened for Voting", value: "OPEN" });
  surveyStatusOptions.push({ label: "Closed", value: "CLOSED" });

  useDeepCompareEffect(() => {
    let surveyData = [];

    resolutions?.forEach((resolution) => {
      let userIsInvited = false;
      let isOwner = userInfo._id === resolution.createdBy;
      let userResponseStatus = "pending";

      userIsInvited = resolution?.survey?.decisionInvitees?.includes(
        userInfo._id
      );

      if (userIsInvited) {
        if (
          !checkIfNull(resolution?.responses) &&
          Boolean(resolution?.responses[userInfo._id])
        ) {
          userResponseStatus = "completed";
        } else {
          if (
            constantCase(resolution?.survey?.decisionStatus ?? "") === "CLOSED"
          ) {
            userResponseStatus = "noresponse";
          }
        }
      }

      surveyData.push({
        ...resolution,
        responses: resolution.responses,
        decisionId: resolution._id,
        userIsInvited: userIsInvited,
        userResponseStatus: userResponseStatus,
        isOwner: isOwner,
      });
    });
    let filteredDecisionsData =
      surveyData.filter((rowData) => checkCanView(rowData)) ?? [];
    setDecisionsData(filteredDecisionsData);
  }, [resolutions || [], selectedDecisionId]);

  const selectedDecision =
    decisionsData?.find((decision) => decision._id === selectedDecisionId) ??
    null;

  const resetActionsList = () => {
    setSelectedSurveysList([]);
    setSelectedSurveyAction("");
  };

  // const handleRefreshFilesData = () => {
  //   queryClient.invalidateQueries(
  //     [
  //       "allS3Files",
  //       {
  //         prefix:
  //           viewContext === "DOCKET"
  //             ? decodedS3Prefix
  //             : `${selectedOrganizationId}/${selectedSuiteId}/`,
  //       },
  //     ],
  //     { refetchInactive: true }
  //   );
  // };

  const fetchDownloadFileUrl = React.useCallback(
    async (file, protectFlag, protectText) => {
      let downloadUrlResponse = protectFlag
        ? await executeApi("PROTECT-FILE-S3", {
            bucket: selectedBucketId,
            key: file.id,
            calledBy: userInfo._id,
          })
        : await executeApi("FETCH-SIGNED-URL", {
            bucket: selectedBucketId,
            key: file.id,
            urlTypeRequested: "get",
            contentDisposition: "attachment",
            contentType: file.fileType, //FIXME: Raghu - can you check this.. content type is not there here..
          });

      return downloadUrlResponse;
    },
    [selectedBucketId, userInfo._id]
  );

  const handleDeleteSurvey = async (rowData) => {
    if (rowData) {
      confirm({
        description:
          "Do you want to delete this Survey? All data and responses to the survey will be deleted.",
      })
        .then(async () => {
          await handleResolutionActions(
            { action: "DELETE-SURVEY", rowData: rowData },
            null,
            null
          );
          toast.success("The Survey has been deleted");
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        })
        .finally(() => null);
    }
  };

  const handleSurveyStatusChange = async (newStatus, rowData) => {
    await handleResolutionActions(
      { action: "SURVEY-STATUS-CHANGE", newStatus, rowData },
      null,
      null
    );
    loadRoute("LIST-SURVEYS", {});
  };

  const surveysContextValues = {
    smallSizeFlag,
    viewContext,
    actionContext,
    isMobile,
    isCreator,
    isInvitee,
    fileViewerFlag,
    loadRoute,
    meetingId,
    xsSize,
    mdSize,
    selectedDocketStatus,
    selectedDocket,
    selectedSuite,
    selectedSuiteStatus,
    decisionsData,
    baseDockets,
    baseDocketsStatus,
    isDocket,
    isDocketOwner,
    handleResolutionActions,
    usersInSuite,
    usersInSuiteStatus,
    resolutionsInDocket,
    resolutionsInDocketStatus,
    resolutionsInSuite,
    resolutionsInSuiteStatus,
    surveysPermissions,
    selectedDecisionId,
    selectedDecision,
    handleDeleteSurvey,
    handleSurveyStatusChange,
  };

  return (
    <SurveysContext.Provider value={{ ...surveysContextValues }}>
      {children}
    </SurveysContext.Provider>
  );
};
export default SurveysProvider;
