// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const DocketAddedSuite = ({ notification, viewContext, index }) => {
  const { payload } = notification;
  let dummyPicture = `images/suitestockimages/suiteImage-5.jpg`;

  const data = {
    message: `Breakout meeting - ${
      payload?.addedDocketFullName
    } has been added to ${payload?.suiteFullName || "Suite"}`,
    avatar: getS3ImageWrapper(payload?.addedByImage || dummyPicture),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default DocketAddedSuite;
