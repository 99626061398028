import React, { useState, useEffect } from "react";
import {
  BackgroundBlurProvider,
  BackgroundReplacementProvider,
  MeetingProvider,
  useVideoInputs,
  useVoiceFocus,
  VoiceFocusProvider,
  useBackgroundBlur,
  useBackgroundReplacement,
  useLocalVideo,
  useMeetingManager,
  useLogger,
} from "amazon-chime-sdk-component-library-react";
import { isVideoTransformDevice } from "amazon-chime-sdk-js";
import { useHandleManageUserProfile, useAppContext } from "@app21/core";
import { VideoFiltersCpuUtilization } from "../configs/constants";
import NoMeetingRedirect from "./NoMeetingRedirect";
import MeetingEventObserver from "./MeetingEventObserver";
import { useChimeState } from "../wrappers/ChimeStateProvider";
import {
  useVideocallContext,
  VideoTransformOptions,
} from "providers/TrrystVideocallProvider";
import { LoadingSpinner } from "blocks/atoms";
import { FlowBox } from "blocks/atoms/uistyles";

const MeetingProviderWithVoiceFocusDeviceReplacement = ({ children }) => {
  const { addVoiceFocus, isVoiceFocusSupported } = useVoiceFocus();

  const onDeviceReplacement = (nextDevice, currentDevice) => {
    if (currentDevice) {
      return addVoiceFocus(nextDevice);
    }
    return Promise.resolve(nextDevice);
  };

  const meetingConfigValue = {
    onDeviceReplacement,
  };
  if (isVoiceFocusSupported) {
    return (
      <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>
    );
  }
  return <MeetingProvider>{children}</MeetingProvider>;
};

const BlurAndBackgroundReplacementWrapper = ({ children }) => {
  const { selectedDevice } = useVideoInputs();
  const [isLoading, setIsLoading] = useState(false);
  const { isVideoEnabled } = useLocalVideo();
  const meetingManager = useMeetingManager();
  const logger = useLogger();

  const { handleAction: handleManageUserProfile } =
    useHandleManageUserProfile();
  const { userInfo } = useAppContext();

  const {
    activeVideoTransformOption,
    changedUserPreferencesFlag,
    setChangedUserPreferencesFlag,
  } = useChimeState();
  const { createBackgroundBlurDevice } = useBackgroundBlur();
  const { loadReplacementImageBlob } = useChimeState();
  const { createBackgroundReplacementDevice } = useBackgroundReplacement();

  const selectTransform = async (option) => {
    let currentDevice = selectedDevice;
    if (isLoading || currentDevice === undefined) {
      return;
    }
    try {
      setIsLoading(true);
      if (option.includes("BLUR")) {
        if (!isVideoTransformDevice(currentDevice)) {
          // Enable video transform on the default device.
          currentDevice = await createBackgroundBlurDevice(currentDevice);
        } else {
          // Switch back to intrinsicDevice.
          const intrinsicDevice = await currentDevice.intrinsicDevice();
          // Stop existing VideoTransformDevice.
          await currentDevice.stop();
          currentDevice = intrinsicDevice;

          currentDevice = await createBackgroundBlurDevice(currentDevice);
        }
      } else if (option.includes("REPLACEMENT")) {
        await loadReplacementImageBlob(
          VideoTransformOptions[activeVideoTransformOption].value
        );
        if (!isVideoTransformDevice(currentDevice)) {
          // Enable video transform on the non-transformed device.
          currentDevice = await createBackgroundReplacementDevice(
            currentDevice
          );
        } else {
          // the existing device is already a transformed device
          // the earlier device was blur
          const intrinsicDevice = await currentDevice.intrinsicDevice();
          // Stop existing VideoTransformDevice.
          await currentDevice.stop();
          currentDevice = intrinsicDevice;
          // Switch to background replacement device if old selection was
          // background blur otherwise switch to default intrinsic device.
          currentDevice = await createBackgroundReplacementDevice(
            currentDevice
          );
        }
        // Update the current selected transform.
      } else {
        // if none is chosen or any other condition
        if (isVideoTransformDevice(currentDevice)) {
          const intrinsicDevice = await currentDevice.intrinsicDevice();
          // Stop existing VideoTransformDevice.
          await currentDevice.stop();
          currentDevice = intrinsicDevice;
        }
      }

      if (isVideoEnabled) {
        // Use the new created video device as input.
        await meetingManager.startVideoInputDevice(currentDevice);
      } else {
        // Select the new created video device but don't start it.
        meetingManager.selectVideoInputDevice(currentDevice);
      }
    } catch (e) {
      logger.error(`Error trying to change Background Option ${e}`);
    } finally {
      if (changedUserPreferencesFlag) {
        const updatedUserProfile = userInfo;

        updatedUserProfile.updatedKeys = {};

        if (updatedUserProfile?.settings) {
          updatedUserProfile.updatedKeys.settings = {
            ...updatedUserProfile.settings,
            chimeSettings: {
              ...(updatedUserProfile.settings.chimeSettings ?? {}),
              videoTransformOption: option,
            },
          };
        } else {
          updatedUserProfile.updatedKeys.settings = {
            ...updatedUserProfile.settings,
            chimeSettings: {
              videoTransformOption: option,
            },
          };
        }

        handleManageUserProfile({ updatedUserProfile }, null, null);
        setChangedUserPreferencesFlag(false);
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (selectedDevice && !isVideoTransformDevice(selectedDevice)) {
      selectTransform(activeVideoTransformOption);
    }
  }, [selectedDevice]);

  React.useEffect(() => {
    if (selectedDevice) {
      selectTransform(activeVideoTransformOption);
    }
  }, [activeVideoTransformOption]);

  return children;
};

const MeetingProviderWrapper = ({ children }) => {
  const {
    isVoiceFocusEnabled,
    videoTransformCpuUtilization,
    imageBlob,
    blurStrength,
  } = useChimeState();
  const { joinInfo, isBotJoining } = useVideocallContext();

  const isFilterEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  const getMeetingProviderWrapper = () => {
    return (
      <>
        <NoMeetingRedirect>{children}</NoMeetingRedirect>
        <MeetingEventObserver />
      </>
    );
  };
  function voiceFocusName(name) {
    if (name && ["default", "ns_es"].includes(name)) {
      return name;
    }
    return "default";
  }

  function getVoiceFocusSpecName() {
    if (
      joinInfo &&
      joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === "AVAILABLE"
    ) {
      return voiceFocusName("ns_es");
    }
    return voiceFocusName("default");
  }

  const vfConfigValue = {
    spec: { name: getVoiceFocusSpecName() },
    createMeetingResponse: joinInfo,
  };

  const getMeetingProviderWrapperWithVoiceFocus = (children) => {
    if (isBotJoining) return children;
    return (
      <VoiceFocusProvider {...vfConfigValue}>
        <MeetingProviderWithVoiceFocusDeviceReplacement>
          {children}
        </MeetingProviderWithVoiceFocusDeviceReplacement>
      </VoiceFocusProvider>
    );
  };

  const getWrapperWithVideoFilter = (children) => {
    let filterCPUUtilization = parseInt(videoTransformCpuUtilization, 10);
    if (!filterCPUUtilization) {
      filterCPUUtilization = 40;
    }
    if (isBotJoining) return children;

    return (
      <BackgroundBlurProvider options={{ filterCPUUtilization, blurStrength }}>
        <BackgroundReplacementProvider
          options={{ imageBlob, filterCPUUtilization }}
        >
          <BlurAndBackgroundReplacementWrapper>
            {children}
          </BlurAndBackgroundReplacementWrapper>
        </BackgroundReplacementProvider>
      </BackgroundBlurProvider>
    );
  };

  const getMeetingProvider = (children) => {
    return <MeetingProvider>{children}</MeetingProvider>;
  };

  const getMeetingProviderWithFeatures = () => {
    let children = getMeetingProviderWrapper();

    if (isFilterEnabled && !isBotJoining) {
      children = getWrapperWithVideoFilter(children);
    }
    if (!isBotJoining) {
      children = getMeetingProviderWrapperWithVoiceFocus(children);
    } else {
      children = getMeetingProvider(children);
    }
    return children;
  };

  return (
    <>
      {imageBlob === undefined ? (
        <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
          <LoadingSpinner />
        </FlowBox>
      ) : (
        getMeetingProviderWithFeatures()
      )}
    </>
  );
};

export default MeetingProviderWrapper;
