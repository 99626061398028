import { atom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";

export const mainDashboardSuiteSearchQueryAtom = atomWithStorage(
  "searchQuery",
  ""
);
export const mainDashboardSuiteSearchSortMethodAtom = atomWithStorage(
  "sortMethod",
  "date"
);
export const mainDashboardSuiteSearchFilterListAtom = atomWithStorage(
  "suiteFilterList",
  []
);
export const dataSuiteDashboardTabOptionsValueAtom = atomWithStorage(
  "tabOptions",
  "breakouts"
);
