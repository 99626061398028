import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { SignupButton } from "blocks/atoms";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";

export default function VisitorSuiteDashboardView() {
  const { loginWithRedirect } = useAuth0();
  const router = useRouter();
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 6,
            borderRadius: 2,
            backgroundColor: (theme) =>
              alpha(theme.palette.secondary.lighter, 0.5),
            color: "primary.main",
          }}
        >
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Welcome to Trryst
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center", mt: 3 }}>
            You are now authenticated in Visitor Mode with very limited access
          </Typography>
        </Box>
        <Typography variant="h5" color="primary" sx={{ my: 4 }}>
          Please sign up (for free!) to Trryst to gain full access to the
          platform functionality
        </Typography>

        <SignupButton />
        <Typography variant="h6">
          If you have already signed up, please
          <Button
            onClick={() => {
              typeof window !== "undefined" &&
                localStorage.setItem("savedRoute", router.asPath);
              loginWithRedirect({
                appState: {
                  targetUrl: `${process.env.ROOT_URL}/auth0_callback`,
                },
                screen_hint: "login",
              });
            }}
          >
            Login
          </Button>
        </Typography>
      </Box>
    </Container>
  );
}
