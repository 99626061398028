// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  StyledStandardDialog,
  StyledStandardDialogActions,
} from "blocks/atoms/uistyles";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { checkIfNull } from "@app21/core";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import { CloudDownload } from "@mui/icons-material";
import { getFileOrFoldernameFromS3Key } from "utils/fileHelperUtils";

export default function DownloadFilesDialog({
  onClose,
  open,
  protectFlag = false,
  viewContext,
  filesForDownload = [],
  ...rest
}) {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <StyledStandardDialog
      onClose={onClose}
      open={open}
      scroll="paper"
      {...rest}
    >
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            my: 3,
          }}
        >
          <CloudDownload color="secondary" sx={{ fontSize: "100px" }} />
        </Box>
        <DialogContentText>
          <Typography variant="h5">
            Your files are ready for download.
            {protectFlag &&
              `Files in this ${
                viewContext === "DOCKET" ? "Breakout" : "Suite"
              } are
            protected.`}
          </Typography>
        </DialogContentText>

        <List sx={{ mt: 2, alignSelf: "center" }}>
          {!checkIfNull(filesForDownload) &&
            filesForDownload.map((fileInfo, index) => {
              return (
                !checkIfNull(fileInfo) && (
                  <ListItem key={index}>
                    <ListItemText
                      primary={getFileOrFoldernameFromS3Key(fileInfo.file)}
                    />

                    <NextMuiLink href={fileInfo.url} download>
                      <Button variant="outlined" sx={{ ml: 3 }}>
                        Download
                      </Button>
                    </NextMuiLink>
                  </ListItem>
                )
              );
            })}
        </List>
      </DialogContent>
      <StyledStandardDialogActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t("global.buttons.close")}
        </Button>
      </StyledStandardDialogActions>
    </StyledStandardDialog>
  );
}
