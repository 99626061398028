import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Stack,
  Paper,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import {
  useAppContext,
  useOrgsAndSuitesForUser,
  useHandleRouterPush,
} from "@app21/core";
import ShowPersonalSuiteCard from "./ShowPersonalSuiteCard";

export default function SharedPersonalBreakoutsViewPanel({}) {
  const { userInfo } = useAppContext();
  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo?._id);

  const { loadRoute } = useHandleRouterPush();

  let sharedOrgsAndSuitesList = orgsAndSuites?.filter(
    (org) =>
      org.orgType === "PERSONAL" && !org.fullName?.includes(userInfo?._id)
  );

  const handleClick = (org) => (event) => {
    loadRoute("LIST-DOCKETS", {
      orgId: org?._id,
      suiteId: org?.suitesInOrg?.[0]?._id,
    });
  };

  if (orgsAndSuitesStatus !== "success") {
    return null;
  } else if (sharedOrgsAndSuitesList?.length === 0) {
    return null;
  }
  return (
    <Card sx={{ flexGrow: 1, height: "100%" }}>
      <CardHeader
        sx={{ pt: 1 }}
        disableTypography
        title={
          <Typography
            variant="overline"
            color="primary.darker"
            sx={{ lineHeight: 2 }}
          >
            Personal Suites shared with me
          </Typography>
        }
      />
      <CardContent>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          {sharedOrgsAndSuitesList?.map((org, key) => (
            <ShowPersonalSuiteCard
              key={key}
              org={org}
              onClick={handleClick(org)}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
