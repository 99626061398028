// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { LogLevel } from "amazon-chime-sdk-js";

export const AMAZON_CHIME_VOICE_CONNECTOR_PHONE_NUMDER = "+17035550122";

export const VideoFiltersCpuUtilization = {
  Disabled: "0",
  CPU10Percent: "10",
  CPU20Percent: "20",
  CPU40Percent: "40",
};
export const BlurStrengthOptions = {
  LOW: 7,
  MEDIUM: 15,
  HIGH: 30,
};
export const AvailableMeetingModes = {
  Spectator: "Spectator",
  Attendee: "Attendee",
};
// Video Transform Options
export const VideoTransformOptions = {
  NONE: { label: "None", value: "none" },
  BLURLOW: { label: "Normal Blur", value: BlurStrengthOptions.MEDIUM },
  BLURHIGH: { label: "High Blur", value: BlurStrengthOptions.HIGH },
  REPLACEMENT1: {
    label: "Background 1",
    value: "/static/backgrounds/office.jpg",
  },
  REPLACEMENT2: {
    label: "Background 2",
    value: "/static/backgrounds/office3.jpg",
  },
  REPLACEMENT3: {
    label: "Background 3",
    value: "/static/backgrounds/office5.jpg",
  },
  REPLACEMENT4: {
    label: "Background 4",
    value: "/static/backgrounds/office6.jpg",
  },
  REPLACEMENT5: {
    label: "Background 5",
    value: "/static/backgrounds/office7.jpg",
  },
  REPLACEMENT6: {
    label: "Background 6",
    value: "/static/backgrounds/office8.jpg",
  },
  REPLACEMENT7: {
    label: "Background 7",
    value: "/static/backgrounds/office9.jpg",
  },
  REPLACEMENT8: {
    label: "Background 8",
    value: "/static/backgrounds/office11.jpg",
  },
  REPLACEMENT9: {
    label: "Background 9",
    value: "/static/backgrounds/office12.jpg",
  },
  REPLACEMENT10: {
    label: "Background 10",
    value: "/static/backgrounds/Back-min.jpg",
  },
  REPLACEMENT11: {
    label: "Background 10",
    value: "/static/backgrounds/Paper1-min.jpg",
  },
  REPLACEMENT12: {
    label: "Background 10",
    value: "/static/backgrounds/Wood-min.jpg",
  },
};

export const VIDEO_INPUT = {
  NONE: "None",
  BLUE: "Blue",
  SMPTE: "SMPTE Color Bars",
};

export const AUDIO_INPUT = {
  NONE: "None",
  440: "440 Hz",
};

export const MAX_REMOTE_VIDEOS = 16;

export const AVAILABLE_AWS_REGIONS = {
  "us-east-1": "United States (N. Virginia)",
  "af-south-1": "Africa (Cape Town)",
  "ap-northeast-1": "Japan (Tokyo)",
  "ap-northeast-2": "Korea (Seoul)",
  "ap-south-1": "India (Mumbai)",
  "ap-southeast-1": "Singapore",
  "ap-southeast-2": "Australia (Sydney)",
  "ca-central-1": "Canada",
  "eu-central-1": "Germany (Frankfurt)",
  "eu-north-1": "Sweden (Stockholm)",
  "eu-south-1": "Italy (Milan)",
  "eu-west-1": "Ireland",
  "eu-west-2": "United Kingdom (London)",
  "eu-west-3": "France (Paris)",
  "sa-east-1": "Brazil (São Paulo)",
  "us-east-2": "United States (Ohio)",
  "us-west-1": "United States (N. California)",
  "us-west-2": "United States (Oregon)",
};

export const VIDEO_INPUT_QUALITY = {
  "360p": "360p (nHD) @ 15 fps (600 Kbps max)",
  "540p": "540p (qHD) @ 15 fps (1.4 Mbps max)",
  "720p": "720p (HD) @ 15 fps (1.4 Mbps max)",
};

export const SDK_LOG_LEVELS = {
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  off: LogLevel.OFF,
};

export const DATA_MESSAGE_LIFETIME_MS = 1000; // 1 second is sufficient
export const DATA_MESSAGE_TOPICS = {
  admin: "ADMIN-MESSAGES",
  attendee: "ATTENDEE-MESSAGES",
  group: "GROUP-MESSAGES",
};
