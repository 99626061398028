// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import Router, { useRouter } from "next/router";
import React from "react";
import { useAppContext, checkIfNull } from "@app21/core";
const DONE_DURATION = 10;
import _ from "lodash";
import { isSafari } from "react-device-detect";

const ProcessRouteSwitch = () => {
  const [loading, setLoading] = React.useState(null);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const { updateRouteHistory, updateAllIds } = useAppContext();

  const router = useRouter();
  const onLoad = () => {
    setLoading(true);
  };
  const onDone = () => {
    setLoading(false);
    setTimeoutId(
      setTimeout(() => {
        setTimeoutId(null);
        setLoading(null);
      }, DONE_DURATION)
    );
  };

  React.useEffect(() => {
    // if the route has these set of query params (as sent by the successUrl)
    // just let it pass.
    // the AppProvider will catch the keys it already does - choice, orgId, suiteId
    // and the other will be ignored.
    // Any component that handles this route can pick up the other parameters to
    // handle the flow as needed.
    if (
      _.isEqual(Object.keys(router?.query), [
        "activeStep",
        "choice",
        "isSetupSuccessful",
        "orgId",
        "suiteId",
      ])
    )
      return;

    // add routes that dont have any query by default. I think it is only the /user route
    if (router.pathname.endsWith("/user")) {
      updateRouteHistory(router.pathname, router.query);
      updateAllIds(router.query, router.asPath);
      return;
    }

    if (router.pathname.startsWith("/meeting")) {
      updateAllIds(router.query, router.asPath);
      return;
    }

    if (
      !checkIfNull(router.query) &&
      !_.isEqual(Object.keys(router.query), ["action"])
    ) {
      updateRouteHistory(router.pathname, router.query);
      const { action, ...otherQueryParams } = router.query;

      if (
        _.isEqual(Object.keys(otherQueryParams), ["viewFile"]) ||
        _.isEqual(Object.keys(otherQueryParams), ["meetingId"]) ||
        _.isEqual(Object.keys(otherQueryParams), ["viewFile", "meetingId"]) ||
        _.isEqual(Object.keys(otherQueryParams), ["meetingId", "viewFile"])
      ) {
        // file is being opened from a clean url page - if that is the case, viewFile needs to be
        // added to the list of currIds and not replace it.
        const currIds = JSON.parse(localStorage.getItem("lastIds"));
        updateAllIds({ ...currIds, ...otherQueryParams }, router.asPath);
      } else {
        updateAllIds(otherQueryParams, router.asPath);
      }

      if (
        !isSafari &&
        typeof window !== "undefined" &&
        window.location.href !== router.pathname
      ) {
        if (Object.prototype.hasOwnProperty.call(router.query, "action")) {
          const url = {
            pathname: router.pathname,
            query: { action },
          };
          router.replace(url, router.pathname, { shallow: true });
        } else {
          router.replace(router.pathname, router.pathname, { shallow: true });
        }
      }
    }
  }, [router]);

  React.useEffect(() => {
    Router.events.on("routeChangeStart", onLoad);
    Router.events.on("routeChangeComplete", onDone);
    Router.events.on("routeChangeError", onDone);

    return () => {
      Router.events.off("routeChangeStart", onLoad);
      Router.events.off("routeChangeComplete", onDone);
      Router.events.off("routeChangeError", onDone);
    };
  });

  React.useEffect(
    () => () => {
      if (timeoutId) clearTimeout(timeoutId);
    },
    [timeoutId]
  );

  return (
    <div className={loading === null ? "" : loading ? "loading" : "done"}>
      <style jsx>{`
        div {
          position: fixed;
          left: 0;
          top: 0;
          right: 100%;
          z-index: 2;
          height: 2px;
          background-color: hsla(207, 98%, 40%, 0.95);
          box-shadow: 0 1px 8px hsla(0, 0%, 0%, 0.1);
          opacity: 0;
          transition-property: right, opacity;
          transition-duration: 0s;
          pointer-events: none;
        }
        .loading {
          right: 5%;
          opacity: 1;
          transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          transition-duration: 8s, 0s;
        }
        .done {
          right: 10;
          transition-duration: ${DONE_DURATION}ms;
          transition-delay: 0s, ${DONE_DURATION}ms;
        }
      `}</style>
    </div>
  );
};

export default ProcessRouteSwitch;
