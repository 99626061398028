export const DataMessagesActionType = {
  ADD: "ADD",
};
export const initialState = {
  messages: [],
};

export function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case DataMessagesActionType.ADD:
      return { messages: [...state.messages, payload] };
    default:
      throw new Error("Incorrect action in DataMessagesProvider reducer");
  }
}
