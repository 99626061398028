// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreRoundedIcon,
      },

      styleOverrides: {
        root: {
          '&:hover': {
            outline: `${theme.spacing(0.25)} solid ${theme.palette.primary.light}`,
          },
        },
      },
    },
  };
}
