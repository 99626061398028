// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const UserRoleUpdatedOrganization = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  const data = {
    message: `${
      payload?.updatedUserFullName || "User"
    }'s role in organization ${payload?.organizationFullName} is changed to ${
      payload?.role
    }`,
    avatar: getS3ImageWrapper(payload?.updatedUserImage, "user"),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default UserRoleUpdatedOrganization;
