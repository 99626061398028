// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadiusButton,

          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,

          "&:hover.MuiButton-containedSizeLarge": {
            outline: `1 solid ${theme.palette.primary.main}`,
            color: theme.palette.secondary.main,
          },
          "&:hover.MuiButton-containedSizeMedium": {
            color: theme.palette.secondary.main,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          boxShadow: `5px 3px 2px ${theme.palette.primary.main}`,
          "&:hover.MuiButton-containedSizeMedium": {
            outline: `${theme.spacing(0.5)} solid ${
              theme.palette.primary.main
            }`,
          },
          "&:hover.MuiButton-containedSizeSmall": {
            outline: `${theme.spacing(0.5)} solid ${
              theme.palette.primary.main
            }`,
          },
          "&:hover.MuiButton-containedSizeLarge": {
            outlineWidth: theme.spacing(1),
            outlineColor: theme.palette.primary.main,
            outlineStyle: "solid",
          },
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        // <<<<<<< HEAD
        //         outlined: {
        //           outline: `1px solid ${theme.palette.primary.main}`,
        //           '&:hover': {
        // =======
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          "&:hover": {
            // >>>>>>> v4dev
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: "none",
            color: theme.palette.primary.light,
            boxShadow: "none",
          },
        },
      },
    },
  };
}
