import { useQuery, useMutation } from "react-query";
import { QueryOptions, MutationOptions, useApolloClient } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";

export const useGraphqlQuery = (options: QueryOptions) => {
  const client = useApolloClient();
  if (!client)
    throw new Error(
      "Make sure you are using useGraphqlQuery wrapped in ApolloProvider"
    );

  const operation = getOperationName(options.query);

  const result = useQuery([operation, options.variables], () =>
    client.query(options)
  );
  return result;
};

export const useGraphqlMutation = (options: MutationOptions) => {
  const client = useApolloClient();
  if (!client)
    throw new Error(
      "Make sure you are using useGraphqlQuery wrapped in ApolloProvider"
    );

  const result = useMutation(() => client.mutate(options));
  return result;
};
