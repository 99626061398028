// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { reportErrorToAsana, useUserInfo } from "@app21/core";
import {
  Paper,
  Grid,
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import toast from "react-hot-toast";

import { useTheme } from "@mui/material/styles";
import Page from "blocks/views/Layouts/Page";
import Image from "next/image";
import { useRouter } from "next/router";
import { Layouts } from "blocks/views/Layouts";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const theme = useTheme();
  const { data: user } = useUserInfo();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const router = useRouter();

  const handleReportErrorToAsana = React.useCallback(async () => {
    await reportErrorToAsana({
      assigneeId: "1199608836986978", // Madhukar Sir's Asana Account,
      projects: ["1202616463336205"], // Production Bugs FE
      workspace: "1199604503295381",
      taskName: `[${process.env.NODE_ENV}] Error ${error.message}`,
      taskNote: `Error Occured\n\nError Message: ${
        error.message
      }\n\nlast Id's in Local Storage: ${JSON.stringify(
        localStorage.getItem("lastIds")
      )}\n\nCurrent User: ${user?._id} - ${user?.emailId}
      `,
    });
    toast.error(
      `Something went wrong. We have been notified and will look into the issue.`
    );
    if (resetErrorBoundary && typeof resetErrorBoundary === "function")
      resetErrorBoundary();
  }, [error, resetErrorBoundary, user?._id, user?.emailId]);

  return (
    <Layouts
      viewContext="generic"
      showPathCrumbs={false}
      pageTitle={"Error. Sorry!"}
    >
      <Page
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          maxHeight: "60vh",
          display: "flex",
          alignItems: "center",
          padding: (theme) => theme.spacing(3),
        }}
        title="404: Not found"
        role="alert"
      >
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid
              item
              xs={2}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image
                  src="/static/images/undraw_empty_xct9.png"
                  alt="Under development"
                  width={400}
                  height={400}
                  priority
                  style={{
                    maxWidth: "100%",

                    borderRadius: 10,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={8}>
              <Paper
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant={mobileDevice ? "h6" : "h5"}
                  color="textPrimary"
                >
                  Sorry ! Something did not work as intended.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ marginTop: 10 }}
                >
                  In case you wish to let us know (support @ trryst.com), the
                  error message is: <br />
                  <Paper
                    elevation={2}
                    style={{ wordWrap: "break-word", margin: 10, padding: 5 }}
                  >
                    {error.message}
                  </Paper>
                </Typography>

                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      router.reload();
                    }}
                    variant="outlined"
                  >
                    Try Again
                  </Button>
                  <Button
                    color="secondary"
                    onClick={resetErrorBoundary}
                    variant="contained"
                  >
                    Reset
                  </Button>
                </Stack>
                <Button
                  onClick={handleReportErrorToAsana}
                  variant="outlined"
                  sx={{ my: 4 }}
                >
                  Create Ticket
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </Layouts>
  );
};

export default ErrorFallback;
