import {
  executeApi,
  useAppContext,
  useHandleMeetingActions,
} from "@app21/core";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useHandleLeaveVideocall } from "./useHandleLeaveVideocall";

export const useHandleVideocallActions = () => {
  const { userInfo, meetingId } = useAppContext();
  const { meeting, setChimeRoute, chimeRoute, isRecordingBotEnabled } =
    useVideocallContext();
  const meetingManager = useMeetingManager();
  const { leaveVideocall } = useHandleLeaveVideocall();
  const { handleMeetingActions } = useHandleMeetingActions();

  const handleMeetingJoinAction = async ({ action }) => {
    let isVisitorPresent = false;
    const updatedMeetingJSON = meeting?.data?.meetingJSON;
    switch (action) {
      case "REQUEST-ACCESS":
        // need to enhance the meetingJSON.visitors object here...
        // first check if visitor who is requesting access is part of the list of visitors already or not.
        updatedMeetingJSON?.visitors?.forEach((v, index) => {
          if (v.emailId === userInfo.emailId) {
            // will need the check for otheremail ids also here.
            // invited user used the same emailId on which he was invited earlier.
            // enhance that with the _id of his signed up account now.
            isVisitorPresent = true;
            updatedMeetingJSON.visitors[index] = {
              ...v,
              _id: userInfo._id, // if _id from the past guest_xxx is there it will be overwritten
              status: "REQUESTED",
            };
          }
        });
        if (!isVisitorPresent) {
          // 1. Looks like user joined through a link and we need to create an entry for his in visitors.
          // 2. Possibly the user signed up to Trryst with an email id different from which he got the invite
          const visitorObject = {
            _id: userInfo._id,
            fullName: userInfo.fullName,
            emailId: userInfo.emailId,
            status: "REQUESTED",
          };
          updatedMeetingJSON?.visitors.push(visitorObject);
        }
        await handleMeetingActions({
          action: "UPDATE-MEETING",
          meetingData: { meetingJSON: updatedMeetingJSON },
          meetingId: meetingId,
        });
        break;
      case "enter":
        if (isRecordingBotEnabled) {
          await executeApi("PREPARE-CHIME-RECORDING", {
            clientTokenId: meetingId,
            calledBy: userInfo?._id,
            chimeMeetingId: meetingManager.meetingId,
          });
        }
        await meetingManager.start();
        setChimeRoute("meeting");
        break;
      case "cancel":
        if (chimeRoute === "settings") {
          setChimeRoute("meeting");
        } else {
          await leaveVideocall({ meetingManager });
        }
        break;
      default:
    }
  };

  return {
    handleMeetingJoinAction,
  };
};
