import React, { useContext, useState, useEffect, useRef } from "react";
import { useAppContext } from "@app21/core";
import toast from "react-hot-toast";

const APIErrorHandlerContext = React.createContext({});

export const useAPIErrorHandlerContext = () => {
  const appContext = useAppContext();
  const context = useContext(APIErrorHandlerContext);
  if (!appContext || !context) {
    throw new Error(
      "useAPIErrorHandlerContext must be used within APIErrorHandlerProvider and within AppProvider"
    );
  }
  return context;
};

const showToastDecisionMessage = (error) => {
  switch (error.errorReason) {
    case "SUITE-ID-NOT-FOUND":
      return "Invalid Parameters provided. Possibly your link has expired. Please login and try again.";
    default:
      return "Something went wrong. Please try again or contact support if the problem persists.";
  }
};

const APIErrorHandlerProvider = ({ children }) => {
  const { sessionErrorLogs } = useAppContext();
  const prevErrorMessages = useRef(sessionErrorLogs);

  const sessionErrorLogHash = Array.isArray(sessionErrorLogs)
    ? sessionErrorLogs.reduce((a, b) => a + b.errorReason, "")
    : null;

  useEffect(() => {
    if (sessionErrorLogHash !== "" && Array.isArray(sessionErrorLogs)) {
      // There is some new error
      const newError = sessionErrorLogs.at(-1);
      let errorMessage = showToastDecisionMessage(newError);
      if (errorMessage) toast.error(errorMessage, { autoClose: 5000 });
    }
    // const newErrorMessages = sessionErrorLogs.filter(
    //   (message) => !prevErrorMessages.current.includes(message)
    // );

    // Boolean(newErrorMessages.length) &&
    //   newErrorMessages.slice(-1).forEach((error) => {
    //     let errorMessage = showToastDecisionMessage(error);
    //     if (errorMessage) toast.error(errorMessage, { autoClose: 5000 });
    //   });
    // prevErrorMessages.current = [...sessionErrorLogs];
  }, [sessionErrorLogHash, sessionErrorLogs]);

  const apiErrorHandlerValues = {};

  return (
    <APIErrorHandlerContext.Provider value={{ ...apiErrorHandlerValues }}>
      {children}
    </APIErrorHandlerContext.Provider>
  );
};
export default APIErrorHandlerProvider;
