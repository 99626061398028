import React, { useContext, useState, useEffect, useRef } from "react";
import {
  useAppContext,
  useAnalytics,
  useUsersInSuite,
  useSuite,
  useMeetingsInSuite,
  useResolutionsInSuite,
  useUserInfo,
  checkIfNull,
} from "@app21/core";
import toast from "react-hot-toast";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";
import { useIsMobileBreakpoint } from "hooks";
import { useIntl } from "react-intl";
import { differenceInMinutes } from "date-fns";
import { constantCase } from "change-case";
import { LoadingSpinner } from "blocks/atoms";
const DashboardViewsDataContext = React.createContext({});

export const useDashboardViewsDataContext = () => {
  const appContext = useAppContext();
  const context = useContext(DashboardViewsDataContext);
  if (!appContext || !context) {
    throw new Error(
      "useDashboardViewsDataContext must be used within DashboardViewsDataProvider and within AppProvider"
    );
  }
  return context;
};

const DashboardViewsDataProvider = ({ children }) => {
  const isMobile = useIsMobileBreakpoint();
  const mobileMinWidth = useMediaQuery("(min-width:1100px)");
  const { selectedSuiteId, suiteRole, viewFile, userInfo } = useAppContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId) ?? {};

  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId) ?? {};

  const [suiteViews, setSuiteViews] = React.useState(0);
  const [docketEngagements, setDocketEngagements] = React.useState(0);
  const [fileViews, setFileViews] = React.useState(0);
  const [totalChimeMinutes, setTotalChimeMinutes] = React.useState(0);
  const [
    additionalDataSuiteDashboardContextValues,
    setAdditionalDataSuiteDashboardContextValues,
  ] = React.useState({});

  const isSuiteManager = suiteRole === "MANAGER";
  const [viewAnalyticsOption, setViewAnalyticsOption] = React.useState(
    isSuiteManager ? "All" : "Self"
  );
  const handleChipToggle = () =>
    setViewAnalyticsOption((prev) => (prev === "Self" ? "All" : "Self"));
  const {
    data: allAnalytics,
    status: allAnalyticsStatus,
    apiError: allAnalyticsApiError,
  } = useAnalytics({
    scope: { scope: "SUITE", suiteId: selectedSuiteId },
  });

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  React.useEffect(() => {
    if (allAnalyticsStatus === "success") {
      setSuiteViews(allAnalytics?.SUITE?.totals?.totalSuiteViews);
      setDocketEngagements(
        allAnalytics?.SUITE?.totals?.totalDocketViewsInSuites
      );
      setFileViews(allAnalytics?.SUITE?.totals?.totalFileViews);
      setTotalChimeMinutes(
        allAnalytics?.SUITE?.totals?.totalChimeMeetingDurationMs / 1000 / 60
      );
    }
  }, [allAnalytics, allAnalyticsStatus, viewAnalyticsOption, selectedSuiteId]);

  // start Needed Items block - removed hooks for this and inserted code here.
  const [meetingsToRSVP, setMeetingsToRSVP] = React.useState(null);
  const [upcomingMeetings, setUpcomingMeetings] = React.useState(null);
  const [openSurveysAndResolutions, setOpenSurveysAndResolutions] =
    React.useState(null);
  const {
    data: meetings,
    status: meetingsInSuiteStatus,
    apiError: meetingsApiError,
  } = useMeetingsInSuite({
    suiteId: selectedSuiteId,
    groupMeetings: "exclude",
  });
  const {
    data: surveysAndResolutions,
    status: surveysAndResolutionsStatus,
    apiError: surveysAndResolutionsApiError,
  } = useResolutionsInSuite(selectedSuiteId, false);
  const [probabilisticSnippetsPanelFlag] = React.useState(Math.random() < 0.3); //30% prob.. change say to 0.1 in case 10% is needed

  const dataFetchError =
    meetingsApiError ||
    surveysAndResolutionsApiError ||
    allAnalyticsApiError ||
    usersInSuiteApiError ||
    selectedSuiteApiError;

  const dataFetchLoading =
    meetingsInSuiteStatus === "loading" ||
    surveysAndResolutionsStatus === "loading" ||
    allAnalyticsStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    selectedSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const isPersonalSuite =
    selectedSuite?.suiteType === "PERSONAL-SUITE-BASIC" ||
    selectedSuite?.suiteTier === "PERSONAL-SUITE-FREE";

  React.useEffect(() => {
    let meetingList = [];
    const localMeetings = _.cloneDeep(meetings);
    if (meetings) {
      localMeetings?.forEach((m) => {
        if (
          m?.meetingType === "CLOSED-MEETING" &&
          differenceInMinutes(new Date(m?.meetingJSON?.start), new Date()) > 15
        ) {
          m?.meetingJSON?.members?.forEach((member) => {
            if (
              member?._id === userInfo?._id &&
              member?.rsvpStatus?.toLowerCase() === "invited"
            ) {
              meetingList.push(m);
            }
          });
        }
      });
    }
    setMeetingsToRSVP(meetingList);
    meetingList = [];
    if (meetings) {
      localMeetings?.forEach((m) => {
        if (
          m?.meetingType === "CLOSED-MEETING" &&
          differenceInMinutes(new Date(m?.meetingJSON?.start), new Date()) <=
            15 &&
          differenceInMinutes(new Date(m?.meetingJSON?.end), new Date()) > 0
        )
          m?.meetingJSON?.members?.forEach((member) => {
            if (
              member?._id === userInfo?._id &&
              member?.rsvpStatus !== "Declined"
            )
              meetingList.push(m);
          });
      });
    }
    setUpcomingMeetings(meetingList);
  }, [meetings, userInfo?._id]);

  React.useEffect(() => {
    let surveysAndResolutionsList = [];
    if (surveysAndResolutions) {
      surveysAndResolutions?.forEach((s) => {
        if (
          Boolean(
            s.survey?.decisionInvitees?.find((id) => id === userInfo?._id)
          ) &&
          !s?.responses?.[userInfo?._id] &&
          s.survey.decisionStatus === "OPEN"
        ) {
          surveysAndResolutionsList.push(s);
        }
      });
    }
    setOpenSurveysAndResolutions(surveysAndResolutionsList);
  }, [surveysAndResolutions, userInfo?._id]);
  // end needed items block

  const needingAttentionItemsFlag =
    Boolean(
      meetingsToRSVP?.length +
        upcomingMeetings?.length +
        openSurveysAndResolutions?.length
    ) ?? false;

  const basicDashboardViewsDataValues = {
    selectedSuite,
    dataFetchLoading,
    mobileMinWidth,
    isMobile,
    probabilisticSnippetsPanelFlag,
    needingAttentionItemsFlag,
    isPersonalSuite,
    allAnalytics,
    meetings,
    selectedSuite,
    surveysAndResolutions,
    usersInSuite,
    userInfo,
    suiteViews,
    docketEngagements,
    fileViews,
    totalChimeMinutes,
    viewAnalyticsOption,
    handleChipToggle,
    upcomingMeetings,
    meetingsToRSVP,
    openSurveysAndResolutions,
    isSuiteManager,
  };

  const dataSuiteSpecificContextValues = {
    ...additionalDataSuiteDashboardContextValues,
    setAdditionalDataSuiteDashboardContextValues,
  };

  return (
    <DashboardViewsDataContext.Provider
      value={{
        ...basicDashboardViewsDataValues,
        ...dataSuiteSpecificContextValues,
      }}
    >
      {children}
    </DashboardViewsDataContext.Provider>
  );
};
export default DashboardViewsDataProvider;
