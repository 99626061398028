import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useAtom } from "jotai";
import {
  showRecordingsSidebarFlagAtom,
  selectedRecordingsListAtom,
  selectedRecordingsActionAtom,
  viewRecordingsContextAtom,
  viewRecordingsFilterContextAtom,
  selectedBreakoutsInRecordingsFiltersAtom,
} from "blocks/modules/MeetingRecords/recordings-atoms";
import { useQueryClient } from "react-query";
import {
  useUsersInSuite,
  useAppContext,
  useSuite,
  useDockets,
  executeApi,
  useSelectedBucketId,
  useHandleRouterPush,
  checkIfNull,
  useRecordings,
  getRolePrivileges,
  useHandleTaskActions,
  useDocketsInSuite,
  useMeetingsInSuite,
  useTranscript,
} from "@app21/core";

import { useMediaQuery, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";

const RecordingsContext = React.createContext({});

export const useRecordingsContext = () => {
  const state = useContext(RecordingsContext);
  if (!state) {
    throw new Error(
      "useRecordingsContext must be used within RecordingsProvider"
    );
  }
  return state;
};
const RecordingsProvider = ({
  viewContext = "suite",
  actionContext = "list",
  isMobile = false,
  children,
}) => {
  const {
    meetingId,
    checkAccessRules = {},
    userInfo = {},
    chimeViewMode,
    viewFile,
    selectedSuiteId,
    selectedDocketId,
    taskId: selectedTaskId,
  } = useAppContext();

  const confirm = useConfirm();
  const theme = useTheme();
  const [selectedMeetingId, setSelectedMeetingId] = React.useState(null);
  const [searchContent, setSearchContent] = React.useState(null);

  const [viewRecordingsFilterContext, setViewRecordingsFilterContext] = useAtom(
    viewRecordingsFilterContextAtom
  );
  const [
    selectedBreakoutsInRecordingsFilters,
    setSelectedBreakoutsInTaskFilters,
  ] = useAtom(selectedBreakoutsInRecordingsFiltersAtom);

  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const {
    data: baseDockets,
    status: baseDocketsStatus,
    apiError: baseDocketsApiError,
  } = useDocketsInSuite(selectedSuiteId);
  const selectedBucketId = useSelectedBucketId(null, true);

  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);

  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(selectedSuiteId);
  const { data: meetings, status: useMeetingsInSuiteStatus } =
    useMeetingsInSuite({
      suiteId: selectedSuiteId,
      groupMeetings: "with-closed-meetings",
      searchString: searchContent,
      searchContext: "search-transcriptions",
    });

  let filteredMeetings =
    meetings
      ?.filter(
        (meeting) =>
          meeting.isTranscriptionPresent || meeting.isRecordingPresent
      )
      .reverse() ?? [];

  const { data: transcriptions, status: transcriptionsStatus } = useTranscript({
    meetingId: selectedMeetingId,
    searchString: searchContent,
  });

  const [selectedTask, setSelectedTask] = React.useState(null);
  const { handleTaskActions } = useHandleTaskActions();
  const isDocket = viewContext === "docket" || Boolean(selectedDocketId);
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;

  const [selectedTaskUniqueMembers, setSelectedTaskUniqueMembers] =
    React.useState(null);

  const contextId = isDocket ? selectedDocketId : selectedSuiteId;

  React.useEffect(() => {
    if (selectedDocketId) {
      setViewRecordingsFilterContext(["breakoutsFilter"]);
      setSelectedBreakoutsInTaskFilters([selectedDocket]);
    }
  }, [selectedDocketStatus]);

  const { loadRoute } = useHandleRouterPush();

  const fileViewerFlag = !checkIfNull(viewFile);

  const [selectedRecordingsList, setSelectedRecordingsList] = useAtom(
    selectedRecordingsListAtom
  );
  const [selectedRecordingsAction, setSelectedRecordingsAction] = useAtom(
    selectedRecordingsActionAtom
  );
  const [showRecordingsSidebarFlag, setShowRecordingsSidebarFlag] = useAtom(
    showRecordingsSidebarFlagAtom
  );
  const [viewRecordingsContext, setViewTaskContext] = useAtom(
    viewRecordingsContextAtom
  );

  const isCreator =
    viewContext === "DOCKET"
      ? selectedDocket?.createdBy === userInfo._id
      : selectedSuite?.createdBy === userInfo._id;
  const isInvitee =
    viewContext === "DOCKET"
      ? (selectedDocket?.members ?? []).includes(userInfo?._id)
      : (usersInSuite ?? []).includes(userInfo?._id);

  const checkCanDeleteRecording = (rowData) =>
    checkAccessRules({
      entity: "MEETINGS",
      action: "DELETE-RECORDING",
      featureName: "VIDEOCALL-RECORDING",
      isInvitee: true,
    })?.accessFlag;
  const checkCanDeleteTranscription = (rowData) =>
    checkAccessRules({
      entity: "MEETINGS",
      action: "DELETE-TRANSCRIPTION",
      featureName: "VIDEOCALL-RECORDING",
      isInvitee: true,
    })?.accessFlag;

  const recordingsPermissions = {
    checkCanDeleteRecording,
    checkCanDeleteTranscription,
    default: true,
  };
  const fetchDownloadFileUrl = React.useCallback(
    async (file, protectFlag, protectText) => {
      let downloadUrlResponse = protectFlag
        ? await executeApi("PROTECT-FILE-S3", {
            bucket: selectedBucketId,
            key: file.id,
            calledBy: userInfo._id,
          })
        : await executeApi("FETCH-SIGNED-URL", {
            bucket: selectedBucketId,
            key: file.id,
            urlTypeRequested: "get",
            contentDisposition: "attachment",
            contentType: file.fileType, //FIXME: Raghu - can you check this.. content type is not there here..
          });

      return downloadUrlResponse;
    },
    [selectedBucketId, userInfo._id]
  );
  React.useEffect(() => {
    if (selectedDocketId) {
      setViewTaskContext("showdockets");
    }
  }, [selectedDocketId]);

  const handleSelectMeeting = (meeting) => {
    setSelectedMeetingId(meeting._id);
    setShowRecordingsSidebarFlag(false);
  };

  const handleCloseTranscript = () => {
    setSelectedMeetingId(null);
  };

  const handleDeleteRecording = async (rowData) => {
    if (rowData) {
      confirm({
        description:
          "Do you want to delete the Recording? The action cannot be undone.",
      })
        .then(async () => {
          console.log("need to put in a recording action handler here");

          toast.success("Recording has been deleted");
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    }
  };

  const handleDeleteTranscript = async (rowData) => {
    if (rowData) {
      confirm({
        description:
          "Do you want to delete the Meeting Transcript? The action cannot be undone.",
      })
        .then(async () => {
          console.log("need to put in a recording action handler here");

          toast.success("Transcript has been deleted");
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    }
  };

  const recordingsContextValues = {
    filteredMeetings,
    selectedMeetingId,
    transcriptionsStatus,
    transcriptions,
    searchContent,
    handleCloseTranscript,
    useMeetingsInSuiteStatus,
    setSearchContent,
    handleSelectMeeting,
    recordingsPermissions,
    handleDeleteRecording,
    handleDeleteTranscript,
    viewContext,
    actionContext,
    isMobile,
    isCreator,
    isInvitee,
    fileViewerFlag,
    loadRoute,
    meetingId,
    baseDockets,
    baseDocketsStatus,
    selectedDocketStatus,
    selectedDocket,
    selectedSuite,
    selectedSuiteStatus,
    isDocket,
    isDocketOwner,
    usersInSuite,
    usersInSuiteStatus,
  };

  return (
    <RecordingsContext.Provider value={{ ...recordingsContextValues }}>
      {children}
    </RecordingsContext.Provider>
  );
};
export default RecordingsProvider;
