// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import { Box, Skeleton, Grid, Card, CardContent, Stack } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",

    flexGrow: 1,
    minHeight: "55vh",
    backgroundColor: "#ffffff",

    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",
      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      bgcolor: (theme) => alpha(theme.palette.grey[600], 0.72),
    },
  };
});

export default function PersonalSuiteDashboardViewLoading() {
  return (
    <Box
      sx={{
        pt: 1,
        pl: 1,
        pb: 2,
        weight: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <RootStyle ownerState={{ backgroundPicture: " " }}>
            <CardContent
              sx={{
                width: "100%",
              }}
            >
              <Skeleton
                variant="rectangular"
                height={100}
                width={100}
                sx={{ backgroundColor: "#ffffff" }}
              />
              <Skeleton
                variant="text"
                height={100}
                sx={{ backgroundColor: "#ffffff", pb: { xs: 3, xl: 5 } }}
              />
              <Stack direction={{ xs: "row", md: "column" }}>
                <Skeleton
                  variant="rectangular"
                  width={250}
                  height={60}
                  sx={{
                    backgroundColor: "#fafafa",
                    borderRadius: `10px 10px 10px 10px`,
                    mt: 2,
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  width={250}
                  height={60}
                  sx={{
                    backgroundColor: "#fafafa",
                    borderRadius: `10px 10px 10px 10px`,
                    mt: 2,
                  }}
                />
              </Stack>
            </CardContent>
          </RootStyle>
        </Grid>
        <Grid item container spacing={2} xs={12} sm={6} md={7}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Card elevation={0} sx={{ flexGrow: 1, height: 150 }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={150}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Card elevation={0} sx={{ flexGrow: 1 }}>
              <Skeleton
                variant="rectangular"
                height={250}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
