// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import { format, parseISO } from "date-fns";

export const msToTime = (s, secsFlag = false) => {
  if (s <= 0 || !s) return `0s`;
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return `${hrs || ""}${hrs > 0 ? "h " : ""}${mins || ""}${
    mins > 0 ? "m " : ""
  }${(secsFlag && secs) || ""}${secsFlag && secs > 0 ? "s" : ""}`;

  // return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`;
};

export const parseStringDate = (dateString, dateFormat) => {
  if (parseISO(dateString)) {
    let validDate = parseISO(dateString);
    return format(validDate, dateFormat);
  } else {
    return dateString;
  }
};

// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export function checkTimeWithinRange(currentTime, startTime, endTime) {
  if (currentTime > endTime) return currentTime - endTime;
  else if (currentTime < startTime) return currentTime - startTime;
  else return 0;
}
