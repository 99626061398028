// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useRouter } from "next/router";

export const useCheckActiveLink = (route) => {
  const router = useRouter();
  const lastIds = localStorage.getItem("lastIds") || "{}";
  const { choice, meetingId } = JSON.parse(lastIds);
  const isActiveLinkCheck = React.useCallback(
    (choice, route) => {
      if (
        router.pathname === "/user/organization/suite" &&
        route === "VIEW-SUITE"
      ) {
        return true;
      } else if (
        router.pathname === "/user" &&
        route === "GOTO-MAINDASHBOARD"
      ) {
        return true;
      } else if (
        router.pathname.includes("/docket/") &&
        route?.includes("DOCKET")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/calendar") &&
        route?.includes("CALENDAR")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/suite/dataroom") &&
        route?.includes("DATAROOM")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/suite/surveys") &&
        route?.includes("SURVEYS")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/suite/tasks") &&
        route?.includes("TASKS")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/suite/manage") &&
        route?.includes("MANAGE-SUITE")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/suite/chatroom") &&
        route?.includes("CHAT")
      ) {
        return true;
      } else if (meetingId && route?.includes("MEETING")) {
        return true;
      } else if (
        router.pathname?.includes("/user/manage") &&
        (choice === "orgadmin" ||
          choice === "adminconsole" ||
          choice === "billing" ||
          choice === "trrystanalytics" ||
          choice === "analytics" ||
          choice === "permissions") &&
        route?.includes("ADMIN-CONSOLE")
      ) {
        return true;
      } else if (
        router.pathname?.includes("/manage") &&
        choice === "addsuite" &&
        route?.includes("ADD-SUITE")
      ) {
        return true;
      } else if (choice === "analytics" && route?.includes("ADMIN-ANALYTICS")) {
        return true;
      } else if (
        choice === "trrystanalytics" &&
        route?.includes("TRRYST-ANALYTICS")
      ) {
        return true;
      } else if (
        choice === "permissions" &&
        route?.includes("ADMIN-PERMISSIONS")
      ) {
        return true;
      } else if (choice === "billing" && route?.includes("ADMIN-BILLING")) {
        return true;
      } else return false;
    },
    [router.pathname]
  );

  return isActiveLinkCheck(choice, route);
};
