// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

export function randomIdGenerator() {
  const string = "abcdefghijklmnopqrstuvwxyz";
  const numeric = "qwertyuioplkjhgfdsazxcvbnm";
  const punctuation = "mnbvcxzqwertyuioplkjhgfdsa";

  let length = 10;

  const formValid = +length > 0;
  if (!formValid) {
    return;
  }
  let character = "";
  let newpassword = "";
  while (newpassword.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity3 = Math.ceil(
      punctuation.length * Math.random() * Math.random()
    );
    let hold = string.charAt(entity1);
    hold = newpassword.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    newpassword = character;
  }
  newpassword = newpassword
    .split("")
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join("");
  return newpassword.substring(0, length);
}
