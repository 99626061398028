// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SDK_LOG_LEVELS } from "./constants.js";
import { ConsoleLogger, MultiLogger, POSTLogger } from "amazon-chime-sdk-js";

const queryLogLevel = "error";
const logLevel = SDK_LOG_LEVELS[queryLogLevel] || SDK_LOG_LEVELS.error;

const postLogConfig = {
  batchSize: 85,
  intervalMs: 2000,
  url: "${process.env.AWS_APIPATH}/chime/logs",
  logLevel,
  metadata: {
    appName: "TrrystMeeting",
    timestamp: Date.now().toString(), // Add current timestamp for unique AWS CloudWatch log stream generation. This will be unique per POSTLogger creation in time.
  },
};

const meetingConfig = {
  simulcastEnabled: false,
  enableWebAudio: true,
  logger: new ConsoleLogger("TrrystMeetingApp", logLevel),
};

const postLogger = new POSTLogger(postLogConfig);
if (process.env.STAGE === "prod") {
  meetingConfig.logger = new MultiLogger(meetingConfig.logger, postLogger);
}
meetingConfig.postLogger = postLogger;

export default meetingConfig;
