import { isSameYear, isSameDay } from "date-fns";

export const getDateTimeFormat = ({
  startDate,
  endDate = null,
  datePosition = 1,
  dateContext = "single",
  noTimeFlag = false,
  showDayFlag = false,
}) => {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : Date.now();

  const isSameDayEvent = isSameDay(start, end);
  const isSameYearEvent = isSameYear(start, end);
  const isMeetingEvent = dateContext === "meeting";
  const isSecondPlace = datePosition === 2;

  const isMeetingDateFullDateTimeFormat = "EEE, do MMM, h:mm a";
  const isMeetingDateShortDateTimeFormat = "h:mm a";

  const isSingleDateFullDateTimeFormat = noTimeFlag
    ? "d MMM yyyy"
    : showDayFlag
    ? "EEE, do MMM 'yy, h:mm a"
    : "d MMM yyyy, h:mm a";

  const isSingleDateShortDateTimeFormat = noTimeFlag
    ? "d MMM"
    : showDayFlag
    ? "EEE, do MMM, h:mm a"
    : "d MMM, h:mm a";

  if (isMeetingEvent) {
    if (isSecondPlace) {
      if (isSameDayEvent) return isMeetingDateShortDateTimeFormat;
      else return isMeetingDateFullDateTimeFormat;
    } else {
      return isMeetingDateFullDateTimeFormat;
    }
  } else {
    if (isSameYearEvent) return isSingleDateShortDateTimeFormat;
    else return isSingleDateFullDateTimeFormat;
  }
};
