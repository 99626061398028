// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, List, Card, CardHeader, CardContent } from "@mui/material";
import { useAppContext, useUserRecentItems, useDockets } from "@app21/core";
import toast from "react-hot-toast";

import {
  DocketLinkSnippet,
  FileLinkSnippet,
} from "blocks/views/SuiteDashboard/foundations/ActivityLinkSnippets";
import ExpandPanel from "blocks/atoms/ExpandPanel";
import { useIntl } from "react-intl";

const RecentActivitiesList = () => {
  const { selectedSuiteId } = useAppContext();

  const {
    data: allAnalytics,
    status: allAnalyticsStatus,
    apiError: allAnalyticsApiError,
  } = useUserRecentItems();
  const [recentDockets, setRecentDockets] = React.useState([]);
  const [recentFiles, setRecentFiles] = React.useState([]);

  const {
    data: recentDocketsData,
    status: recentDocketsDataStatus,
    apiError: recentDocketsDataApiError,
  } = useDockets(recentDockets);

  const dataFetchError = allAnalyticsApiError || recentDocketsDataApiError;
  const dataFetchLoading =
    recentDocketsDataStatus === "loading" || allAnalyticsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  React.useEffect(() => {
    if (allAnalyticsStatus === "success") {
      let dataUpdated = false;

      allAnalytics?.recentDockets?.map((d) => {
        if (d._id === selectedSuiteId) {
          setRecentDockets(
            d.dockets?.map((d) => {
              if (d !== "") return d;
            })
          );
          dataUpdated = true;
        }
      });

      !dataUpdated && setRecentDockets([]);
      dataUpdated = false;

      allAnalytics?.recentFiles?.map((d) => {
        if (d._id === selectedSuiteId) {
          setRecentFiles(d.s3Keys);
          dataUpdated = true;
        }
      });

      !dataUpdated && setRecentFiles([]);
      dataUpdated = false;
    }
  }, [allAnalytics, allAnalyticsStatus, selectedSuiteId]);

  let uniqueRecentFiles = [
    ...new Set(Array.isArray(recentFiles) ? recentFiles : []),
  ];

  return (
    <Card sx={{ height: "100%", flexGrow: 3 }}>
      <CardHeader
        disableTypography
        title={
          <Typography variant="overline" color="primary.darker">
            {`${t("SuiteDashboard.ActivitiesList.headline")}`}
          </Typography>
        }
      />
      <CardContent>
        {recentDockets?.length > 0 ? (
          <>
            <Typography variant="subtitle1" color="textSecondary">
              {`${t("SuiteDashboard.ActivitiesList.listBriefsSubtitle")}`}
            </Typography>
            <List>
              <ExpandPanel maxItems={4}>
                {recentDocketsData?.map(
                  (docket) =>
                    docket.data && (
                      <DocketLinkSnippet
                        key={docket._id}
                        docket={docket.data}
                      />
                    )
                )}
              </ExpandPanel>
            </List>
          </>
        ) : (
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ pl: 2 }}
          ></Typography>
        )}
        <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 2 }}>
          {`${t("SuiteDashboard.ActivitiesList.listFilesSubtitle")}`}
        </Typography>
        <List>
          {uniqueRecentFiles?.length > 0 ? (
            <ExpandPanel maxItems={4}>
              {uniqueRecentFiles?.map((file, index) => (
                <FileLinkSnippet key={index} fileId={file} />
              ))}
            </ExpandPanel>
          ) : (
            <Typography variant="caption" color="textPrimary" sx={{ pl: 2 }}>
              No Recent Files
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default RecentActivitiesList;
