// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  List,
  Paper,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Chip,
  Link,
  Switch,
} from "@mui/material";
import { Visibility, Add, Delete, Update } from "@mui/icons-material";
import {
  useUserRecentItems,
  useUsersInSuite,
  useSuite,
  useAppContext,
  useHandleRouterPush,
  useHandleAddRemoveIdFromRoute,
} from "@app21/core";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import PerfectScrollbar from "react-perfect-scrollbar";
import { msToTime } from "utils/timeHelperUtils";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import clip from "text-clipper";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { getDateTimeFormat } from "utils";

const StyledTab = styled(TabUnstyled)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  cursor: "pointer",
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightNormal,
  fontStyle: theme.typography.body1.fontSize,
  backgroundColor: "transparent",
  width: "100%",
  padding: "4px 6px",
  margin: "2px 2px",
  border: "none",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",

  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },

  "&:focus": {
    borderRadius: "3px",
    outline: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.darker,
    outlineOffset: "2px",
  },
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.primary.darker,
  },

  [buttonUnstyledClasses.disabled]: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
}));

const TabPanel = styled(TabPanelUnstyled)(({ theme }) => ({
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  fontStyle: theme.typography.body1.fontSize,
  maxHeight: "60vh",
}));

const TabsList = styled(TabsListUnstyled)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "space-between",
}));

const DataSuiteActivityLogsPanel = (props) => {
  const { timelineData, handleDataViewToggle, dataView } = props;
  const { selectedSuiteId, userInfo, meetingId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const router = useRouter();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const currentUser = usersInSuite?.find((user) => user._id === userInfo._id);
  const restrictedMembersView =
    selectedSuite?.suiteType?.includes("DATA") &&
    currentUser?.roleInfo?.role !== "MANAGER";
  const isMinWidth = !useIsMobileBreakpoint(1450);
  const isBreakpointWidth = !useIsMobileBreakpoint(1050);

  const isIconNeeded =
    !(isMinWidth || !isBreakpointWidth) || Boolean(meetingId);

  const {
    data: allAnalytics,
    status: allAnalyticsStatus,
    apiError: allAnalyticsApiError,
  } = useUserRecentItems();

  const dataFetchError =
    selectedSuiteApiError || usersInSuiteApiError || allAnalyticsApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    allAnalyticsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);
  const [, setRecentDockets] = React.useState([]);
  const [, setRecentFiles] = React.useState([]);

  const [processedTimelineData, setProcessedTimelineData] = React.useState({});

  const processTimelineData = (data) => {
    const processedData = { added: [], deleted: [], updated: [], viewed: [] };

    timelineData?.forEach((item) => {
      if (item.event.includes("Read")) processedData.viewed.push(item);
      else if (item.event.includes("Create")) processedData.added.push(item);
      else if (item.event.includes("Rename")) processedData.updated.push(item);
      else if (item.event.includes("Delete")) processedData.deleted.push(item);
    });

    return processedData;
  };

  React.useEffect(() => {
    setProcessedTimelineData(processTimelineData(timelineData));
  }, [timelineData]);

  const handleActivityRowClick = (key) => {
    let newRoutePath = addIdToRoute({
      paramKey: "viewFile",
      paramValue: key,
    });
    router.push(newRoutePath, newRoutePath, { shallow: true });
  };
  const showTimelineitem = (action, item, index) => {
    switch (action) {
      case "added":
        return (
          <ListItem key={index}>
            <ListItemText
              primary={
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleActivityRowClick(item?.key)}
                >
                  {clip(item.documentName, 50, { breakWords: true })}
                </Link>
              }
              secondary={
                <Typography variant="caption">
                  {`Added on: ${format(
                    new Date(item.createdAt),
                    getDateTimeFormat({
                      startDate: item.createdAt,
                    })
                  )} by `}
                  <PrintUserInfo userId={item.userId} defaultText="Unknown" />
                </Typography>
              }
            />
          </ListItem>
        );
      case "deleted":
        return (
          <ListItem key={index}>
            <ListItemText
              primary={clip(item.documentName, 50, { breakWords: true })}
              secondary={
                <Typography variant="caption">
                  {`Removed on: ${format(
                    new Date(item.createdAt),
                    getDateTimeFormat({
                      startDate: item?.createdAt,
                    })
                  )} by `}
                  <PrintUserInfo userId={item.userId} defaultText="Unknown" />
                </Typography>
              }
            />
          </ListItem>
        );
      case "updated":
        return (
          <ListItem key={index}>
            <ListItemText
              primary={
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleActivityRowClick(item?.key)}
                >
                  {clip(item.documentName, 50, { breakWords: true })}
                </Link>
              }
              secondary={
                <Typography variant="caption">
                  {`Updated: ${format(
                    new Date(item.createdAt),
                    getDateTimeFormat({
                      startDate: item?.createdAt,
                    })
                  )} by `}
                  <PrintUserInfo userId={item.userId} defaultText="Unknown" />
                </Typography>
              }
            />
          </ListItem>
        );
      case "viewed":
        return (
          <ListItem
            key={index}
            secondaryAction={
              item.viewEndTime && item.viewStartTime ? (
                <Chip
                  icon={<Visibility fontSize="small" />}
                  label={
                    <Typography variant="caption">
                      {item.viewEndTime && item.viewStartTime
                        ? msToTime(item.viewEndTime - item.viewStartTime)
                        : "N/A"}
                    </Typography>
                  }
                />
              ) : null
            }
          >
            <ListItemAvatar>
              <UserAvatar userId={item.userId} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleActivityRowClick(item?.key)}
                >
                  {clip(item.documentName, 50, { breakWords: true })}
                </Link>
              }
              secondary={
                <Typography variant="caption">
                  {format(
                    new Date(item.createdAt),
                    getDateTimeFormat({
                      startDate: item?.createdAt,
                    })
                  )}
                </Typography>
              }
            />
          </ListItem>
        );
    }
  };
  const getTimelineSummary = React.useCallback((e) => {
    const { event, documentName } = e;
    switch (event) {
      case "app21:S3:Read":
        return `Opened file ${documentName}`;
      case "app21:S3:Rename": {
        const oldFileSplit = e.oldKey.split("/");
        const newFileSplit = e.key.split("/");
        return `Renamed ${oldFileSplit[oldFileSplit.length - 1]} to ${
          newFileSplit[newFileSplit.length - 1]
        }`;
      }
      case "app21:S3:Create": {
        const fileName = e.key.split("/");
        return `Created file ${fileName[fileName.length - 1]}`;
      }
    }
  }, []);

  React.useEffect(() => {
    if (allAnalyticsStatus === "success") {
      let dataUpdated = false;

      allAnalytics?.recentDockets?.map((d) => {
        if (d._id === selectedSuiteId) {
          setRecentDockets(d.dockets);
          dataUpdated = true;
        }
      });

      !dataUpdated && setRecentDockets([]);
      dataUpdated = false;

      allAnalytics?.recentFiles?.map((d) => {
        if (d._id === selectedSuiteId) {
          setRecentFiles(d.s3Keys);
          dataUpdated = true;
        }
      });

      !dataUpdated && setRecentFiles([]);
      dataUpdated = false;
    }
  }, [allAnalytics, allAnalyticsStatus, selectedSuiteId]);

  return (
    <Paper
      sx={{
        height: "auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.paper",
        p: 1,
        maxHeight: "75vh",
      }}
    >
      {!restrictedMembersView && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 2 }}>
            Activities in Suite
          </Typography>
          <Typography>
            All
            <Switch
              checked={dataView === "ONLY-ME"}
              onChange={handleDataViewToggle}
            />
            Only Me
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          flexGrow: 1,
        }}
      >
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <StyledTab>{!isIconNeeded ? "Added" : <Add />}</StyledTab>
            <StyledTab>{!isIconNeeded ? "Viewed" : <Visibility />}</StyledTab>
            <StyledTab>{!isIconNeeded ? "Updates" : <Update />}</StyledTab>
            <StyledTab>{!isIconNeeded ? "Deleted" : <Delete />}</StyledTab>
          </TabsList>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <TabPanel value={0}>
              <PerfectScrollbar
                options={{ suppressScrollX: true }}
                style={{ maxHeight: "60vh" }}
              >
                <List dense>
                  {processedTimelineData?.added?.map((item, index) =>
                    showTimelineitem("added", item, index)
                  )}
                </List>
              </PerfectScrollbar>
            </TabPanel>
            <TabPanel value={1}>
              <PerfectScrollbar
                options={{ suppressScrollX: true }}
                style={{ maxHeight: "60vh" }}
              >
                <List dense>
                  {processedTimelineData?.viewed
                    ?.filter(
                      (e) =>
                        e.viewEndTime !== null || e.viewEndTime !== undefined
                    )
                    .map((item, index) =>
                      showTimelineitem("viewed", item, index)
                    )}
                </List>
              </PerfectScrollbar>
            </TabPanel>
            <TabPanel value={2}>
              <PerfectScrollbar
                options={{ suppressScrollX: true }}
                style={{ maxHeight: "60vh" }}
              >
                <List dense>
                  {processedTimelineData?.updated?.map((item, index) =>
                    showTimelineitem("updated", item, index)
                  )}
                </List>
              </PerfectScrollbar>
            </TabPanel>
            <TabPanel value={3}>
              <PerfectScrollbar
                options={{ suppressScrollX: true }}
                style={{ maxHeight: "60vh" }}
              >
                <List dense>
                  {processedTimelineData?.deleted?.map((item, index) =>
                    showTimelineitem("deleted", item, index)
                  )}
                </List>
              </PerfectScrollbar>
            </TabPanel>
          </Box>
        </TabsUnstyled>
      </Box>
    </Paper>
  );
};

export default DataSuiteActivityLogsPanel;
