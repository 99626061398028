import React, { useContext, useState } from "react";
import {
  useUsersInSuite,
  useAppContext,
  useSuite,
  useDocketsInSuite,
  executeApi,
  useSelectedBucketId,
  useHandleRouterPush,
  checkIfNull,
  getRolePrivileges,
  useHandleDocketActions,
  app21APIInstance,
  useHandleAddRemoveIdFromRoute,
  useMeetingsInDockets,
  useUsersInOrganization,
} from "@app21/core";
import { useRouter } from "next/router";
import { useConfirm } from "material-ui-confirm";
import { handleWalkinCall } from "blocks/atoms";
import toast from "react-hot-toast";
import { checkTimeWithinRange } from "utils/timeHelperUtils";
import { useCheckActiveLink } from "hooks";
import isDate from "validator/lib/isDate";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { sort } from "fast-sort";

const DocketsContext = React.createContext({});

export const useDocketsContext = () => {
  const state = useContext(DocketsContext);
  if (!state) {
    throw new Error("useDocketsContext must be used within DocketsProvider");
  }
  return state;
};
const DocketsProvider = ({
  isMobile = false,
  viewContext,
  actionContext,
  children,
}) => {
  const {
    meetingId,
    checkAccessRules,
    userInfo,
    viewFile,
    selectedOrganizationId,
    selectedSuiteId,
    selectedDocketId,
  } = useAppContext();
  const router = useRouter();
  const confirm = useConfirm();
  const [listOfDocketIds, setListOfDocketIds] = useState([]);
  const { loadRoute } = useHandleRouterPush();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const isConferenceLinkActive = useCheckActiveLink("JOIN-MEETING") ?? false;
  const fileViewerFlag = !checkIfNull(viewFile);
  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(selectedSuiteId);
  const [selectedDocket, setSelectedDocket] = React.useState(null);
  const { handleDocketActions } = useHandleDocketActions();
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;

  const isBoardContext = selectedSuite?.suiteType?.includes("BOARD");
  const selectedBucketId = useSelectedBucketId(null, true);
  const { setChimeRoute } = useVideocallContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);

  const {
    data: baseDockets = {},
    status: baseDocketsStatus,
    apiError: baseDocketsApiError,
  } = useDocketsInSuite(selectedSuiteId);

  const { data: docketMeetings, status: docketMeetingsStatus } =
    useMeetingsInDockets(listOfDocketIds);
  const isCreator = selectedDocket?.createdBy === userInfo._id;
  const isInvitee = true; // needs to be fixed to a more nuanced rule..
  const [allDockets, setAllDockets] = useState([]);

  React.useEffect(() => {
    const list = baseDockets?.map((d) => d._id) ?? [];
    setListOfDocketIds(list);
  }, [baseDocketsStatus]);

  React.useEffect(() => {
    if (selectedDocketId) {
      const docket = allDockets?.find(
        (docket) => docket._id === selectedDocketId
      );
      setSelectedDocket(docket);
    } else {
      setSelectedDocket(null);
    }
  }, [selectedDocketId, allDockets]);

  const sortedDocketsList = React.useMemo(() => {
    let sortedResult = sort(allDockets).by([
      { desc: (a) => a.members?.includes(userInfo._id) },
      { desc: (a) => a.createdAt },
    ]);
    return sortedResult;
  }, [allDockets, userInfo._id]);

  const filteredMembershipDocketsList = React.useMemo(() => {
    let filteredResult = sortedDocketsList.filter((docket) => {
      return (
        docket.members?.includes(userInfo._id) ||
        docket.visitors?.includes(userInfo._id)
      );
    });
    return filteredResult;
  }, [sortedDocketsList, userInfo._id]);

  const getStatusLabelInfo = React.useCallback(
    (docket) => {
      let status = "DRAFT";

      if (docket?.status && docket?.status !== "DRAFT") {
        status = docket.status;
        if (
          docketMeetings &&
          docketMeetings[docket._id]?.length &&
          docketMeetings[docket._id][0]?.meetingJSON?.start
        ) {
          let meetingStatusTime = checkTimeWithinRange(
            Date.now(),
            Date.parse(docketMeetings[docket._id][0]?.meetingJSON?.start),
            Date.parse(docketMeetings[docket._id][0]?.meetingJSON?.end)
          );
          if (meetingStatusTime > 0) status = "COMPLETED";
          else if (meetingStatusTime === 0) status = "ONGOING";
        }
      } else if (
        docketMeetings &&
        docketMeetings[docket._id]?.length &&
        isDate(docketMeetings[docket._id][0]?.meetingJSON?.start)
      ) {
        status = "SCHEDULED";
      }

      switch (status) {
        // first part is style, second part status label, third part the status order number for sorting
        case "DRAFT":
          return ["Draft", 1, "secondary"];
        case "SCHEDULED":
          return ["Draft - Scheduled", 2, "primary"];
        case "ONGOING":
          return ["Ongoing", 2, "primary"];
        case "PUBLISHED":
          return ["Published", 3, "success"];
        case "COMPLETED":
          return ["Completed", 4, "warning"];
        case "ARCHIVED":
          return ["Archived", 5, "error"];
        default:
          return ["Draft", 1, "default"];
      }
    },
    [docketMeetings]
  );

  React.useEffect(() => {
    if (baseDocketsStatus === "success" && docketMeetingsStatus === "success") {
      let populatedDockets = [];
      baseDockets?.forEach((docket) => {
        let baseDocketObj = { ...docket };
        let baseDocketObjMeetings = [];

        if (docketMeetings && docketMeetings[docket._id]?.length) {
          docketMeetings[docket._id].forEach((meeting) => {
            let meetingStartTime = new Date(
              meeting?.meetingJSON?.start
            ).valueOf();
            let meetingEndTime = new Date(meeting?.meetingJSON?.end).valueOf();
            let meetingStatusTime = checkTimeWithinRange(
              Date.now(),
              Date.parse(meeting?.meetingJSON?.start),
              Date.parse(meeting?.meetingJSON?.end)
            );
            let meetingStatus = meeting?.meetingJSON?.start
              ? meetingStatusTime > 0
                ? "COMPLETED"
                : meetingStatusTime === 0
                ? "ONGOING"
                : "SCHEDULED"
              : "DRAFT";

            let meetingObj = {
              ...meeting,
              meetingStartTime,
              meetingEndTime,
              meetingStatusTime,
              meetingStatus,
            };
            baseDocketObjMeetings.push(meetingObj);
          });
        }

        baseDocketObj.relatedMeetings = baseDocketObjMeetings;
        populatedDockets.push(baseDocketObj);
      });
      setAllDockets(populatedDockets);
    }
  }, [baseDockets, docketMeetings]);

  const { accessFlag: canAddNew } = checkAccessRules({
    entity: "SUITE",
    action: "CREATE-DOCKET",
    featureName: "DOCKET",
    isCreator: isCreator,
    isInvitee: isInvitee,
  });

  const checkCanStartDocketWalkinCall = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "START-WALKINCALL-DOCKET",
      featureName: "VIDEOCALL",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: rowData.members ?? [],
      }),
    })?.accessFlag;
  };
  const checkCanEditDocket = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "UPDATE-DOCKET",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: rowData.members ?? [],
      }),
    })?.accessFlag;
  };
  const checkCanDeleteDocket = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "DELETE-DOCKET",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: selectedSuite,
        members: usersInSuite.map((user) => user._id),
      }),
    })?.accessFlag;
  };

  const checkCanViewDocket = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-DOCKET",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanViewTasks = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-DOCKET-ACTIONS",
      featureName: "DOCKET-ACTIONS",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanViewSurveys = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-RESOLUTION",
      featureName: "DOCKET-RESOLUTIONS",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };
  const checkCanViewCalendar = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-DOCKET",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanViewFiles = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-INFOPACK",
      featureName: "DOCKET-INFOPACK",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanViewChat = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "PARTICIPATE-CHAT",
      featureName: "DOCKET-CHAT",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanViewRecords = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "VIEW-RECORDS",
      featureName: "DOCKET-RECORDS",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanAccessMembers = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "USERS-VISIBILITY",
      action: "VIEW-ALL-DOCKET-USERS",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData,
        members: [
          ...(rowData.members ?? []),
          ...(rowData.visitors ? rowData.visitors.map((v) => v._id) : []),
        ],
      }),
    })?.accessFlag;
  };

  const checkCanManageInvitees = (rowData = {}) => {
    if (!rowData) return false;

    return checkAccessRules({
      entity: "DOCKET",
      action: "MANAGE-INVITEES",
      featureName: "DOCKET",
      ...getRolePrivileges({
        userId: userInfo._id,
        baseObject: rowData ?? {},
        members: rowData?.members ?? [],
      }),
    });
  };
  const docketPermissions = React.useMemo(() => {
    return {
      canAddNew,
      checkCanViewDocket,
      checkCanEditDocket,
      checkCanDeleteDocket,
      checkCanStartDocketWalkinCall,
      checkCanViewTasks,
      checkCanViewSurveys,
      checkCanViewCalendar,
      checkCanViewFiles,
      checkCanViewChat,
      checkCanViewRecords,
      checkCanAccessMembers,
      checkCanManageInvitees,
    };
  }, [selectedSuiteId]);

  const fetchDownloadFileUrl = React.useCallback(
    async (file, protectFlag, protectText) => {
      let downloadUrlResponse = protectFlag
        ? await executeApi("PROTECT-FILE-S3", {
            bucket: selectedBucketId,
            key: file.id,
            calledBy: userInfo._id,
          })
        : await executeApi("FETCH-SIGNED-URL", {
            bucket: selectedBucketId,
            key: file.id,
            urlTypeRequested: "get",
            contentDisposition: "attachment",
            contentType: file.fileType, //FIXME: Raghu - can you check this.. content type is not there here..
          });

      return downloadUrlResponse;
    },
    [selectedBucketId, userInfo._id]
  );

  const handleDocketCreate = async (docketId, formData) => {
    await handleDocketActions(
      { action: "ADD-DOCKET", docketId: docketId, docketData: formData },
      null,
      null
    );
  };

  const handleDocketDelete = async (rowData) => {
    if (rowData) {
      confirm({
        description: "Do you want to delete the docket?",
      })
        .then(async () => {
          await handleDocketActions(
            { action: "DELETE-DOCKET", docketId: rowData._id },
            null,
            null
          );
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    }
  };

  const handleDocketView = (docketId) => {
    loadRoute("VIEW-DOCKET", { docketId: docketId });
  };

  const handleDocketEdit = async (docketId) => {
    loadRoute("EDIT-DOCKET", { docketId: docketId });
  };

  const handleDocketSave = async (formData) => {
    await handleDocketActions(
      {
        action: "UPDATE-DOCKET",
        docketId: selectedDocketId,
        docketData: formData,
      },
      null,
      null
    );
  };
  const handleDocketWalkinVideocallClick = async (docketId) => {
    setChimeRoute("base");
    await handleWalkinCall({
      docketId,
      userInfo,
      selectedSuiteId,
      addIdToRoute,
      router,
    });
  };

  const handleDocketChatClick = async (docketId) => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "docket",
      objectId: docketId,
      calledBy: userInfo._id,
      additionalData: {
        suiteId: selectedSuiteId,
      },
    });
    if (response && response?.data?.data?.data?.[0]) {
      loadRoute("GROUP-CHAT", {
        chatId: response?.data?.data.data[0].id,
        chatType: "dockets",
      });
    }
  };

  const handleDocketTasksClick = (docketId) => {
    console.log("click");
    loadRoute("LIST-TASKS", {
      docketId: docketId,
    });
  };
  const handleDocketMeetingClick = (docketId) => {
    loadRoute("VIEW-CALENDAR", {
      docketId: docketId,
    });
  };

  const handleDocketRecordingsClick = (docketId) => {
    loadRoute("VIDEOCALL-TRANSCRIPTS", {
      docketId: docketId,
    });
  };
  const handleDocketSurveysClick = (docketId) => {
    loadRoute("LIST-SURVEYS", {
      docketId: docketId,
    });
  };
  const handleDocketFilesClick = (docketId) => {
    loadRoute("VIEW-DATAROOM", {
      docketId: docketId,
      s3Key: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${docketId}/`,
    });
  };
  const docketsContextValues = {
    isMobile,
    isCreator,
    isInvitee,
    isBoardContext,
    viewContext,
    actionContext,
    fileViewerFlag,
    loadRoute,
    meetingId,
    selectedDocket,
    selectedSuite,
    selectedSuiteStatus,
    isDocketOwner,
    isConferenceLinkActive,
    usersInSuite,
    usersInSuiteStatus,
    docketPermissions,
    selectedDocketId,
    selectedDocket,
    allDockets,
    sortedDocketsList,
    filteredMembershipDocketsList,
    baseDocketsStatus,
    handleDocketActions,
    handleDocketCreate,
    handleDocketDelete,
    handleDocketEdit,
    handleDocketView,
    handleDocketSave,
    handleDocketChatClick,
    handleDocketFilesClick,
    handleDocketMeetingClick,
    handleDocketRecordingsClick,
    handleDocketSurveysClick,
    handleDocketTasksClick,
    handleDocketWalkinVideocallClick,
  };

  return (
    <DocketsContext.Provider value={{ ...docketsContextValues }}>
      {children}
    </DocketsContext.Provider>
  );
};
export default DocketsProvider;
