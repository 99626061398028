// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Icon } from "@iconify/react";
import closeCircleFill from "@iconify/icons-eva/close-circle-fill";

// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={closeCircleFill} />,
      },

      styleOverrides: {
        root: {
          "& .MuiChip-deleteIcon": {
            color: theme.palette.grey[0],
          },
        },
        colorDefault: {
          // <<<<<<< HEAD
          //           backgroundColor: theme.palette.gray.light,
          //           color: theme.palette.gray.main,
          //           '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
          // =======
          "& .MuiChip-avatarMedium, .MuiChip-avatarSmall": {
            // >>>>>>> v4dev
            color: theme.palette.text.secondary,
          },
        },
        outlined: {
          borderColor: theme.palette.grey[500_32],
          "& .MuiChip-colorPrimary": {
            borderColor: theme.palette.primary.main,
          },
          "& .MuiChip-colorSecondary": {
            borderColor: theme.palette.secondary.main,
          },
        },
        //
        avatarColorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.dark,
        },
        avatarColorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.dark,
        },
        avatarColorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.dark,
        },
        avatarColorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
  };
}
