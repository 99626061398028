// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useAppContext } from "@app21/core";

const useIsMobileBreakpoint = (maxWidth = 480, meetingFlag = false) => {
  const islessThanWidth = useMediaQuery(`(max-width:${maxWidth}px)`);
  const { meetingId, chimeViewMode = "compact" } = useAppContext();
  const isMobileBreakpoint = useMemo(() => {
    return (
      islessThanWidth ||
      isMobile ||
      (meetingFlag && meetingId && chimeViewMode !== "compact")
    );
  }, [islessThanWidth, meetingFlag, meetingId, chimeViewMode]);
  return isMobileBreakpoint;
};

export default useIsMobileBreakpoint;
