import React, { Fragment } from "react";
import { Stack, Button, IconButton, Box, useMediaQuery } from "@mui/material";
import {
  ChatIcon,
  DataroomIcon,
  AverageViewIcon,
} from "blocks/atoms/trrysticons";
import {
  useAppContext,
  useHandleRouterPush,
  getS3ImageWrapper,
  useSuite,
} from "@app21/core";
import { useIsMobileBreakpoint } from "hooks";
import { ScheduleMeetingDropdownButton } from "blocks/atoms";

export default function DashboardActionButtonsStack({ buttonsList = [] }) {
  const { userInfo } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { selectedOrganizationId, selectedSuiteId } = useAppContext();
  const isMobile = useIsMobileBreakpoint(800);

  const FilesButton = () => {
    return (
      <Button
        startIcon={<DataroomIcon />}
        variant="contained"
        sx={{
          minWidth: isMobile ? 0 : 8,
          color: (theme) => theme.palette.grey[0],
          border: (theme) =>
            `${theme.spacing(0.1)} solid ${theme.palette.grey[0]}`,
        }}
        size={isMobile ? "small" : "medium"}
        onClick={() =>
          loadRoute("VIEW-DATAROOM", {
            s3Key: `${selectedOrganizationId}/${selectedSuiteId}/`,
          })
        }
      >
        {!isMobile && "FILES"}
      </Button>
    );
  };

  const DiscussButton = () => {
    return (
      <Button
        startIcon={<ChatIcon />}
        variant="contained"
        size={isMobile ? "small" : "medium"}
        sx={{
          minWidth: isMobile ? 0 : 8,
          border: (theme) =>
            `${theme.spacing(0.1)} solid ${theme.palette.grey[0]}`,
        }}
        onClick={() => {
          loadRoute("GROUP-CHAT", {
            orgId: selectedOrganizationId,
            suiteId: selectedSuiteId,
            chatId: `${selectedSuiteId}-CHAT`,
            chatType: "suite",
          });
        }}
      >
        {!isMobile && "DISCUSS"}
      </Button>
    );
  };

  const MeetingButton = () => {
    return (
      <ScheduleMeetingDropdownButton
        isMobile={isMobile}
        docketId={null}
        sx={{
          borderRadius: (theme) => theme.shape.borderRadiusButton,
          border: (theme) =>
            `${theme.spacing(0.1)} solid ${theme.palette.grey[0]}`,
        }}
      />
    );
  };

  const InsightsButton = () => {
    return (
      <Button
        startIcon={<AverageViewIcon />}
        variant="contained"
        size={isMobile ? "small" : "medium"}
        sx={{
          minWidth: isMobile ? 0 : 8,
          border: (theme) =>
            `${theme.spacing(0.1)} solid ${theme.palette.grey[0]}`,
        }}
        onClick={() =>
          loadRoute("GOTO-LINK", {
            hrefLink: "/user/organization/suite/analytics",
          })
        }
      >
        {!isMobile && "INSIGHTS"}
      </Button>
    );
  };
  const getButton = (buttonName) => {
    switch (buttonName) {
      case "meeting":
        return <MeetingButton />;
      case "insights":
        return <InsightsButton />;
      case "discuss":
        return <DiscussButton />;
      case "files":
        return <FilesButton />;
      default:
        return null;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        flexGrow: 1,
        gap: isMobile ? 1 : 2,
        m: 2,
        zIndex: 2,
      }}
    >
      {buttonsList.map((buttonName, index) => (
        <Fragment key={index}> {getButton(buttonName)}</Fragment>
      ))}
    </Box>
  );
}
