import React from "react";
import {
  Card,
  Box,
  Typography,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import { Edit, QueryStat, QueryStats } from "@mui/icons-material";
import { IncreaseIcon, RightArrowSquareIcon } from "blocks/atoms/trrysticons";
import { useIsMobileBreakpoint } from "hooks";

export default function DataSuiteEngagementInsightCard({
  Icon = Edit,
  title = "Files Viewed",
  value = "1000",
  units = "",
  onClick = () => null,
  uptrendFlag = false,
  downtrendFlag = false,
  subheader = null,
}) {
  const isMobile = useIsMobileBreakpoint(480, true);
  const [hovered, setHovered] = React.useState(false);

  return (
    <Card
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        m: 1,
        width: "100%",
        height: "100%",
        maxHeight: 100,
        display: "flex",
        gap: 2,
        p: 2,
        alignItems: "stretch",
        borderRadius: (theme) => theme.shape.borderRadius * 3,
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "60%",
          width: 40,
        }}
      >
        <Icon sx={{ height: 40, width: 40 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
          width: "100%",
          position: "relative",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: `${
              isMobile ? "14px" : `clamp(14px, 1.2vw, 18px)`
            } !important`,

            overflowWrap: "break-word",
          }}
        >
          {title}
        </Typography>
        {Boolean(value) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="h3"
              color="secondary"
              sx={{
                fontSize: `${
                  isMobile ? "14px" : `clamp(14px, 1.2vw, 21px)`
                } !important`,
                wordWrap: "break-word",
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="h5"
              color="secondary"
              sx={{
                ml: 1,
                fontSize: `${
                  isMobile ? "14px" : `clamp(12px, 1.1vw, 16px)`
                } !important`,
                wordWrap: "break-word",
              }}
            >
              {units}
            </Typography>
          </Box>
        ) : (
          <Typography color="secondary" variant="h5">
            ---
          </Typography>
        )}
        {uptrendFlag ? <IncreaseIcon color="success" /> : null}
        {downtrendFlag ? <IncreaseIcon color="error" /> : null}
        <Typography>{subheader ? subheader : null}</Typography>
      </Box>
      {hovered && (
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 0, bottom: 0 }}
          onClick={onClick}
        >
          <RightArrowSquareIcon fontSize="inherit" />
        </IconButton>
      )}
    </Card>
  );
}
