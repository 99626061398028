import React, { useState } from "react";
import ChartsEmbedSdk from "@mongodb-js/charts-embed-dom";
import JWT from "jsonwebtoken";

const MongodbChartContext = React.createContext(null);

// Constants
const MONGODB_JWT_TOKEN_KEY = "mongodb:charts:JWT:TOKEN";

export const useMongodbChartContext = () => {
  const state = React.useContext(MongodbChartContext);
  if (state) return state;

  throw new Error(
    "useMongodbChartContext is undefined. Did you forgot to wrap your component with MongodbChartProvider?"
  );
};

export const MongodbChartProvider = (props) => {
  const [jwtToken, setJwtToken] = useState(null);

  const mongodbChartSdk = React.useMemo(() => {
    if (jwtToken) {
      return new ChartsEmbedSdk({
        baseUrl: process.env.NEXT_PUBLIC_MONGODB_CHARTS_BASE_URL,
        getUserToken: () => jwtToken,
      });
    }
    return null;
  }, [jwtToken]);

  React.useEffect(() => {
    // Check JWT token in local storage or else Fetch JWT from server
    if (localStorage.getItem(MONGODB_JWT_TOKEN_KEY)) {
      try {
        const token = JWT.decode(localStorage.getItem(MONGODB_JWT_TOKEN_KEY));

        if (Date.now() >= token.exp * 1000) throw new Error("Token Expired");

        setJwtToken(localStorage.getItem(MONGODB_JWT_TOKEN_KEY));
      } catch (error) {
        // Token is invalid or expired!!! Fetch from server
        const token = JWT.sign({ text: "my-token" }, "test", {
          expiresIn: "1d",
        }); // TODO: Fetch this from server
        localStorage.setItem(MONGODB_JWT_TOKEN_KEY, token);
        setJwtToken(token);
      }
    } else {
      const token = JWT.sign({ text: "my-token" }, "test", {
        expiresIn: "1d",
      }); // TODO: Fetch this from server
      localStorage.setItem(MONGODB_JWT_TOKEN_KEY, token);
      setJwtToken(token);
    }
  }, []);

  return (
    <MongodbChartContext.Provider value={{ jwtToken, mongodbChartSdk }}>
      {props.children}
    </MongodbChartContext.Provider>
  );
};
