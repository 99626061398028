// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Box, Typography, List, Button, Paper } from "@mui/material";
import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  notificationTemplate,
  supportedNotificationTemplateList,
} from "blocks/views/Notifications/foundations";
import {
  useUserInfo,
  useOrgsAndSuitesForUser,
  useNotifications,
  useAppContext,
  getCreatedDateFromOrgsAndSuites,
  useHandleNotificationsActions,
} from "@app21/core";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { sort } from "fast-sort";
import toast from "react-hot-toast";

const NotificationsWall = ({
  viewContext,
  maxWidgetHeight = "35vh",
  handleClose,
  viewMode,
}) => {
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();

  const {
    data: loggedInUser,
    status: loggedInUserStatus,
    apiError: loggedInUserApiError,
  } = useUserInfo();
  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(loggedInUser?._id);
  const {
    data: notifications,
    status: notificationsStatus,
    apiError: notificationsApiError,
  } = useNotifications({
    orgsAndSuites: orgsAndSuites,
  });

  const dataFetchError =
    loggedInUserApiError || orgsAndSuitesApiError || notificationsApiError;
  const dataFetchLoading =
    loggedInUserStatus === "loading" ||
    orgsAndSuitesStatus === "loading" ||
    notificationsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { handleNotificationsActions, clearNotificationsStatus } =
    useHandleNotificationsActions();

  let notificationsCount = 0;

  const handleClearAllNotifications = async () => {
    await handleNotificationsActions(
      {
        action: "CLEAR-NOTIFICATIONS",
        notifications: notifications,
        viewContext: viewContext,
      },
      null,
      null
    );

    handleClose();
  };

  const sortedAndFilteredNotifications = React.useMemo(() => {
    let processedNotifications = [];
    if (viewContext === "suite") {
      notifications?.suites?.forEach((suite) => {
        if (suite.id === selectedSuiteId) {
          let suiteCreatedDate = getCreatedDateFromOrgsAndSuites({
            userId: loggedInUser._id,
            orgsAndSuites,
            organizationId: selectedOrganizationId,
            suiteId: selectedSuiteId,
            viewContext,
          });
          suite?.notifications?.reverse().forEach((notification, index) => {
            if (!notification.clearedBy?.includes(loggedInUser?._id)) {
              if (
                new Date(notification.createdAt).getTime() >
                new Date(suiteCreatedDate).getTime()
              ) {
                processedNotifications.push(notification);
              }
            }
          });
        }
      });
    } else if (viewContext === "main") {
      notifications?.user?.notifications?.reverse()?.forEach((notification) => {
        if (!notification.clearedBy?.includes(loggedInUser?._id)) {
          processedNotifications.push(notification);
        }
      });

      notifications?.organizations?.map((org) => {
        let orgCreatedDate = getCreatedDateFromOrgsAndSuites({
          orgsAndSuites,
          userId: loggedInUser._id,
          organizationId: org.id,
          viewContext,
        });
        return org.notifications?.reverse().forEach((notification) => {
          if (!notification.clearedBy?.includes(loggedInUser?._id)) {
            if (
              new Date(notification.createdAt).getTime() >
              new Date(orgCreatedDate).getTime()
            ) {
              processedNotifications.push(notification);
            }
          }
        });
      });
    }

    const sortedNotifications =
      sort(processedNotifications).desc(
        (notification) => notification["createdAt"]
      ) ?? [];
    return sortedNotifications;
  }, [notifications, viewContext, selectedSuiteId]);
  return (
    <Box
      sx={{
        display: "flex",
        m: 0,

        flexDirection: "column",
        height: "100%",
        maxHeight: maxWidgetHeight,
        width: "100%",
      }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <List
          disablePadding
          dense={true}
          sx={{ maxHeight: maxWidgetHeight - 50, mr: 2 }}
        >
          {sortedAndFilteredNotifications.map((notification, index) => {
            let TemplateName = supportedNotificationTemplateList.includes(
              notification.notificationKey
            )
              ? notificationTemplate[notification.notificationKey]
              : notificationTemplate["default"];

            if (TemplateName) {
              notificationsCount++;
              return <TemplateName key={index} notification={notification} />;
            }
          })}
          {notificationsCount === 0 && (
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography variant="subtitle1" color="textPrimary">
                You are up-to-date on the happenings here!
              </Typography>
            </Box>
          )}
        </List>
      </PerfectScrollbar>
      {notificationsCount > 3 &&
        viewMode === "popup" &&
        (clearNotificationsStatus?.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Paper
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <Button onClick={handleClearAllNotifications}>Clear All</Button>
          </Paper>
        ))}
    </Box>
  );
};

export default NotificationsWall;
