// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  Button,
  Avatar,
  Stack,
} from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import {
  useAppContext,
  useHandleRouterPush,
  getS3ImageWrapper,
  useSuite,
} from "@app21/core";

import PersonalOfficeImage from "blocks/atoms/graphics/officeimage.png";
import { ScheduleMeetingDropdownButton } from "blocks/atoms";
import { useIntl } from "react-intl";
import { useIsMobileBreakpoint } from "hooks";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",

    flexGrow: 1,
    height: "100%",
    minHeight: "60vh",
    backgroundImage: `url(${backgroundPicture})`,
    backgroundImage: `linear-gradient(135deg, rgba(16,10,10,1) 0%, rgba(255,255,255,0.25) 92%), url(${backgroundPicture})`,

    backgroundPosition: "center",
    backgroundColor: theme.palette.primary.lighter,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",
      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      bgcolor: (theme) => alpha(theme.palette.grey[600], 0.72),
    },
  };
});

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const PersonalSuiteWelcomeCard = () => {
  const { userInfo } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();
  const { data: selectedSuite } = useSuite(selectedSuiteId);
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const isMobile = useIsMobileBreakpoint();
  return (
    <RootStyle
      ownerState={{ backgroundPicture: PersonalOfficeImage }}
      sx={{ m: 0 }}
    >
      <CardContent
        sx={{
          pt: 1,
          height: "100%",
          width: "100%",
          color: "grey.800",
          zIndex: 10,
          bgcolor: (theme) => alpha(theme.palette.secondary.lighter, 0.3),
        }}
      >
        <Typography gutterBottom variant="overline" color="secondary.lighter">
          {`${t("SuiteDashboard.SuiteWelcomeMessage.mainOverline")}`}
        </Typography>
        <Box sx={{ display: "flex", my: 3, alignItems: "center" }}>
          <Avatar
            variant="rounded"
            src={getS3ImageWrapper(
              userInfo?.image,
              "initials",
              userInfo?.fullName
            )}
            sx={{ height: 60, width: 60, mr: 3 }}
          />
          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary.contrastText"
            sx={{ mt: 1 }}
          >
            {`${t(
              "SuiteDashboard.SuiteWelcomeMessage.greetingSuiteDashboard"
            )}`}
            , {userInfo.fullName}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="primary.contrastText"
          sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480 }}
        >
          This is your Personal suite area. You can save and share documents or
          conduct videocalls whenever you need to.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <ScheduleMeetingDropdownButton
            isMobile={isMobile}
            docketId={null}
            color="primary"
            sx={{ width: "100%" }}
            TextButtonProps={{ sx: { width: "100%" } }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              loadRoute("VIEW-DATAROOM", {
                s3Key: `${selectedOrganizationId}/${selectedSuiteId}/`,
              })
            }
          >
            Manage Documents
          </Button>
        </Box>
      </CardContent>
    </RootStyle>
  );
};

export default PersonalSuiteWelcomeCard;

{
  /*<Card className={clsx(classes.cardRoot, globalClasses.glassPaper)}>
      <CardContent className={classes.cardContent}>
        <Typography variant="overline" color="primary">
          {`${t('SuiteDashboard.SuiteWelcomeMessage.mainOverline')}`}
        </Typography>
        <Typography variant="h5">
          {`${t('SuiteDashboard.SuiteWelcomeMessage.greetingSuiteDashboard')}`}, {userInfo.fullName}
        </Typography>
        <Typography variant="body1">{`${t('SuiteDashboard.SuiteWelcomeMessage.mainSuiteMessage')}`}</Typography>
      </CardContent>
      <CardMedia
        className={classes.backdropImage}
        image={getS3ImageWrapper(selectedSuite?.image, 'initials', selectedSuite?.fullName)}
        title="Board Meeting"
      />
    </Card>
  );
*/
}
