// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  Button,
  Dialog,
  Avatar,
  Stack,
  useMediaQuery,
  IconButton,
  Tooltip,
  AvatarGroup,
} from "@mui/material";
import {
  AttachFile,
  Analytics,
  Chat,
  VideoCall,
  Cached,
  MoreHoriz,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import {
  useAppContext,
  useHandleRouterPush,
  getS3ImageWrapper,
  useSuite,
} from "@app21/core";
import { constantCase } from "change-case";

import { ScheduleMeetingDropdownButton } from "blocks/atoms";
import { useIsMobileBreakpoint } from "hooks";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",
    flexGrow: 1,
    height: "100%",

    backgroundImage: `linear-gradient(45deg, rgba(56,50,50,1) 0%, rgba(255,255,255,0.25) 52%), url(${backgroundPicture})`,
    backgroundPosition: "center",
    backgroundColor: theme.palette.primary.lighter,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",

      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      bgcolor: (theme) => alpha(theme.palette.grey[600], 0.12),
    },
  };
});

// const RootStyle = styled(Card)(({ theme }) => {
//   return {
//     boxShadow: "none",
//     textAlign: "center",
//     flexGrow: 1,
//     padding: 3,
//     height: "100%",
//     display: "flex",
//     backgroundColor: alpha(theme.palette.primary.darker, 0.5),
//     backgroundSize: "cover",
//     [theme.breakpoints.up("md")]: {
//       textAlign: "left",
//       alignItems: "center",
//       justifyContent: "space-between",
//     },
//     [theme.breakpoints.up("xl")]: { height: "100%" },
//     "&:after": {
//       content: "''",

//       flexGrow: 1,
//       width: "100%",
//       height: "100%",
//       position: "absolute",
//       bgcolor: (theme) => alpha(theme.palette.grey[600], 0.12),
//     },
//   };
// });

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export default function ExecutiveSuiteWelcomeCard({}) {
  const { userInfo } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { selectedOrganizationId, selectedSuiteId, suiteRole } =
    useAppContext();
  const isSuiteManager = suiteRole === "MANAGER";

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  React.useEffect(() => {
    if (selectedSuiteApiError && selectedSuiteStatus != "loading") {
      toast.error(selectedSuiteApiError.message);
    }
  }, [selectedSuiteApiError]);
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const mobileMinWidth = useMediaQuery("(min-width:1200px)");
  const isMobile = useIsMobileBreakpoint();
  const [maxUsersViewCount, setMaxUsersViewCount] = React.useState(9);
  const suiteImageHeight = isMobile ? 100 : 170;
  const toggleUsersViewCount = () => {
    maxUsersViewCount === 9
      ? setMaxUsersViewCount(50)
      : setMaxUsersViewCount(9);
  };
  return (
    <RootStyle
      ownerState={{
        backgroundPicture: getS3ImageWrapper(
          selectedSuite?.image,
          "initials",
          selectedSuite?.fullName
        ),
        opacity: 0.1,
      }}
    >
      <CardHeader
        sx={{ p: 3, flexGrow: 1 }}
        title={
          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary.contrastText"
            sx={{
              mt: 1,
              p: "5px 10px",

              textShadow: "0 1px #555",
            }}
          >
            {`${t(
              "SuiteDashboard.SuiteWelcomeMessage.greetingSuiteDashboard"
            )}`}
            , {userInfo.fullName}
          </Typography>
        }
        action={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: mobileMinWidth ? 2 : 1,
              m: 2,
            }}
          >
            <Button
              endIcon={<ArrowCircleRightOutlined />}
              variant="contained"
              onClick={() =>
                loadRoute("VIEW-DATAROOM", {
                  s3Key: `${selectedOrganizationId}/${selectedSuiteId}/`,
                })
              }
            >
              {mobileMinWidth ? "Goto Files" : null}
            </Button>
            <ScheduleMeetingDropdownButton
              isMobile={!mobileMinWidth}
              docketId={null}
            />

            {isSuiteManager && (
              <Button
                startIcon={<Analytics />}
                variant="contained"
                onClick={() =>
                  loadRoute("MANAGE-SUITE", { tabValue: "suiteanalytics" })
                }
              >
                {mobileMinWidth ? "Analytics" : null}
              </Button>
            )}
            {/*<Tooltip title="Refresh Dashboard Data">
                <IconButton disabled={isRefreshing} onClick={handleRefreshData} color="secondary">
                  {isRefreshing ? <MoreHoriz /> : <Cached />}
                </IconButton>
              </Tooltip>*/}
            {/*<AvatarGroup
              max={maxUsersViewCount}
              sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}
              onClick={toggleUsersViewCount}
            >
              {usersInSuite?.map((user, index) => (
                <Tooltip title={user?.fullName} key={index} arrow>
                  <Avatar key={index} alt={user?.fullName} src={getS3ImageWrapper(user?.image, 'person')} />
                </Tooltip>
              ))}
              </AvatarGroup>*/}
          </Box>
        }
      />
    </RootStyle>
  );
}
