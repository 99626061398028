// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { UserAvatar } from "blocks/atoms";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { format } from "date-fns";
import clip from "text-clipper";
import { Clear } from "@mui/icons-material";
import * as React from "react";

const SimpleTimelineItem = ({
  data,
  timelineItem,
  viewContext = "timeline",
}) => {
  const { message, avatar, userId, subHeader } = data;

  return (
    <TimelineItem
      sx={{
        mt: 0,

        p: 0,
        minHeight: 30,
      }}
    >
      <TimelineOppositeContent sx={{ flex: 0, minWidth: 80, mt: 0 }}>
        <Typography variant="caption" sx={{ fontSize: 8 }}>
          {format(
            new Date(timelineItem?.createdAt ?? Date.now()),
            "LLL dd, HH:mm"
          )}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot sx={{ p: 0, m: 0 }}>
          <Avatar sx={{ height: 15, width: 15 }} src={avatar} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ flex: 1, mt: 0 }}>
        <Typography variant="caption">
          {clip(timelineItem?.textContent, 120, {
            breakWords: true,
            maxLines: 3,
          })}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default SimpleTimelineItem;
