// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { useAppContext, executeApi } from "@app21/core";
import { useVideocallContext } from "providers/TrrystVideocallProvider";

export default function useInitiateWalkinCallInSuite({
  suiteId = null,
  orgId = null,
  docketId = null,
}) {
  const { userInfo, selectedOrganizationId, selectedSuiteId } = useAppContext();

  const { setChimeRoute } = useVideocallContext();

  const getWalkinCallRouteParams = async () => {
    let response;
    let meetingId;
    if (docketId) {
      response = await executeApi("FETCH-ACTIVE-GROUP-MEETING-IN-DOCKET", {
        listOfDocketIds: docketId,
        groupMeetings: "active-only",
        calledBy: userInfo?._id,
      });
      meetingId = response?.data[0]?._id;
    } else {
      response = await executeApi("FETCH-ACTIVE-GROUP-MEETING-IN-SUITE", {
        suiteId: suiteId,
        groupMeetings: "active-only",
        calledBy: userInfo?._id,
      });
      meetingId = response.data?._id;
    }
    const newRoutePath = `/user/organization/suite?orgId=${
      orgId ?? selectedOrganizationId
    }&suiteId=${suiteId ?? selectedSuiteId}&meetingId=${meetingId}`;

    setChimeRoute("base");

    return newRoutePath;
  };
  return { getWalkinCallRouteParams };
}
