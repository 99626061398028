import path from "path";
import { sort } from "fast-sort";
import _ from "lodash";

export const constructTrrystFileMap = (
  inputKeys,
  prefix,
  dockets,
  userInfo
) => {
  let filteredDockets =
    dockets?.filter((d) => d?.members?.some((m) => m === userInfo._id)) ?? [];

  let keys = [];
  inputKeys?.map((k) => {
    if (k.Key.includes("/dockets/")) {
      filteredDockets.map((d) => {
        if (k.Key.includes(d._id)) keys.push(k);
      });
    } else keys.push(k);
  });

  let folders =
    keys?.filter(
      (k) =>
        k.Key.startsWith(prefix) &&
        k.Key !== prefix &&
        k.Key.endsWith("/") &&
        k.Key.split("/").length - 1 === prefix.split("/").length
    ) ?? [];

  const files = keys?.filter(
    (k) =>
      k.Key.startsWith(prefix) &&
      !k.Key.endsWith("/") &&
      path.dirname(k.Key) + "/" === prefix
  );

  // if prefix is trying to list suite entries at top level, see if docket key needs to be inserted.
  let splitKeys = prefix.split("/").filter((e) => e !== "");
  splitKeys.length === 2 &&
    keys?.some((k) => k.Key.includes("/dockets/")) &&
    folders.push({ Key: `${splitKeys[0]}/${splitKeys[1]}/dockets/` });

  if (prefix.endsWith("dockets/")) {
    let listOfDockets = [];

    keys?.forEach((k) => {
      if (k.Key.includes("/dockets/")) {
        listOfDockets.push(k.Key.split("/")[3]);
      }
    });
    const listOfUniqueDockets = _.uniq(listOfDockets);
    listOfUniqueDockets.forEach((docketId) => {
      !keys.find((k) => k.Key === `${prefix}${docketId}/`) &&
        folders.push({ Key: prefix + docketId + "/" });
    });
  }

  const chonkyFiles = [];

  Array.isArray(files) &&
    files.forEach((file) => {
      if (file.Key !== prefix) {
        chonkyFiles.push({
          id: file.Key,
          name: path.basename(file.Key),
          modDate: file.updatedAt,
          size: file?.properties?.totalSizeBytes,
          metadata: file.metadata,
          fileType: file.Key.split(".").pop(),
          contentType: file.contentType,
          status: file.status,
          tags: file.tags,
          createdBy: file?.metadata?.createdBy,
          views: file?.metadata?.views,
          isPasswordProtected: file.isPasswordProtected,
          hasAiGenerated:
            Array.isArray(file.aiSummary) && file.aiSummary.length > 0,
          aiSummary: file.aiSummary,
          isStarred: file.isStarred,
          tags: file.tags,
        });
      }
    });

  Array.isArray(folders) &&
    folders.forEach((folder) => {
      let name = null;
      if (folder.Key.endsWith("dockets/")) name = "Breakouts";
      else if (path.dirname(folder.Key).endsWith("dockets")) {
        name = dockets?.find((d) => d._id === path.basename(folder.Key));
        if (name) {
          name = name.title;
        }
      } else name = path.basename(folder.Key);

      if (name) {
        chonkyFiles.push({
          id: folder.Key,
          metadata: folder.Metadata,
          modDate: folder.updatedAt,
          name: name,
          fileType: "folder",
          isDir: true,
          createdBy: folder?.metadata?.createdBy,
          tags: folder.tags,
          views: folder?.metadata?.views,
        });
      }
    });

  const sortedChonkyFiles = sort(chonkyFiles).desc((file) => file.modDate);

  return sortedChonkyFiles;
};
