// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function ToggleButton(theme) {
  const isLight = theme.palette.mode === "light";

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          // color: theme.palette.grey[500],
          color: theme.palette.text.secondary,
          // backgroundColor: theme.palette.background.paper,

          "&.Mui-selected": {
            //  color: theme.palette.primary.main,
            //  backgroundColor: theme.palette.background.paper,
            //  border: `solid 3px ${theme.palette.grey[500_32]}`,
          },
          "& .Mui-disabled": {
            color: theme.palette.grey[500_48],
          },
        },
      },
    },
  };
}
