// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography, List, Divider } from "@mui/material";
import { useUserMeetings } from "hooks/meet";
import { LoadingSpinner } from "blocks/atoms";
import { checkIfNull, useUserInfo } from "@app21/core";
import RsvpMeetingCard from "blocks/views/SuiteDashboard/foundations/AttentionActionCards/RsvpMeetingCard";
import UpcomingMeetingCard from "blocks/views/SuiteDashboard/foundations/AttentionActionCards/UpcomingMeetingCard";
import { format, isValid, differenceInMinutes } from "date-fns";
import _ from "lodash";
import toast from "react-hot-toast";

const ListUpcomingMeetings = () => {
  const {
    data: userInfo,
    status: userInfoStatus,
    apiError: userInfoApiError,
  } = useUserInfo();
  const {
    data: userMeetingsData,
    status: userMeetingsStatus,
    apiError: userMeetingsDataApiError,
  } = useUserMeetings(userInfo._id);

  const dataFetchError = userMeetingsDataApiError || userInfoApiError;
  const dataFetchLoading =
    userInfoStatus === "loading" || userInfoApiError === "loading";
  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const userInvitedToMeetingsList = React.useMemo(() => {
    if (
      userMeetingsStatus !== "success" ||
      checkIfNull(userMeetingsData) ||
      userMeetingsData?.status !== "success"
    ) {
      return [];
    }
    let filteredMeetings = [];
    let localUserMeetingsData = _.cloneDeep(userMeetingsData);
    localUserMeetingsData?.meetings?.forEach((meeting) => {
      if (
        isValid(new Date(meeting?.meetingJSON.start)) &&
        isValid(new Date(meeting?.meetingJSON.end)) &&
        new Date(meeting.meetingJSON.end) > new Date()
      ) {
        if (
          differenceInMinutes(new Date(meeting.meetingJSON.start), new Date()) <
            15 &&
          differenceInMinutes(new Date(meeting.meetingJSON.end), new Date()) > 0
        ) {
          filteredMeetings.push({ ...meeting, ...{ cardType: "upcoming" } });
        } else {
          filteredMeetings.push({ ...meeting, ...{ cardType: "rsvp" } });
        }
      }
    });
    return filteredMeetings;
  }, [userMeetingsStatus, userMeetingsData]);

  if (userMeetingsStatus === "loading" || userInfoStatus === "loading")
    return <LoadingSpinner />;
  else if (userInvitedToMeetingsList?.length > 0) {
    return (
      <Box sx={{ p: 1, my: 2 }}>
        <Typography variant="subtitle2" sx={{ my: 1 }}>
          Your Upcoming meetings
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, my: 1 }}>
          {userInvitedToMeetingsList.map((meeting, index) => {
            if (meeting.cardType === "upcoming") {
              return <UpcomingMeetingCard key={index} meeting={meeting} />;
            } else return <RsvpMeetingCard key={index} meeting={meeting} />;
          })}
        </Box>
      </Box>
    );
  } else return null;
};

export default ListUpcomingMeetings;
