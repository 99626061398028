// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import React from "react";
import { Button, Typography } from "@mui/material";
import { Layouts } from "blocks/views/Layouts";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";

export default function ShowLoginRequiredComponent({
  viewContext = "catchAll",
}) {
  const { loginWithRedirect } = useAuth0();
  const router = useRouter();
  const promptMessage =
    viewContext === "meeting"
      ? "Please authenticate with your email Id to proceed"
      : "You must be logged in to view this page";
  return (
    <Layouts
      viewContext="generic"
      showPathCrumbs={false}
      pageTitle="Login Needed"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh",
          maxWidth: "80vw",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          margin: "auto",
        }}
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {promptMessage}
        </Typography>
        <Button
          onClick={() => {
            typeof window !== "undefined" &&
              localStorage.setItem("savedRoute", router.asPath);
            loginWithRedirect({
              appState: { targetUrl: `${process.env.ROOT_URL}/auth0_callback` },
              screen_hint: "login",
              isPasswordlessLogin: viewContext === "meeting",
            });
          }}
          variant="contained"
          color="primary"
          sx={{ my: 2 }}
        >
          Login
        </Button>
      </div>
    </Layouts>
  );
}
