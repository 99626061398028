export function findOrgIdFromSuiteId(suiteId, orgsAndSuites = []) {
  let foundOrg = orgsAndSuites?.find((org) =>
    org?.suitesInOrg?.some((suite) => suite?._id === suiteId)
  );
  return foundOrg?._id;
}

export function getPersonalSuiteInfo(orgsAndSuites) {
  const persOrgObj = orgsAndSuites?.find((org) => org.orgType === "PERSONAL");
  return {
    orgId: persOrgObj?.orgId,
    suiteId: persOrgObj?.suitesInOrg && persOrgObj?.suitesInOrg[0]?._id,
  };
}
