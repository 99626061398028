import { useEffect, useState } from "react";

const NOT_BROWSER_ENV_ERROR =
  "useIsOnline only works in a browser environment.";

const notABrowserEnv = typeof window === "undefined";

const navigatorNotPresent = typeof navigator === "undefined";

const useIsOnline = () => {
  const [isOnline, setOnlineStatus] = useState(
    notABrowserEnv ? false : window.navigator.onLine
  );

  useEffect(() => {
    if (notABrowserEnv || navigatorNotPresent) return;

    const toggleOnlineStatus = () => setOnlineStatus(window.navigator.onLine);

    window.addEventListener("online", toggleOnlineStatus);
    window.addEventListener("offline", toggleOnlineStatus);

    return () => {
      window.removeEventListener("online", toggleOnlineStatus);
      window.removeEventListener("offline", toggleOnlineStatus);
    };
  }, [isOnline]);

  if (notABrowserEnv || navigatorNotPresent) {
    return {
      error: NOT_BROWSER_ENV_ERROR,
      isOnline: false,
      isOffline: false,
    };
  }

  return {
    error: null,
    isOffline: !isOnline,
    isOnline,
  };
};

export default useIsOnline;
