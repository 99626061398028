import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Stack,
  Typography,
  Box,
  IconButton,
  CardActionArea,
  Tooltip,
} from "@mui/material";
import { getFileIcon, UserAvatar } from "blocks/atoms";
import { EyeIcon } from "blocks/atoms/trrysticons";
import { formatDistance } from "date-fns";
import clip from "text-clipper";
import { useIsMobileBreakpoint } from "hooks";
import { formatBytes } from "utils/fileHelperUtils";

export default function GridViewFileCard({
  fileObj,
  fileMap = [],
  isSuiteManager = false,
  handleFileView = () => null,
}) {
  const isMobile = useIsMobileBreakpoint();

  const TooltipWrapper = ({ children }) => {
    if (fileObj.isDeleted)
      return (
        <Tooltip placement="top" title="File Deleted">
          {children}
        </Tooltip>
      );
    return children;
  };
  return (
    <TooltipWrapper>
      <Card
        sx={{
          m: 1,
          width: isMobile ? 150 : 190,
          height: isMobile ? 130 : 150,
          boxShadow: (theme) =>
            !fileObj.isDeleted ? `${theme.customShadows.z16}` : "none",
          border: (theme) =>
            fileObj.isDeleted
              ? `1px solid ${theme.palette.error.lighter}`
              : "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isSuiteManager ? (
          <CardHeader
            {...(isSuiteManager
              ? {
                  avatar: <UserAvatar userId={fileObj.uploadedBy} size={20} />,
                  action: (
                    <Stack direction={"row"} spacing={1} sx={{ mt: 1.5 }}>
                      <EyeIcon />
                      <Typography variant="caption">
                        {fileMap && fileMap.has(fileObj.key)
                          ? fileMap.get(fileObj.key).totalViews
                          : "0"}
                      </Typography>
                    </Stack>
                  ),
                }
              : {})}
            sx={{ alignItems: "center", minHeight: 35 }}
          />
        ) : null}
        <CardActionArea
          disabled={fileObj.isDeleted}
          onClick={() => handleFileView(fileObj)}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              minHeight: 75,
              height: "100%",
              width: "100%",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              {getFileIcon({
                fileExt: fileObj?.fileType || "file",
                fontSize: "large",
              })}
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                p: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",

                flexGrow: 1,
                height: "100%",
                width: "100%",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {clip(fileObj.fileName, isMobile ? 18 : 40, {
                breakWords: true,
              })}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            minHeight: 25,
          }}
        >
          <Typography variant="caption">
            {formatDistance(new Date(fileObj.lastUpdated ?? null), new Date(), {
              addSuffix: false,
            })}
          </Typography>
          <Typography variant="caption">
            {formatBytes(fileObj.fileSize ?? 0)}
          </Typography>
        </CardActions>
      </Card>
    </TooltipWrapper>
  );
}
