// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import {
  Box,
  Skeleton,
  Grid,
  Divider,
  useMediaQuery,
  Card,
  CardMedia,
  CardHeader,
  Stack,
  CardContent,
  ListItem,
  ListItemAvatar,
  Paper,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useIsMobileBreakpoint } from "hooks";

const RootStyle = styled(Card)(({ theme, ownerState }) => {
  const { backgroundPicture } = ownerState;
  return {
    boxShadow: "none",
    textAlign: "center",
    flexGrow: 1,
    height: "100%",
    display: "flex",
    // backgroundColor: theme.palette.primary.light,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: { height: "100%" },
    "&:after": {
      content: "''",

      flexGrow: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  };
});
export default function ExecutiveSuiteDashboardViewLoading() {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileMinWidth = useMediaQuery("(min-width:1200px)");
  const isMobile = useIsMobileBreakpoint();
  const suiteImageHeight = isMobile ? 100 : 170;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <>
          {isMedium && (
            <Grid item md={4}>
              <Skeleton
                variant="rectangular"
                height="33%"
                sx={{
                  borderRadius: `14px 14px 0px 0px`,
                  backgroundColor: "#ffffff",
                }}
              />
              <Skeleton
                variant="rectangular"
                height="33%"
                sx={{ backgroundColor: "#ffffff" }}
              />
              <Skeleton
                variant="rectangular"
                height="33%"
                sx={{
                  borderRadius: `0px 0px 14px 14px`,
                  backgroundColor: "#ffffff",
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <RootStyle ownerState={{}} sx={{ height: 300 }}>
                  <Skeleton
                    sx={{
                      // height: suiteImageHeight,
                      objectFit: "contain",
                      maxWidth: suiteImageHeight,
                      width: 200,
                      height: 300,
                      backgroundColor: "#ffffff",
                      mt: -15,
                    }}
                  />
                  <CardHeader
                    sx={{ p: 1, flexGrow: 1 }}
                    title={
                      <Skeleton
                        variant="text"
                        sx={{
                          backgroundColor: "#ffffff",
                          p: "5px 10px",
                          mt: -5,
                        }}
                      />
                    }
                    subheader={
                      <Stack direction="row" spacing={mobileMinWidth ? 2 : 1}>
                        <Skeleton
                          width={150}
                          height={80}
                          sx={{
                            borderRadius: `10px 10px 10px 10px`,
                            backgroundColor: "#ffffff",
                          }}
                        />
                        <Skeleton
                          width={150}
                          height={80}
                          sx={{
                            borderRadius: `10px 10px 10px 10px`,
                            backgroundColor: "#ffffff",
                            border: "0.5px solid #cfcfcf",
                          }}
                        />
                        <Skeleton
                          width={150}
                          height={80}
                          sx={{
                            borderRadius: `10px 10px 10px 10px`,
                            backgroundColor: "#ffffff",
                            border: "0.5px solid #cfcfcf",
                          }}
                        />
                      </Stack>
                    }
                  />
                </RootStyle>
                <RootStyle ownerState={{}} sx={{ mt: 3 }}>
                  <Skeleton
                    width="100%"
                    height={70}
                    sx={{
                      borderRadius: `10px 10px 10px 10px`,
                      backgroundColor: "#ffffff",
                    }}
                  />
                </RootStyle>
              </Box>
            </Box>
          </Grid>
        </>
      </Grid>
    </Box>
  );
}
