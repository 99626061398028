// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import { LoadingSpinner, NoCookiesComponent } from "blocks/atoms";
import { Auth0Provider } from "@auth0/auth0-react";
import { Analytics } from "@vercel/analytics/react";

import { HelmetProvider } from "react-helmet-async";
// Minimal UI theme
import ThemeConfig from "styles/theme/ThemeConfig";
import ThemePrimaryColor from "styles/ThemePrimaryColor";
import createEmotionCache from "styles/createEmotionCache";
import GlobalStyles from "styles/theme/globalStyles";
import { /*AnalyticsWrapper,*/ AuthRedirectWrapper } from "wrappers";
// css imports
//Below is workaround for fullcalendar react...
import "@fullcalendar/common/main.css"; // @fullcalendar/react imports @fullcalendar/common
import "@fullcalendar/daygrid/main.css"; // @fullcalendar/timegrid imports @fullcalendar/daygrid
import "@fullcalendar/timegrid/main.css"; // @fullcalendar/timegrid is a direct import
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/list/main.css";
// (and @fullcalendar/interaction has no stylesheet)
import "styles/css/React-Dates.css";
//for video-react and pdf viewer components in dataroom
import "node_modules/video-react/dist/video-react.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
//import "emoji-mart/css/emoji-mart.css";

import "styles/css/ReactUsageBar.css";
//Below all self hosted tiny mce assets

//import 'tinymce/skins/ui/oxide/skin.min.css';
//import 'tinymce/skins/ui/oxide/content.min.css';
//import 'public/assets/libs/tinymce/skins/content/default/content.min.css';
//import 'tinymce/skins/content/default/content.min.css';
import "styles/reactKeyedFileBrowser.css";
import "styles/splitpane.css";
// For react-slick carousel
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "styles/css/ReactSlick.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Below for google analytics
import { GoogleAnalytics, usePagesViews, event } from "nextjs-google-analytics";
//import { pageview } from '@app21/core';

//Below for events and performance handling
import { eventAndPerformanceHandler } from "@app21/core";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "blocks/views/GenericFunctions/ErrorFallback";

//From Devias-kit
import CookiesNotification from "blocks/atoms/CookiesNotification";
import "styles/perfectscrollbarstyles.css"; //for perfect scrollbar in deviaskit
import "simplebar/src/simplebar.css";

//import { AccessCheckWrapper, LocalPubNubWrapper } from 'wrappers';

import SettingsProvider from "providers/SettingsProvider";

import { resetStorage } from "utils";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const auth0Domain = process.env.AUTH0_DOMAIN;
const auth0ClientId = process.env.AUTH0_CLIENT_ID;

// tracking user route changes

//Below for measuring page performance and other metrics (webvitals)
export function reportWebVitals(metric) {
  eventAndPerformanceHandler(metric);
}

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [cookiesCompliant, setCookiesCompliant] = React.useState(null);
  const router = useRouter();
  // const logger = new ConsoleLogger("trryst", LogLevel.ERROR);

  React.useEffect(() => {
    let cookieEnabled =
      typeof window !== "undefined" && navigator.cookieEnabled;
    if (cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document?.cookie?.indexOf("testcookie") !== -1;
      setCookiesCompliant(cookieEnabled);
    } else if (typeof window !== "undefined") {
      setCookiesCompliant(false);
    } else if (typeof window === "undefined") {
      setCookiesCompliant(true);
    }
  }, [router]);

  //Below hookto record google analytics pageviews events
  usePagesViews(); //hide if individual pageview tracking not needed

  {
    /*useEffect(() => {
    if (process.env.STAGE === 'prod') {
      const handleRouteChange = url => {
        pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/
  } //Done with google analytics stuff

  {
    /*  useEffect(() => {
    // Remove the server-side injected CSS for material-ui SSR.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
*/
  }

  {
    /*;
  } else*/
  }
  const AuthorizationProvider =
    (typeof window !== undefined) & cookiesCompliant ? Auth0Provider : Fragment;
  const authProviderProps =
    typeof window !== undefined && cookiesCompliant
      ? {
          domain: `${auth0Domain}`,
          clientId: `${auth0ClientId}`,
          redirectUri: `${process.env.ROOT_URL}/auth0_callback`,
          cacheLocation: "localstorage",
        }
      : {};

  if (cookiesCompliant === null) {
    return <LoadingSpinner flowScreenFlag={true} variant="exp" />;
  } else if (typeof window !== "undefined" && !navigator.cookieEnabled) {
    return <NoCookiesComponent />;
  } else
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          minWidth: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CacheProvider value={emotionCache}>
          <GoogleAnalytics strategy="lazyOnload" />
          <Analytics />
          <HelmetProvider>
            <Head>
              <title>Trryst</title>
              <meta
                name="viewport"
                content="minimum-scale=1, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0,  shrink-to-fit=no"
              />
            </Head>
            <SettingsProvider>
              <ThemeConfig>
                <ThemePrimaryColor>
                  {/* <Suspense fallback={<LoadingSpinner />}> */}
                  <AuthorizationProvider {...authProviderProps}>
                    <GlobalStyles />
                    {/* <AnalyticsWrapper /> */}
                    <CookiesNotification />
                    <AuthRedirectWrapper {...props}>
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={() => {
                          resetStorage();
                          router.push("/");
                          router.reload();
                        }}
                        onError={(error, info) => {
                          console.log("error", error, "info", info);
                          event("user_FE_error", {
                            category: "User FE Error",
                            label: error?.message + info?.componentStack,
                          });
                        }}
                      >
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </AuthRedirectWrapper>
                  </AuthorizationProvider>
                </ThemePrimaryColor>
              </ThemeConfig>
            </SettingsProvider>
          </HelmetProvider>
        </CacheProvider>
      </div>
    );
};

export default MyApp;
