import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import { Timeline, ArrowDropDown } from "@mui/icons-material";

import { useIsMobileBreakpoint } from "hooks";

import { useAppContext, useSuite } from "@app21/core";

import { MongodbChartProvider } from "providers/mongodb/Chart";
import MongodbChartRender from "blocks/atoms/Charts/MongodbChartRender";

import chartConfig from "blocks/atoms/constants/mongodbChart";

const DataSuiteUsageInsightsPanel = () => {
  const isMobile = useIsMobileBreakpoint(800);
  const { selectedSuiteId } = useAppContext();
  const { data: selectedSuiteInfo } = useSuite(selectedSuiteId);

  const [analyticsView, setAnalyticsView] = React.useState(
    localStorage.getItem("preferences:analytics-view") || "analytics:this-month"
  );
  const [selectedTimelineMenuIndex, setSelectedTimelineMenuIndex] =
    React.useState(1);

  const [timelineMenuOpen, setTimelineMenuOpen] = React.useState(false);

  const timelineAnchorRef = React.useRef(null);

  const timelineOptions = React.useMemo(
    () => [
      { value: "analytics:today", label: "Today" },
      { value: "analytics:this-month", label: "This Month" },
      { value: "analytics:last-month", label: "Last Month" },
      { value: "analytics:last-three-months", label: "Last 3 Months" },
      { value: "analytics:all", label: "Till date" },
    ],
    []
  );

  const [selectedDurationPeriod, setSelectedDurationPeriod] = React.useState(
    localStorage.getItem("preferences:analytics-view") || "analytics:this-month"
  );

  const foundIndex = React.useMemo(
    () =>
      timelineOptions.findIndex(
        (item) => item.value === selectedDurationPeriod
      ),
    [selectedDurationPeriod, timelineOptions]
  );

  const [fromDate, toDate] = React.useMemo(() => {
    localStorage.setItem("preferences:analytics-view", selectedDurationPeriod);
    switch (selectedDurationPeriod) {
      case "analytics:all":
        return [undefined, undefined];

      case "analytics:today": {
        const currentDate = new Date();
        const endDate = new Date();

        currentDate.setHours(0, 0, 0);
        endDate.setHours(59, 59, 59);

        return [currentDate, endDate];
      }

      case "analytics:this-month": {
        const currentDate = new Date();
        currentDate.setDate(1);

        return [currentDate, undefined];
      }

      case "analytics:last-month": {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setDate(1);
        startDate.setHours(0, 0, 0);

        const endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );

        return [startDate, endDate];
      }

      case "analytics:last-three-months": {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 3);
        startDate.setDate(1);

        return [startDate, undefined];
      }

      default: {
        const currentDate = new Date();
        const endDate = new Date();

        return [currentDate, endDate];
      }
    }
  }, [selectedDurationPeriod]);

  const handleAnalyticsViewChange = React.useCallback((event, val) => {
    if (val) setAnalyticsView(val);
  }, []);

  const handleClose = React.useCallback((event) => {
    if (
      timelineAnchorRef.current &&
      timelineAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setTimelineMenuOpen(false);
  }, []);

  const handleMenuItemClick = React.useCallback(
    (event, index) => {
      setSelectedTimelineMenuIndex(index);
      setTimelineMenuOpen(false);
      setSelectedDurationPeriod(timelineOptions[index].value);
    },
    [timelineOptions]
  );

  // Callbacks
  const handleToggle = React.useCallback(() => {
    setTimelineMenuOpen((prevOpen) => !prevOpen);
  }, []);

  if (!selectedSuiteInfo) return <p>Loading...</p>;
  {
    /* TODO: Add a loading spinner */
  }

  return (
    <MongodbChartProvider>
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: 5,
          }}
        >
          <ButtonGroup
            ref={timelineAnchorRef}
            variant="contained"
            size="small"
            aria-label="Choose timeline"
            sx={{ boxShadow: 0 }}
          >
            <Button onClick={handleToggle} startIcon={<Timeline />}>
              {timelineOptions[selectedTimelineMenuIndex].label}
            </Button>
            <Button size="small" onClick={handleToggle}>
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper
            open={timelineMenuOpen}
            anchorEl={timelineAnchorRef.current}
            role={undefined}
            transition
            disablePortal
            sx={{ zIndex: 20 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {timelineOptions.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          selected={index === selectedTimelineMenuIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
          gap: 3,
          my: "20px",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} md={4}>
            <MongodbChartRender
              chartId={chartConfig.FILE.totalReadDuration}
              style={{ height: "100px" }}
              filters={{
                dateFilter: {
                  render: false,
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <MongodbChartRender
              chartId={chartConfig.FILE.totalOperations}
              style={{ height: "100px" }}
              filters={{
                dateFilter: {
                  render: false,
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <MongodbChartRender
              chartId={chartConfig.FILE.totalAISummaryGenerated}
              style={{ height: "100px" }}
              filters={{
                dateFilter: {
                  render: false,
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
          gap: 3,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={8} md={8}>
            <MongodbChartRender
              chartId={chartConfig.FILE.allFileAnalyticsNumberOfViews}
              style={{ height: "40vh" }}
              filters={{
                dateFilter: {
                  render: true,
                  value: {
                    from: fromDate,
                    to: toDate,
                  },
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <MongodbChartRender
              chartId={chartConfig.FILE.viewsByFileType}
              style={{ height: "40vh" }}
              filters={{
                dateFilter: {
                  render: true,
                  value: {
                    from: fromDate,
                    to: toDate,
                  },
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ mt: isMobile && -0.5 }}
          alignItems="center"
        >
          <Grid item xs={12} lg={8} md={8}>
            <MongodbChartRender
              chartId={chartConfig.FILE.allFileAnalyticsMinutesViewed}
              style={{ height: "40vh" }}
              filters={{
                dateFilter: {
                  render: true,
                  value: {
                    from: fromDate,
                    to: toDate,
                  },
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <MongodbChartRender
              chartId={chartConfig.FILE.crudAnalytics}
              style={{ height: "40vh" }}
              filters={{
                dateFilter: {
                  render: true,
                  value: {
                    from: fromDate,
                    to: toDate,
                  },
                },
                suiteFilter: {
                  render: false,
                  value: [selectedSuiteInfo],
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </MongodbChartProvider>
  );
};

export default DataSuiteUsageInsightsPanel;
