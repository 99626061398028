// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import NeedingAttentionItems from "blocks/views/SuiteDashboard/foundations/NeedingAttentionItems";
import RecentActivitiesList from "blocks/views/SuiteDashboard/foundations/RecentActivitiesList";
import { useIntl } from "react-intl";
import EngagementPanel from "blocks/views/SuiteDashboard/foundations/EngagementPanel";
import {
  SharedPersonalBreakoutsViewPanel,
  PersonalSuiteWelcomeCard,
} from "blocks/views/SuiteDashboard/foundations";
import { useAppContext, useHandleRouterPush } from "@app21/core";
import { BackTitleWrapperPaper, PrintUserInfo } from "blocks/atoms";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const PersonalSuiteDashboardView = (props) => {
  const { isMobile, needingAttentionItemsFlag, selectedSuite } =
    useDashboardViewsDataContext();
  const { userInfo } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { loadRoute } = useHandleRouterPush();
  const isOwnPersonalSuite = selectedSuite.createdBy === userInfo._id;

  React.useEffect(() => {
    if (!isOwnPersonalSuite) {
      loadRoute("LIST-DOCKETS", {});
    }
  }, []);

  if (!isOwnPersonalSuite) {
    return (
      <BackTitleWrapperPaper
        data-testid="SimpleSurveyBuilder"
        title={
          <Typography variant="h5" sx={{ flexGrow: 1, textAlign: "center" }}>
            Shared Breakouts with you in{" "}
            <PrintUserInfo userId={selectedSuite.createdBy} />
            's Suite
          </Typography>
        }
      >
        Redirecting....
      </BackTitleWrapperPaper>
    );
  }
  return (
    <Box
      sx={{
        pt: 1,
        pl: 0,
        pb: 2,
        pr: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Container
        maxWidth={"lg"}
        sx={{ pl: !isMobile && 1, pr: !isMobile && 1 }}
      >
        <Grid container spacing={isMobile ? 1 : 2} alignItems="stretch">
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <PersonalSuiteWelcomeCard />
          </Grid>

          <Grid item container spacing={2} xs={12} sm={6} md={8}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SharedPersonalBreakoutsViewPanel />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <EngagementPanel suiteType="PERSONAL" />
            </Grid>

            {needingAttentionItemsFlag && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pt: 1,
                }}
              >
                <NeedingAttentionItems />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RecentActivitiesList />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PersonalSuiteDashboardView;
