// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useRef, useState } from "react";
import {
  Box,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Close } from "@mui/icons-material";
import { NotificationIcon } from "blocks/atoms/trrysticons";
import NotificationsWall from "blocks/views/Notifications/features/NotificationsWall";
import { useNotificationsCount, useAppContext } from "@app21/core";
import { useIsMobileBreakpoint } from "hooks";
import { menuActionTestIds } from "blocks/atoms/constants";

const NotificationsPopup = ({
  selectedSuiteId,
  iconSize = "medium",
  viewContext,
  ...rest
}) => {
  const isMobile = useIsMobileBreakpoint();
  const { selectedOrganizationId, checkAccessRules } = useAppContext();
  const notificationsCount = useNotificationsCount({
    selectedOrganizationId,
    selectedSuiteId,
  });
  let contextNotificationsCount = notificationsCount[viewContext] ?? 0;

  const { accessFlag: canAccessNotifications } = checkAccessRules({
    entity: "MENU",
    action: "SHOW-NOTIFICATIONS-MENU",
    featureName: "NOTIFICATIONS",
    isInvitee: true,
  });

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const maxWidgetHeight = theme.breakpoints.up("md") ? 600 : "70vh";
  if (canAccessNotifications) {
    return (
      <>
        <Tooltip title="Notifications" arrow>
          <IconButton
            data-testid={menuActionTestIds.notificationPopupButtonTestId}
            size={iconSize}
            ref={ref}
            onClick={handleOpen}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            <Badge
              badgeContent={contextNotificationsCount}
              invisible={!contextNotificationsCount}
              variant="dot"
              overlap="circular"
              color="warning"
            >
              <SvgIcon
                fontSize="inherit"
                sx={{
                  color: contextNotificationsCount ? "secondary" : "default",
                }}
              >
                <NotificationIcon fontSize="inherit" />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPopover-paper": {
              width: 350,
              padding: 0,
              [theme.breakpoints.up("lg")]: {
                width: 480,
              },
            },
          }}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color="textPrimary">
              {(viewContext === "suite" ? "Suite " : "") + "Updates"}
            </Typography>
            {isMobile && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<Close fontSize="inherit" />}
                onClick={handleClose}
              >
                Close
              </Button>
            )}
          </Box>
          <Box
            sx={{
              maxHeight: maxWidgetHeight,
              m: 1,
              overflowX: "hidden",
            }}
          >
            <NotificationsWall
              viewContext={viewContext}
              maxWidgetHeight={maxWidgetHeight}
              handleClose={handleClose}
              viewMode="popup"
              {...rest}
            />
          </Box>
        </Popover>
      </>
    );
  } else return null;
};

export default NotificationsPopup;
