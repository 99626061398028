// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { UserAvatar } from "blocks/atoms";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { format } from "date-fns";
import clip from "text-clipper";
import { Clear } from "@mui/icons-material";
import * as React from "react";
import { useHandleNotificationsActions } from "@app21/core";

const SimpleNotificationCard = ({
  data,
  notification,
  viewContext = "list",
}) => {
  const { handleNotificationsActions } = useHandleNotificationsActions();
  const { message, avatar, userId, subHeader } = data;
  if (viewContext === "timeline")
    return (
      <TimelineItem
        sx={{
          mt: 0,

          p: 0,
          minHeight: 30,
        }}
      >
        <TimelineOppositeContent sx={{ flex: 0, minWidth: 80, mt: 0 }}>
          <Typography variant="caption" sx={{ fontSize: 8 }}>
            {format(new Date(notification.createdAt), "LLL dd, HH:mm")}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ p: 0, m: 0 }}>
            <Avatar sx={{ height: 15, width: 15 }} src={avatar} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ flex: 1, mt: 0 }}>
          <Typography variant="caption">
            {clip(message, 120, { breakWords: true, maxLines: 3 })}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  else
    return (
      <ListItem sx={{ mt: 0.5, borderRadius: 0, pr: 3 }}>
        {(userId || avatar) && (
          <ListItemAvatar sx={{ width: 25 }}>
            {userId ? (
              <UserAvatar size={25} userId={userId} />
            ) : (
              <Avatar sx={{ height: 25, width: 25 }} src={avatar} />
            )}
          </ListItemAvatar>
        )}
        <ListItemText
          sx={{
            marginLeft: (theme) => theme.spacing(1),
            overflowWrap: "break-word",
            hyphens: "auto",
            wordWrap: "break-word",
            wordbreak: "break-all",
            wordBreak: "break-word",
          }}
          primary={clip(message, 120, { breakWords: true, maxLines: 3 })}
          secondary={subHeader && format(new Date(subHeader), "do MMM")}
        />
        <ListItemSecondaryAction sx={{ right: (theme) => theme.spacing(1) }}>
          <IconButton
            size="small"
            variant="outlined"
            onClick={async () => {
              await handleNotificationsActions({
                action: "CLEAR-A-NOTIFICATION",
                notificationId: notification._id,
              });
            }}
          >
            <Clear fontSize="inherit" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
};

export default SimpleNotificationCard;
