import {
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistance,
  subHours,
  intervalToDuration,
} from "date-fns";
import { checkIfNull } from "@app21/core";

export const convertPubnubTime = (time) => {
  if (time) return Math.round(parseInt(time) / 10000);
  else return 0;
};

export const getHumanisedTimeFormat = (date) => {
  return formatDistance(date, new Date(), {
    addSuffix: true,
  });
};

export const formatChatTimeMessage = (timeStamp) => {
  if (!checkIfNull(timeStamp)) {
    let timediff = differenceInMinutes(new Date(), timeStamp, {
      roundingMethod: "trunc",
    });
    if (timediff > 60) {
      return format(new Date(timeStamp), "hh:mm");
    }
    if (timediff < 1) {
      return `${differenceInSeconds(new Date(), timeStamp, {
        roundingMethod: "round",
      })}s`;
    } else return `${timediff}m`;
  }
};

export const showTimeDuration = (
  startDate = new Date(),
  endDate = new Date()
) => {
  let duration = intervalToDuration({ start: startDate, end: endDate });
  let durationString = "";
  if (duration.years > 0) {
    durationString += `${duration.years}y `;
  }
  if (duration.months > 0) {
    durationString += `${duration.months}mo `;
  }
  if (duration.days > 0) {
    durationString += `${duration.days}d `;
  }
  if (duration.hours > 0) {
    durationString += `${duration.hours}h `;
  }
  if (duration.minutes > 0) {
    durationString += `${duration.minutes}m `;
  }
  if (duration.seconds > 0) {
    durationString += `${duration.seconds}s`;
  }
  return durationString;
};
