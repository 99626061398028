// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[0],
          borderRadius: 10,
          minHeight: 44,
        },
        flexContainer: {
          display: "inline-flex",
          position: "relative",
          zIndex: 1,
        },
        scroller: {
          "@media (min-width: 600px)": {
            padding: "0 8px",
          },
        },
        // indicator: {
        //   top: 3,
        //   bottom: 3,
        //   right: 3,
        //   height: "auto",
        //   background: "none",
        //   "&:after": {
        //     content: '""',
        //     display: "block",
        //     position: "absolute",
        //     top: 0,
        //     left: 4,
        //     right: 4,
        //     bottom: 0,
        //     borderRadius: 8,
        //     backgroundColor: theme.palette.secondary.main,
        //     boxShadow: `0 4px 12px 0 ${theme.customShadows.dark}`,
        //   },
        // },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // <<<<<<< HEAD
          //           '&:hover': {
          //             opacity: 1,
          //             // outline: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
          //           },
          //           minHeight: 44,
          //           // minWidth: 96,
          //           '@media (min-width: 600px)': {
          //             minWidth: 120,
          //           },
          //         },
          //         wrapper: {
          //           // zIndex: 2,
          //           // marginTop: spacing(0.5),
          //           color: theme.palette.text.primary,
          //           textTransform: 'initial',
          // =======
          padding: 0,
          fontWeight: theme.typography.fontWeightMedium,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          "&.Mui-selected": {
            color: theme.palette.text.primary,
          },
          "&:not(:last-of-type)": {
            marginRight: theme.spacing(5),
          },
          "@media (min-width: 600px)": {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,

          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapper: {
          whiteSpace: "nowrap",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
          // >>>>>>> v4dev
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },
  };
}
