// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

const shape = {
  borderRadius: 2,
  borderRadiusSm: 3,
  borderRadiusMd: 4,
  borderRadiusXs: 1,
  borderRadiusMenu: 16,
  borderRadiusButton: 25,
};

export default shape;
