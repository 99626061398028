// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Grid, Container } from "@mui/material";
import SuiteDashboardWelcomeCard from "blocks/views/SuiteDashboard/foundations/SuiteDashboardWelcomeCard";
import FeaturedSnippets from "blocks/views/SuiteDashboard/foundations/FeaturedSnippets";
import NeedingAttentionItems from "blocks/views/SuiteDashboard/foundations/NeedingAttentionItems";
import RecentActivitiesList from "blocks/views/SuiteDashboard/foundations/RecentActivitiesList";
import { useIntl } from "react-intl";
import MembersPanel from "blocks/views/SuiteDashboard/foundations/MembersPanel";
import EngagementPanel from "blocks/views/SuiteDashboard/foundations/EngagementPanel";
import { SuiteCreditDetailsPanel } from "blocks/modules/Subscriptions";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const BoardroomDashboardView = (props) => {
  const {
    isMobile,
    mobileMinWidth,
    probabilisticSnippetsPanelFlag,
    needingAttentionItemsFlag,
    isPersonalSuite,
    usersInSuite,
  } = useDashboardViewsDataContext();

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <Box
      sx={{
        pt: 1,
        // pl: 1,
        pb: 2,
        weight: "100%",
        height: "100%",
      }}
    >
      <Container maxWidth={"lg"} sx={{ px: isMobile ? 0 : 1 }}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid
            item
            xs={mobileMinWidth && probabilisticSnippetsPanelFlag ? 7 : 12}
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <SuiteDashboardWelcomeCard />
            <MembersPanel usersInSuite={usersInSuite} sx={{ mt: 1 }} />
          </Grid>
          {/* {isSuiteManager ? (
            <Grid
              item
              xs={mobileMinWidth ? 5 : 12}
              sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <SuiteCreditDetailsPanel />
            </Grid>
          ) :  */}
          {mobileMinWidth && probabilisticSnippetsPanelFlag ? (
            <Grid
              item
              xs={5}
              sx={{
                height: 190,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                pt: 0,
              }}
            >
              <FeaturedSnippets />
            </Grid>
          ) : null}
          <Grid
            item
            xs={mobileMinWidth && !isPersonalSuite ? 12 : 12}
            sx={{ display: "flex", flexDirection: "column", mt: -1 }}
            xl={12}
          >
            <EngagementPanel />
          </Grid>
          {needingAttentionItemsFlag && (
            <Grid
              item
              xs={mobileMinWidth ? 6 : 12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", pt: 1 }}
            >
              <NeedingAttentionItems />
            </Grid>
          )}
          <Grid
            item
            xs={mobileMinWidth ? (needingAttentionItemsFlag ? 6 : 12) : 12}
            md={needingAttentionItemsFlag ? 6 : 12}
            sx={{ display: "flex", flexDirection: "column", mt: -1 }}
          >
            <RecentActivitiesList />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BoardroomDashboardView;
