// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Card,
  CardHeader,
  Avatar,
  Typography,
  Menu,
  Chip,
  ListSubheader,
  Divider,
  MenuItem,
  IconButton,
  Box,
  ListItemSecondaryAction,
} from "@mui/material";
import { MenuPopover } from "blocks/atoms";
import { useTheme } from "@mui/material/styles";
import {
  useAppContext,
  checkIfNull,
  getS3ImageWrapper,
  useOrgsAndSuitesForUser,
  useSuite,
  useHandleRouterPush,
} from "@app21/core";
import { useRouter } from "next/router";
import * as React from "react";
import {
  usePopupState,
  bindTrigger,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { SwapVert, Done } from "@mui/icons-material";
import clip from "text-clipper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useIsMobileBreakpoint } from "hooks";
import { getSuiteAndOrganizationLabel } from "utils";
import toast from "react-hot-toast";

const SuiteSwitchButton = ({
  viewOption,
  handleSuiteSwitchClick,
  label = "Change Suite",
  showSameSuite = false,
}) => {
  const theme = useTheme();
  const { userInfo } = useAppContext();
  const { selectedSuiteId } = useAppContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo?._id);

  const dataFetchError = selectedSuiteApiError || orgsAndSuitesApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" || orgsAndSuitesStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { loadRoute } = useHandleRouterPush();
  const filteredOrgSet =
    orgsAndSuites?.filter(
      (org) =>
        !checkIfNull(org.suitesInOrg) &&
        !(org.orgType === "PERSONAL" && org.createdBy !== userInfo._id)
    ) ?? [];
  const isMobile = useIsMobileBreakpoint();

  const ChangeSuitesChip = () => {
    const suiteSelectPopupState = usePopupState({
      variant: "popover",
      popupId: "suiteSelectMenu",
    });

    return (
      <div>
        {viewOption === "chipOnly" ? (
          <IconButton
            size="small"
            {...bindToggle(suiteSelectPopupState)}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              bindToggle(suiteSelectPopupState).onClick(event);
            }}
          >
            <SwapVert
              fontSize="inherit"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          </IconButton>
        ) : (
          <Chip
            label={<Typography variant="caption">{label}</Typography>}
            clickable
            disabled={null} //Boolean(meetingId)
            size="small"
            variant="outlined"
            sx={{
              zIndex: 9000,
              "&:hover": {
                backgroundColor: `${theme.palette.secondary.light} !important`,
              },
            }}
            onClick={(event) => event.stopPropagation()}
            onDelete={(event) => {
              event.stopPropagation();
            }}
            deleteIcon={<SwapVert />}
            {...bindToggle(suiteSelectPopupState)}
          />
        )}
        <MenuPopover
          {...bindMenu(suiteSelectPopupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ mt: 1 }}
          arrow="top-center"
        >
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ maxHeight: "75vh" }}
          >
            {filteredOrgSet?.map((org, index) => {
              return (
                <Box
                  key={index}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {index !== 0 && (
                    <Divider
                      sx={{ color: "primary.light", mt: theme.spacing(1) }}
                    />
                  )}
                  {org?.orgType !== "PERSONAL" && (
                    <ListSubheader
                      color="primary"
                      disableSticky
                      sx={{ fontWeight: "bold", lineHeight: 1.1, my: 2 }}
                    >
                      {getSuiteAndOrganizationLabel(org)}
                    </ListSubheader>
                  )}

                  {org?.suitesInOrg?.map((suite, suiteindex) => {
                    const isPersonalSuite =
                      suite?.suiteType?.includes("PERSONAL");

                    return (
                      <MenuItem
                        key={suiteindex}
                        disabled={
                          showSameSuite ? false : suite?._id === selectedSuiteId
                        }
                        selected={suite?._id === selectedSuiteId}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (handleSuiteSwitchClick) {
                            handleSuiteSwitchClick(suite?._id);
                          } else
                            loadRoute("VIEW-SUITE", {
                              orgId: org?._id,
                              suiteId: suite?._id,
                            });

                          suiteSelectPopupState.close();
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color={isPersonalSuite ? "primary" : "textPrimary"}
                          sx={{
                            mt: isPersonalSuite ? 2 : 0,
                            fontWeight: isPersonalSuite ? "bold" : "normal",
                          }}
                        >
                          {getSuiteAndOrganizationLabel(suite)}
                        </Typography>
                        {suite?._id === selectedSuiteId && (
                          <ListItemSecondaryAction>
                            <Done fontSize="small" />
                          </ListItemSecondaryAction>
                        )}
                      </MenuItem>
                    );
                  })}
                </Box>
              );
            })}
          </PerfectScrollbar>
        </MenuPopover>
      </div>
    );
  };

  return viewOption === "chipOnly" || viewOption === "textChip" ? (
    <ChangeSuitesChip />
  ) : (
    <Card sx={{ bgcolor: "transparent", m: 0, p: 0 }} elevation={0}>
      <CardHeader
        sx={{ m: 0, p: 0 }}
        avatar={
          <Avatar
            src={getS3ImageWrapper(
              selectedSuite?.image,
              "suite",
              selectedSuite?._id
            )}
            alt={selectedSuite?.fullName}
            variant="rounded"
            sx={{ height: 40, maxWidth: 60, minWidth: 40 }}
          />
        }
        title={selectedSuite?.fullName}
        titleTypographyProps={{
          variant: "h6",
          style: { color: theme.palette.text.light },
        }}
        subheader={<ChangeSuitesChip />}
      />
    </Card>
  );
};

SuiteSwitchButton.defaultProps = {
  viewOption: "normal",
};
export default SuiteSwitchButton;
