// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button } from "@mui/material";
import { Videocam } from "@mui/icons-material";
import { useHandleRouterPush, useAppContext } from "@app21/core";

import { format, differenceInMinutes } from "date-fns";
import ShowMeetingTimeComponent from "./ShowMeetingTimeComponent";
import { AttentionActionListItem } from "blocks/atoms";

const UpcomingMeetingCard = ({ meeting }) => {
  const { loadRoute } = useHandleRouterPush();
  const { meetingId } = useAppContext();
  const meetingTimeDiff = differenceInMinutes(
    new Date(meeting?.meetingJSON?.start),
    new Date()
  );

  const handleMeetingJoin = () => {
    loadRoute("START-MEETING", { meetingId: meeting?._id });
  };

  return (
    <AttentionActionListItem
      actionOriginatorId={meeting?.meetingJSON?.createdBy}
      actionTitle={meeting.meetingJSON.summary}
      SecondaryComponent={<ShowMeetingTimeComponent meeting={meeting} />}
      actionLabel={
        meetingTimeDiff > 0
          ? ` Meeting in ${meetingTimeDiff} mins `
          : "Meeting Started"
      }
      actionTheme="success"
      actionButton={
        <Button
          onClick={handleMeetingJoin}
          disabled={Boolean(meetingId)}
          variant="contained"
          size="small"
          startIcon={<Videocam />}
        >
          Join
        </Button>
      }
    />
  );
};

export default UpcomingMeetingCard;
