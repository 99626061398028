import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
  alpha,
} from "@mui/material";
import { useAppContext, useUserInfo, getS3ImageWrapper } from "@app21/core";
import { LoadingSpinner, UserAvatar, PrintUserInfo } from "blocks/atoms";

export default function ShowPersonalSuiteCard({ org, onClick = () => null }) {
  const suiteOwnerIdMatches = org?.fullName?.match(/\((.*?)\)/);
  const suiteOwnerId = suiteOwnerIdMatches?.[1];

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5,
        p: 3,
        cursor: "pointer",
        border: `1px solid ${alpha("#000", 0.1)}`,
        boxShadow: (theme) => theme.customShadows.z1,
        "&:hover": {
          background: (theme) => alpha(theme.palette.primary.main, 0.1),
          boxShadow: (theme) => theme.customShadows.z8,
        },
      }}
      onClick={onClick}
    >
      <UserAvatar
        tooltipFlag={false}
        userId={suiteOwnerId}
        size={30}
        variant="rounded"
        disableOnClick
      />
      <Typography variant="caption" sx={{ textAlign: "center", mt: 3 }}>
        <PrintUserInfo userId={suiteOwnerId} /> 's Suite
      </Typography>
    </Paper>
  );
}
