// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useContext } from "react";
import { useAppContext } from "@app21/core";
import { useIsMobileBreakpoint, useIsOnline } from "hooks";
import toast from "react-hot-toast";

const LayoutContext = React.createContext({});

export const useLayoutContext = () => {
  const state = useContext(LayoutContext);
  if (!state) {
    throw new Error("useLayoutContext must be used within LayoutProvider");
  }
  return state;
};

const LayoutProvider = ({ children }) => {
  const { meetingId, chimeViewMode } = useAppContext();
  const [displayUserInfo, setDisplayUserInfo] = React.useState(null);
  const [menuViewMode, setMenuViewMode] = React.useState(null);
  const { isOffline } = useIsOnline();

  const [transcriptSidebarShowFlag, setTranscriptSidebarShowFlag] =
    React.useState(false);
  const [proceedToMeetingLayout, setProceedToMeetingLayout] =
    React.useState(true);

  let meetingScreenWidthOccupied = meetingId
    ? chimeViewMode === "compact"
      ? 250
      : chimeViewMode === "normal"
      ? 450
      : 50
    : 50;

  const isSmallScreen = useIsMobileBreakpoint(500 + meetingScreenWidthOccupied);
  const isLargeScreen = !useIsMobileBreakpoint(
    1000 + meetingScreenWidthOccupied
  );

  React.useEffect(() => {
    if (
      isSmallScreen ||
      chimeViewMode === "fullscreen" ||
      chimeViewMode === "featured"
    ) {
      setMenuViewMode && setMenuViewMode("menuOnly");
    } else if (isLargeScreen) {
      setMenuViewMode &&
        (meetingId ? setMenuViewMode("compact") : setMenuViewMode("full"));
    } else setMenuViewMode && setMenuViewMode("compact");
  }, [isSmallScreen, isLargeScreen, chimeViewMode, meetingId, setMenuViewMode]);

  React.useEffect(() => {
    isOffline &&
      !Boolean(meetingId) &&
      toast.error(
        `You seem to be having Intermittent Network connectivity issues. Please check`,
        {
          icon: "⚠️",
          duration: 10000,
          style: {
            minWidth: "250px",
          },
        }
      );
  }, [isOffline]);

  const layoutContextValues = {
    menuViewMode,
    setMenuViewMode,
    displayUserInfo,
    setDisplayUserInfo,
    isSmallScreen,
    isLargeScreen,
    proceedToMeetingLayout,
    setProceedToMeetingLayout,
    transcriptSidebarShowFlag,
    setTranscriptSidebarShowFlag,
  };
  return (
    <LayoutContext.Provider value={{ ...layoutContextValues }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

//Setting some basic layout parameters.. Some day let lets move this into a separate layoutcontextprovider

//end layout
