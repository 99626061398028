// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useState, useRef } from "react";
//import { NextLink } from 'next/link';
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { CardContent, Box, Card, Typography } from "@mui/material";
// utils
import { mockImgFeed } from "blocks/atoms/graphics/mockImages";
//
import { varFade, MotionContainer } from "blocks/atoms/uieffects/animate";

// ----------------------------------------------------------------------
const DESCRIPTIONS = [
  "Make interacting with your Board easy. None of those scheduling and invite codes to be sent. Just login to suite and start right off.",
  "Want to get a quick Sense Of the Room survey done? Easy. Just head to Surveys and create a new survey",
  "Scheduling a Board Meeting ? Trryst makes it easier to collate all your preparation and material for Board meetings in one place",
  "Keep your company data confidential. No more sending them over emails and sharing over messaging. Take full control over your data",
];
const CAROUSELS = [
  "Start an Instant Videocall",
  "Conduct a Survey",
  "Setup a Breakout meeting",
  "Your Files",
].map((item, index) => {
  const imageIndex = index + 1;

  return {
    title: item,
    description: DESCRIPTIONS[index],
    image: mockImgFeed(imageIndex),
  };
});

const CarouselImgStyle = styled("img")(({ theme }) => ({
  height: 280,
  zIndex: 100,
  width: "100%",
  objectFit: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.up("xl")]: {
    height: 320,
  },
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
};

function CarouselItem({ item, isActive }) {
  const { image, title, description } = item;

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          top: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          bgcolor: (theme) => alpha(theme.palette.grey[700], 0.72),
        }}
      />
      <CarouselImgStyle alt={title} src={image} />

      <CardContent
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          textAlign: "left",
          maxWidth: 320,
          maxHeight: 210,
          color: "common.white",
          backgroundColor: "transparent",
        }}
      >
        <MotionContainer
          open={isActive}
          sx={{ zIndex: 100, display: "flex", flexDirection: "column" }}
        >
          <motion.div variants={varFade().in}>
            <Typography
              variant="overline"
              sx={{
                mb: 1,
                opacity: 0.78,
                display: "block",
              }}
            >
              Featured
            </Typography>
          </motion.div>

          <motion.div variants={varFade().in}>
            <Typography variant="h6" gutterBottom color="secondary">
              {title}
            </Typography>
          </motion.div>
          <motion.div variants={varFade().in}>
            <Typography variant="body2">{description}</Typography>
          </motion.div>
        </MotionContainer>
      </CardContent>
    </Box>
  );
}

export default function AppFeatured() {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === "rtl" ? CAROUSELS.length - 1 : 0
  );

  const settings = {
    speed: 10000,
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  // const handlePrevious = () => {
  //   carouselRef.current.slickPrev();
  // };

  // const handleNext = () => {
  //   carouselRef.current.slickNext();
  // };

  return (
    <Card>
      <Slider ref={carouselRef} {...settings}>
        {CAROUSELS.map((item, index) => (
          <CarouselItem
            key={item.title}
            item={item}
            isActive={index === currentIndex}
          />
        ))}
      </Slider>
    </Card>
  );
}
