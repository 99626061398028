import React from "react";
import { initializeApp, FirebaseApp } from "firebase/app";
// import { useChatContext } from "providers/ChatProvider";
import { useUserInfo } from "@app21/core";
import { getMessaging, getToken } from "firebase/messaging";
import { useConfirm } from "material-ui-confirm";
import { isMobile } from "react-device-detect";

const firebaseOptions = {
  apiKey: "AIzaSyCKAvnDdMxNC4Nj_d5e_4fZgWtZvUDh_Z0",
  authDomain: "trryst-project.firebaseapp.com",
  projectId: "trryst-project",
  storageBucket: "trryst-project.appspot.com",
  messagingSenderId: "78891381189",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseOptions);

const App21FirebaseContext = React.createContext(app);

export const useApp21FirebaseContext = () => {
  const state = React.useContext(App21FirebaseContext);
  if (!state) {
    throw new Error(
      "useApp21FirebaseContext must be used within App21FirebaseProvider"
    );
  }
  return state;
};

// const InitFirebaseMessaging = () => {
//   const firebaseApp = useApp21FirebaseContext();

//   return null;
// };
// Right now have removed firebase messaging functions for mobile devices to avoid issues with push notifications.. but this one is useful to do.. https://stackoverflow.com/questions/31512504/html5-notification-not-working-in-mobile-chrome
export const App21FirebaseProvider = (props) => {
  const { children } = props;
  const [
    desktopNotificationsApprovalState,
    setDesktopNotificationsApprovalState,
  ] = React.useState("default");
  const { data: userInfo } = useUserInfo();
  // const FCM_TOKEN_KEY = "fcm_token";
  const renderRef = React.useRef(false);
  const firebaseMessaging = getMessaging(app);
  const confirm = useConfirm();

  React.useEffect(() => {
    if (!isMobile)
      setDesktopNotificationsApprovalState(Notification.permission);
  }, []);

  // const handleDesktopNotificationsApproval = () => {
  //   if (desktopNotificationsApprovalState !== "denied") {
  //     Notification.requestPermission().then((permission) => {
  //       console.log("permission", permission);
  //     });
  //   } else {
  //     confirm({
  //       description:
  //         "Please enable Desktop Notifications in your browser settings. This will enable use to provide you with timely updates regarding updates in the suites you are a part of",
  //     });
  //   }
  // };
  // const handleTokenServersideSyncUp = React.useCallback(
  //   async (token) => {
  //     if (isMobile) return;
  //     if (!userInfo) return;
  //     if (userInfo?.emailId === "app21chimerecorder@trryst.com") return;
  //     if (renderRef.current) return;
  //     // const clientStoredToken = localStorage.getItem(FCM_TOKEN_KEY);
  //     if (!clientStoredToken) {
  //       const bodyPayload = {
  //         fcmToken: token,
  //         calledBy: userInfo?._id,
  //       };
  //       const response = await (
  //         await fetch(`${process.env.AWS_APIPATH}/user/fcm_token/update`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(bodyPayload),
  //         })
  //       ).json();

  //       if (response && response.status === "success")
  //         localStorage.setItem(FCM_TOKEN_KEY, token);
  //     } else {
  //       if (clientStoredToken !== token) {
  //         if (!userInfo || !userInfo._id) return;

  //         const bodyPayload = {
  //           invalidToken: clientStoredToken,
  //           fcmToken: token,
  //           calledBy: userInfo?._id,
  //         };

  //         const response = await (
  //           await fetch(`${process.env.AWS_APIPATH}/user/fcm_token/update`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(bodyPayload),
  //           })
  //         ).json();
  //         if (response && response.status === "success")
  //           localStorage.setItem(FCM_TOKEN_KEY, token);
  //       } else {
  //         // Token didn't change, do nothing...
  //       }
  //     }
  //     renderRef.current = true;
  //   },
  //   [userInfo]
  // );

  const handleFetchFirebaseMessagingToken = React.useCallback(async () => {
    try {
      const token = await getToken(firebaseMessaging, {
        vapidKey:
          "BI1QnyxlsWT8pfdgcL4a3FhJIdTtHPEXKRN1PQ0UaNVbAAuC4n1t0tzJhQGfCY_uS8ovM6pQlliL__SAioAI0ZE",
      });
      console.log("Messaging Token", token);
      try {
        // await handleTokenServersideSyncUp(token);
      } catch (error) {
        //setDesktopNotificationsApprovalRequired(true);
      }
    } catch (error) {
      console.log("Error in handleFetchFirebaseMessagingToken", error);
    }
  }, [firebaseMessaging]);

  React.useEffect(() => {
    try {
      if (!isMobile && Notification.permission !== "denied") {
        handleFetchFirebaseMessagingToken();
      }
    } catch (error) {}
  }, []);

  const firebaseContextValues = {
    desktopNotificationsApprovalState,
    setDesktopNotificationsApprovalState,
    // handleDesktopNotificationsApproval,
  };
  const allContextValues = Object.assign(app, firebaseContextValues);
  return (
    <App21FirebaseContext.Provider value={allContextValues}>
      {children}
    </App21FirebaseContext.Provider>
  );
};
