import { constantCase } from "change-case";
import { checkIfNull } from "@app21/core";
import clip from "text-clipper";

export function getSuiteDetailsFromType(suiteType = "") {
  let suiteTier = "NA";
  let suiteTemplate = "NA";
  let suiteLabel = "NA";

  if (suiteType?.includes("PRO")) {
    suiteTier = "PRO";
  } else if (suiteType?.includes("BASIC")) {
    suiteTier = "BASIC";
  }

  if (suiteType?.includes("BOARD")) {
    suiteTemplate = "BOARD-SUITE";
    suiteLabel = "Board Suite";
  } else if (suiteType?.includes("PERSONAL")) {
    suiteTemplate = "PERSONAL-SUITE";
    suiteLabel = "Personal Suite";
  } else if (suiteType?.includes("DATA")) {
    suiteTemplate = "DATA-SUITE";
    suiteLabel = "Data Suite";
  } else if (suiteType?.includes("OUTREACH")) {
    suiteTemplate = "OUTREACH-SUITE";
    suiteLabel = "Outreach Suite";
  } else if (suiteType?.includes("EXEC")) {
    suiteTemplate = "EXEC-SUITE";
    suiteLabel = "Executive Suite";
  }

  return { suiteTier, suiteTemplate, suiteLabel };
}

export function getSuiteTypeFromMap(
  subscriptionChoice = "",
  subscriptionTemplate = ""
) {
  let levelString = constantCase(subscriptionChoice)?.includes("PRO")
    ? "PRO"
    : "BASIC";
  let templateString = constantCase(subscriptionTemplate)?.includes("BOARD")
    ? "BOARD-SUITE"
    : constantCase(subscriptionTemplate)?.includes("DATA")
    ? "DATA-SUITE"
    : constantCase(subscriptionTemplate)?.includes("PERSONAL")
    ? "PERSONAL-SUITE"
    : constantCase(subscriptionTemplate)?.includes("OUTREACH")
    ? "OUTREACH-SUITE"
    : "EXEC-SUITE";
  return templateString + "-" + levelString;
}

export const getSuiteCreditsInfo = (allProducts) => {
  const suiteCreditsObj = {
    BasicSuiteCredits: 0,
    ProSuiteCredits: 0,
  };
  if (!checkIfNull(allProducts)) {
    suiteCreditsObj["BASIC"] = allProducts["EXEC-SUITE-BASIC"].credits;
    suiteCreditsObj["PRO"] = allProducts["EXEC-SUITE-PRO"].credits;
  }
  return suiteCreditsObj;
};
export const getSuitePrices = (allProducts = {}, suiteTier = "BASIC") => {
  const priceObj = {
    BASIC: 0,
    PRO: 0,
    "BOARD-SUITE": 0,
    "DATA-SUITE": 0,
    "SALES-SUITE": 0,
    "EXEC-SUITE": 0,
  };
  if (!checkIfNull(allProducts)) {
    priceObj["BASIC"] =
      allProducts["EXEC-SUITE-BASIC"]?.pricing?.GBP?.monthly?.amount;
    priceObj["PRO"] =
      allProducts["EXEC-SUITE-PRO"]?.pricing?.GBP?.monthly?.amount;
    priceObj["BOARD-SUITE"] =
      allProducts[
        suiteTier === "BASIC" ? "BOARD-SUITE-BASIC" : "BOARD-SUITE-PRO"
      ]?.pricing.GBP?.monthly?.amount -
      allProducts[suiteTier === "BASIC" ? "EXEC-SUITE-BASIC" : "EXEC-SUITE-PRO"]
        ?.pricing?.GBP?.monthly?.amount;
    priceObj["DATA-SUITE"] =
      allProducts[suiteTier === "BASIC" ? "DATA-SUITE-BASIC" : "DATA-SUITE-PRO"]
        ?.pricing?.GBP?.monthly?.amount -
      allProducts[suiteTier === "BASIC" ? "EXEC-SUITE-BASIC" : "EXEC-SUITE-PRO"]
        ?.pricing?.GBP?.monthly?.amount;

    priceObj["SALES-SUITE"] = Number(
      allProducts[
        suiteTier === "BASIC" ? "SALES-SUITE-BASIC" : "SALES-SUITE-PRO"
      ]?.pricing?.GBP?.monthly?.amount -
        allProducts[
          suiteTier === "BASIC" ? "EXEC-SUITE-BASIC" : "EXEC-SUITE-PRO"
        ]?.pricing?.GBP?.monthly?.amount
    );
    // allProducts["SALES-SUITE-BASIC"].pricing.GBP.monthly.amount -
    // allProducts["EXEC-SUITE-BASIC"].pricing.GBP.monthly.amount;
  }
  return priceObj;
};

export function getSuiteAndOrganizationLabel(obj = {}) {
  let objType = "org";

  let objName = (obj && obj["fullName"]) ?? "";

  if (obj && obj["suiteType"]) {
    objType = "suite";
  }
  if (objType === "org") {
    if (obj?.orgType?.includes("PERSONAL")) {
      objName = "My Suite";
    }
  } else {
    if (obj?.suiteType?.includes("PERSONAL")) {
      objName = "My Suite";
    }
  }
  return clip(objName, 25);
}

export function getOrgIdFromSuiteId(suiteId, orgsAndSuites) {
  return (
    orgsAndSuites.find((org) =>
      org.suitesInOrg.some((suite) => suite._id === suiteId)
    )?._id ?? null
  );
}

export function checkIfNotLastAdminUser(usersList = []) {
  let sum = 0;
  let notLastAdminFlag = true;
  notLastAdminFlag = usersList?.some((user) => {
    if (constantCase(user.roleInfo?.role ?? "") === "ADMIN") {
      sum++;
    }
    if (sum > 1) {
      return true;
    } else return false;
  });
  return notLastAdminFlag;
}

export function getUserRoleInSuite(userId, usersInSuite = []) {
  const user = usersInSuite.find((user) => user._id === userId);
  return user?.roleInfo?.role
    ? constantCase(user?.roleInfo?.role ?? "")
    : "GUEST";
}
