// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const importantStyle = (value) => [value, "!important"];

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export const getSuiteColor = (theme, style) => {
  let color;
  switch (style) {
    case "boardSuite":
      color = theme.palette.boardSuite.main;
      break;
    case "execSuite":
      color = theme.palette.execSuite.main;
      break;
    case "dataSuite":
      color = theme.palette.dataSuite.main;
      break;
    case "personalSuite":
      color = theme.palette.personalSuite.main;
      break;
    case "salesSuite":
      color = theme.palette.salesSuite.main;
      break;
    case "primary":
      color = theme.palette.primary.main;
      break;
    case "secondary":
      color = theme.palette.secondary.main;
      break;
    default:
      color = theme.palette.primary.main;
  }
  return color;
};
