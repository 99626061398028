import { destroyCookie } from "nookies";

export function resetStorage() {
  const cookiesToBeClearedList = ["signupKey", "auth0.is.authenticated"];
  const localStorageToBeClearedList = [
    "inviteCode",
    "lastIds",
    "savedRoute",
    "savedIds",
    "asPath",
    "APP21-DATA",
    "datadogInitStatus",
    "app21-lost-focus-at",
  ];

  if (typeof window !== "undefined") {
    cookiesToBeClearedList.forEach((cookie) => destroyCookie(null, cookie));
    localStorageToBeClearedList.forEach((storageItem) =>
      localStorage.removeItem(storageItem)
    );
  }
}
