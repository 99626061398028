// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import FilesAdded from "./FilesAdded";
import FilesDeleted from "./FilesDeleted";
import FilesCopied from "./FilesCopied";
import FolderCreated from "./FolderCreated";
import SuiteAddedOrganization from "./SuiteAddedOrganization";
import SuiteDetachedOrganization from "./SuiteDetachedOrganization";
import UserAddedOrganization from "./UserAddedOrganization";
import UserAddedSuite from "./UserAddedSuite";
import UserDetachedOrganization from "./UserDetachedOrganization";
import UserRoleUpdatedOrganization from "./UserRoleUpdatedOrganization";
import UserRoleUpdatedSuite from "./UserRoleUpdatedSuite";
import GenericNotification from "./GenericNotification";
import UserDetachedSuite from "./UserDetachedSuite";
import DocketDeletedSuite from "./DocketDeletedSuite";
import DocketAddedSuite from "./DocketAddedSuite";

export const supportedNotificationTemplateList = [
  "FILES-ADDED",
  "FILES-DELETED",
  "FILES-COPIED",
  "FOLDER-CREATED",
  "SUITE-ADDED-ORGANIZATION",
  "SUITE-DETACHED-ORGANIZATION",
  "USER-ADDED-ORGANIZATION",
  "USER-ADDED-SUITE",
  "USER-DETACHED-ORGANIZATION",
  "USER-ROLE-UPDATED-ORGANIZATION",
  "USER-ROLE-UPDATED-SUITE",
  "USER-DETACHED-SUITE",
  "DOCKET-DELETED-SUITE",
  "DOCKET-ADDED-SUITE",
];

export const notificationTemplate = {
  [`FILES-ADDED`]: FilesAdded,
  [`FILES-DELETED`]: FilesDeleted,
  [`FOLDER-CREATED`]: FolderCreated,
  [`FILES-COPIED`]: FilesCopied,
  [`SUITE-ADDED-ORGANIZATION`]: SuiteAddedOrganization,
  [`SUITE-DETACHED-ORGANIZATION`]: SuiteDetachedOrganization,
  [`USER-ADDED-ORGANIZATION`]: UserAddedOrganization,
  [`USER-ADDED-SUITE`]: UserAddedSuite,
  [`USER-DETACHED-ORGANIZATION`]: UserDetachedOrganization,
  [`USER-DETACHED-SUITE`]: UserDetachedSuite,
  [`USER-ROLE-UPDATED-ORGANIZATION`]: UserRoleUpdatedOrganization,
  [`USER-ROLE-UPDATED-SUITE`]: UserRoleUpdatedSuite,
  [`DOCKET-DELETED-SUITE`]: DocketDeletedSuite,
  [`DOCKET-ADDED-SUITE`]: DocketAddedSuite,
  [`default`]: GenericNotification,
  [`general`]: GenericNotification,
  [null]: GenericNotification,
  [undefined]: GenericNotification,
};
