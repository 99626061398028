// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------
import { alpha } from "@mui/material/styles";

export default function Menu(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.customShadows.z8,
        borderRadius: theme.shape.borderRadiusSm,
        border: `1px solid ${theme.palette.grey[100]}`,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.primary.lighter, 0.3),
            color: theme.palette.text.primary,
          },
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.lighter, 0.1),
            color: theme.palette.text.primary,

            // // =======
            // "&.Mui-selected": {
            //   backgroundColor: theme.palette.action.selected,
            //   "&:hover": {
            //     backgroundColor: theme.palette.action.hover
            //   }
            //   // >>>>>>> v4dev
          },
        },
      },
    },
  };
}
