// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { useCancellablePromises } from "hooks";
import { delay, noop, cancellablePromise } from "hooks";
import React from "react";

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises();

  const handleClick = React.useCallback(
    (event, key, node, context) => {
      api.clearPendingPromises();
      const waitForClick = cancellablePromise(delay(300));
      api.appendPendingPromise(waitForClick);

      return waitForClick.promise
        .then(() => {
          api.removePendingPromise(waitForClick);
          onClick(event, key, node, context);
        })
        .catch((errorInfo) => {
          api.removePendingPromise(waitForClick);
          if (!errorInfo.isCanceled) {
            throw errorInfo.error;
          }
        });
    },
    [api, onClick]
  );

  const handleDoubleClick = React.useCallback(
    (event, key, node, context) => {
      api.clearPendingPromises();

      onDoubleClick(event, key, node, context);
    },
    [api, onDoubleClick]
  );

  return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
