// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useMemo } from "react";
import {
  TextField,
  Autocomplete,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { Search, ArrowRightAlt } from "@mui/icons-material";
import { getFileIcon } from "blocks/atoms";
import { getExtensionFromFilename } from "utils";
import isEmpty from "validator/lib/isEmpty";
import {
  useAppContext,
  useSelectedBucketId,
  useFilesS3,
  useUsersInOrganization,
  getS3ImageWrapper,
  useHandleAddRemoveIdFromRoute,
  FileQueryBuilder,
  checkIfNull,
} from "@app21/core";
import _ from "lodash";
import { reservedFolderNames } from "blocks/modules/TrrystFiles/foundations";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";
import { matchSorter } from "match-sorter";

const DataroomSearchPanel = () => {
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const router = useRouter();

  const [searchContentInput, setSearchContentInput] = React.useState("");
  const [selectedSearch, setSelectedSearch] = React.useState(null);

  const { fetchUserInfo } = useOrgsAndSuitesInfoContext();

  const fileQueryBuilder = useMemo(() => new FileQueryBuilder.default(), []);

  React.useEffect(() => {
    fileQueryBuilder.setId("suite", [`${selectedSuiteId}`]);
  }, [fileQueryBuilder, selectedSuiteId]);

  const {
    data: allS3Keys,
    status: allS3KeysStatus,
    apiError: allS3KeysApiError,
  } = useFilesS3({ aggregateQuery: fileQueryBuilder.getQuery() });
  const dataFetchError = allS3KeysApiError;
  const dataFetchLoading = allS3KeysStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const checkIfReservedFolder = React.useCallback((folderName) => {
    let match = false;
    reservedFolderNames.forEach((reservedFolderName) => {
      if (folderName.includes(`/${reservedFolderName}/`)) {
        match = true;
      }
    });
    return match;
  }, []);

  const constructFileSearchArray = React.useCallback(() => {
    if (!checkIfNull(allS3Keys?.listOfAllKeys)) {
      let allFilesInfo = [];
      let filesToBeProcessed =
        _.flattenDeep(allS3Keys.listOfAllKeys ?? []) ?? [];

      // let filesToBeProcessed = [];
      filesToBeProcessed
        .filter((file) => file?.Key?.slice(-1) !== "/")
        .filter((file) => !checkIfReservedFolder(file.Key))
        .forEach((obj) => {
          let tempObj = JSON.parse(JSON.stringify(obj ?? {}));
          if (obj) {
            tempObj.fileName = obj?.Key?.replace(/^.*(\\|\/|\:)/, "");
            tempObj.fullPath = obj?.Key?.split("/")?.slice(2).join("/") ?? "";
            let user = fetchUserInfo(obj?.metadata?.createdBy);
            tempObj.user = user;
            tempObj.userFullName = user?.fullName ?? "User";
            tempObj.userAvatar = getS3ImageWrapper(
              user?.image,
              "person",
              user?.fullName
            );
            allFilesInfo.push(tempObj);
          }
        });
      return allFilesInfo;
    } else return [];
  }, [allS3Keys]);

  let allFiles = constructFileSearchArray();

  //const filteredFilesArray = applyFilters(allFiles, searchContentInput);

  const handleSearchContentChange = (event) => {
    setSearchContentInput(event.target.value);
  };
  const handleSearchContentKeypress = (event) => {
    if (
      event.keyCode === 13 &&
      !isEmpty(searchContentInput || "", { ignore_whitespace: true })
    ) {
      //handleOnSearchClick();
    }
  };
  const handleResetSearchContent = () => {
    setSearchContentInput("");
    setSelectedSearch(null);
  };

  const handleFileViewClick = (event, value) => {
    handleResetSearchContent();
    let newRoutePath = addIdToRoute({
      paramKey: "viewFile",
      paramValue: value.Key,
    });
    router.push(newRoutePath, newRoutePath, { shallow: true });
  };

  const formatOptionLabel = (props, option) => {
    return (
      <ListItem {...props} dense key={option._id} divider>
        <ListItemIcon sx={{ minWidth: 25 }}>
          {getFileIcon({
            fileExt: getExtensionFromFilename(option.fileName),
            size: "xl",
          })}
        </ListItemIcon>
        <ListItemText
          primary={option.fullPath ?? option.fileName}
          secondaryTypographyProps={{ component: "span" }}
          secondary={
            <Box sx={{ display: "flex" }}>
              {/* <Avatar
                src={option.userAvatar}
                sx={{ mr: 1, mt: 1, height: 20, width: 20 }}
              /> */}
              {option.userFullName}
            </Box>
          }
        />
      </ListItem>
    );
  };
  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: ["fileName", "userFullName"],
      keepDiacritics: true,
    });
  };

  return (
    <Autocomplete
      size="small"
      id="autoComplete-files-search"
      options={allFiles}
      filterOptions={filterOptions}
      onInputChange={handleSearchContentChange}
      onChange={handleFileViewClick}
      onKeyUp={handleSearchContentKeypress}
      onClose={handleResetSearchContent}
      ListboxComponent={List}
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        bgcolor: "background.paper",
        "& .MuiAutocomplete-inputRoot": {
          border: "1px solid lightgrey",
          borderRadius: 4,
          "& :hover": {
            outline: "none",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
          outline: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
          outline: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before ": {
          borderBottom: "0 !important",
        },
      }}
      noOptionsText="No files found"
      getOptionLabel={(option) => option.fileName}
      renderOption={(props, option) => formatOptionLabel(props, option)}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: "100%" }}
          placeholder="Search DataSuite"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            style: {
              border: "none",
            },
          }}
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: <Search sx={{ mr: 1 }} fontSize="inherit" />,
            sx: {
              "& :hover": {
                "& > fieldset": {
                  borderColor: "none",
                },
              },
            },
          }}
        />
      )}
    />
  );
};

export default DataroomSearchPanel;
