// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";

const FilesCopied = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  let fileSummaryInfo;
  if (payload?.files?.length > 1) {
    fileSummaryInfo = `${payload.files?.length} files.`;
  } else {
    fileSummaryInfo = `${payload?.files && payload?.files[0]?.fileName}`;
  }
  const data = {
    message: `${payload?.uploadingUserFullName || "Suite Member"} has copied ${
      fileSummaryInfo || "a file"
    }`,
    userId: payload?.userId,
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default FilesCopied;
