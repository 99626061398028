import React from "react";
import { Box, Typography, Tooltip, Badge } from "@mui/material";
import {
  FlowBox,
  MuiStyledRoundedTabs,
  MuiStyledRoundedTab,
} from "blocks/atoms/uistyles";
import { FlowTabPanel } from "blocks/atoms/uicomponents";
import { varFade } from "blocks/atoms/uieffects/animate";
import {
  AverageViewIcon,
  ArrowSquareDownIcon,
  FilterIcon,
  TextalignJustifycenterIcon,
  GridIcon,
  IncreaseIcon,
  ReceiptSearchIcon,
  DurationIcon,
  KeyContributorsIcon,
  FileEngagementIcon,
  FilesDownloadedIcon,
  UpdatedFilesIcon,
  SearchStatusIcon,
  DirectBoxReceiveIcon,
  TimeLineIcon,
  DropDownArrowIcon,
} from "blocks/atoms/trrysticons";
import DataroomSearchPanel from "../DataroomSearchPanel";
import DataSuiteFileActivitySummaryPanel from "./DataSuiteFileActivitySummaryPanel";
import DataSuiteUsageActivityPanel from "./DataSuiteUsageActivityPanel";
import DataSuiteTimelinePanel from "./DataSuiteTimelinePanel";
import {
  DataSuiteFileHighlightsPanel,
  NeedingAttentionItems,
  DashboardBreakoutsList,
} from "blocks/views/SuiteDashboard/foundations";
import { dataSuiteDashboardTabOptionsValueAtom } from "providers/OrgsAndSuitesInfoProvider/main-atoms";
import { useAtom } from "jotai";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";
import useIsMobileBreakpoint from "hooks/useIsMobileBreakpoint";

export default function DataSuiteTabbedOptionsPanel({}) {
  const {
    needingAttentionItemsFlag = false,
    upcomingMeetings = [],
    meetingsToRSVP = [],
    openSurveysAndResolutions = [],
    isSuiteManager = false,
  } = useDashboardViewsDataContext();
  const isMobile = useIsMobileBreakpoint();
  const [
    dataSuiteDashboardTabOptionsValue,
    setDataSuiteDashboardTabOptionsValue,
  ] = useAtom(dataSuiteDashboardTabOptionsValueAtom);

  const handleTabChange = (event, newValue) => {
    setDataSuiteDashboardTabOptionsValue(newValue);
  };
  const numOfActionItems =
    (upcomingMeetings?.length ?? 0) +
    (meetingsToRSVP?.length ?? 0) +
    (openSurveysAndResolutions?.length ?? 0);

  React.useEffect(() => {
    if (!needingAttentionItemsFlag) {
      setDataSuiteDashboardTabOptionsValue("breakouts");
    } else setDataSuiteDashboardTabOptionsValue("needsattention");
  }, [needingAttentionItemsFlag]);

  return (
    <FlowBox sx={{ gap: 1, mt: 2, overflow: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="overline" color="primary.darker">
          Your Dashboard
        </Typography>
      </Box>
      <MuiStyledRoundedTabs
        value={dataSuiteDashboardTabOptionsValue}
        onChange={handleTabChange}
        sx={{
          mt: 2,
          mb: 2,
          justifyContent: "flex-start",
          ml: 0,
        }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        selectionFollowsFocus
        tabsHeight={35}
      >
        {needingAttentionItemsFlag && (
          <MuiStyledRoundedTab
            value={"needsattention"}
            textColor="secondary"
            tabHeight={35}
            icon={<ReceiptSearchIcon fontSize="small" />}
            label={
              <Badge
                color="secondary"
                badgeContent={numOfActionItems}
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    right: -5,
                    top: 0,
                    fontSize: 10,
                    p: 0,
                  },
                }}
              >
                <Typography variant="subtitle2">
                  {isMobile ? "Attention" : "Needs Attention"}
                </Typography>
              </Badge>
            }
          />
        )}
        <MuiStyledRoundedTab
          value={"breakouts"}
          textColor="secondary"
          tabHeight={35}
          sx={{}}
          icon={<ReceiptSearchIcon fontSize="small" />}
          label={isMobile ? "Breakouts" : "Your Breakouts"}
        />
        <MuiStyledRoundedTab
          value={"filessummary"}
          textColor="secondary"
          tabHeight={35}
          sx={{}}
          icon={<ReceiptSearchIcon fontSize="small" />}
          label={isMobile ? "Recent" : "Recent Files"}
          data-testid="recent-files"
        />

        {isSuiteManager && (
          <MuiStyledRoundedTab
            value={"activity"}
            textColor="secondary"
            tabHeight={35}
            sx={{}}
            icon={<AverageViewIcon fontSize="small" />}
            label={"Activity"}
          />
        )}
        {process.env.STAGE !== "prod" && (
          <MuiStyledRoundedTab
            value={"timeline"}
            textColor="secondary"
            tabHeight={35}
            sx={{}}
            icon={<TimeLineIcon fontSize="small" />}
            label={"Timeline"}
          />
        )}
      </MuiStyledRoundedTabs>
      <FlowBox sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
        <PerfectScrollbar
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: 1,

            paddingRight: 10,
          }}
        >
          {dataSuiteDashboardTabOptionsValue === "needsattention" && (
            <FlowTabPanel
              value={dataSuiteDashboardTabOptionsValue}
              index={"needsattention"}
              sx={{ p: 3 }}
            >
              <NeedingAttentionItems
                showTitleFlag={false}
                upcomingMeetings={upcomingMeetings}
                meetingsToRSVP={meetingsToRSVP}
                openSurveysAndResolutions={openSurveysAndResolutions}
              />
            </FlowTabPanel>
          )}
          {dataSuiteDashboardTabOptionsValue === "breakouts" && (
            <FlowTabPanel
              value={dataSuiteDashboardTabOptionsValue}
              index={"breakouts"}
            >
              <DashboardBreakoutsList />
            </FlowTabPanel>
          )}
          {dataSuiteDashboardTabOptionsValue === "filessummary" && (
            <FlowTabPanel
              value={dataSuiteDashboardTabOptionsValue}
              index={"filessummary"}
            >
              <DataSuiteFileHighlightsPanel />
            </FlowTabPanel>
          )}
          {dataSuiteDashboardTabOptionsValue === "activity" && isSuiteManager && (
            <FlowTabPanel
              value={dataSuiteDashboardTabOptionsValue}
              index={"activity"}
            >
              <DataSuiteUsageActivityPanel />
            </FlowTabPanel>
          )}
          {dataSuiteDashboardTabOptionsValue === "timeline" && (
            <FlowTabPanel
              value={dataSuiteDashboardTabOptionsValue}
              index={"timeline"}
            >
              <DataSuiteTimelinePanel />
            </FlowTabPanel>
          )}
        </PerfectScrollbar>
      </FlowBox>
      {/* {tabValue === "filessummary" && (
        <FlowBox sx={{ py: 1 }}>
          <Box sx={{ display: "flex", gap: 3, py: 1 }}>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", gap: 3, minWidth: 350 }}>
              <DataroomSearchPanel />
              <ToggleButtonGroup
                value={viewType}
                color="primary"
                exclusive
                onChange={handleViewType}
                aria-label="File List view mode"
              >
                <Tooltip title="List View" placement="top">
                  <ToggleButton value="listView" aria-label="List mode">
                    <TextalignJustifycenterIcon fontSize="inherit" />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Grid View" placement="top">
                  <ToggleButton value="gridView" aria-label="Grid mode">
                    <GridIcon fontSize="inherit" />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <DataSuiteFileActivitySummaryPanel
            viewType={viewType}
            fileMap={fileMap}
            recentFiles={recentFiles}
            isSuiteManager={isSuiteManager}
          />
        </FlowBox>
      )}
      {tabValue === "activity" && isSuiteManager && (
        <DataSuiteUsageActivityPanel
          filesActivityData={filesActivityData}
          contributors={contributors}
          totalFileViews={totalFileViews}
          totalChimeMinutes={totalChimeMinutes}
          isSuiteManager={isSuiteManager}
          totalFiles={totalFiles}
          totalSpaceUsed={totalSpaceUsed}
          creditUsageDetails={creditUsageDetails}
        />
      )}
      {process.env.STAGE !== "prod" && tabValue === "timeline" && (
        <DataSuiteTimelinePanel />
      )} */}
    </FlowBox>
  );
}
