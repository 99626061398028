// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Grid, useMediaQuery, Box } from "@mui/material";
import { useAppContext, useDataSuiteAnalytics } from "@app21/core";
import { useQueryClient } from "react-query";
import {
  ExecutiveSuiteWelcomeCard,
  ExecutiveSuiteActivityPanel,
  ExecutiveSuiteActionsPanel,
} from "blocks/views/SuiteDashboard";
import { MainChatComponent } from "blocks/modules/TrrystChat";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const ExecutiveSuiteDashboardView = () => {
  const { isMobile, needingAttentionItemsFlag } =
    useDashboardViewsDataContext();
  const queryClient = useQueryClient();

  const [dataView, setDataView] = React.useState(
    localStorage.getItem("datasuite:preferences:dataView") || "ALL"
  );

  // const handleDataViewToggle = React.useCallback((e) => {
  //   if (e.target.checked) {
  //     setDataView("ONLY-ME");
  //   } else {
  //     setDataView("ALL");
  //   }
  // }, []);

  React.useEffect(() => {
    localStorage.setItem("datasuite:preferences:dataView", dataView);
  }, [dataView]);

  const isMedium = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const showChatPanel = isMedium;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        sx={{ mt: 3, p: 1, flexGrow: 1 }}
        alignItems="stretch"
      >
        {isMedium && showChatPanel && (
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <MainChatComponent viewMode="compact" />
          </Grid>
        )}
        <Grid item container xs={12} md={showChatPanel ? 8 : 12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <ExecutiveSuiteWelcomeCard />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                mt: 1,
                flexGrow: 1,
                alignItems: "stretch",
              }}
            >
              {needingAttentionItemsFlag && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    pb: 1,
                  }}
                >
                  <ExecutiveSuiteActionsPanel />
                </Box>
              )}

              <Box
                sx={{ flexGrow: 2, display: "flex", flexDirection: "column" }}
              >
                <ExecutiveSuiteActivityPanel />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExecutiveSuiteDashboardView;
