import React, { useContext, useState, useEffect } from "react";
import { VideoPriorityBasedPolicy } from "amazon-chime-sdk-js";
import {
  AvailableMeetingModes,
  VideoFiltersCpuUtilization,
  VideoTransformOptions,
} from "../configs/constants";
import {
  useLogger,
  useMeetingEvent,
} from "amazon-chime-sdk-component-library-react";
import { useTheme } from "@mui/material";
import { useAppContext, useHandleAddRemoveIdFromRoute } from "@app21/core";
import { useRouter } from "next/router";
import { useIsOnline } from "hooks";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
const ChimeStateContext = React.createContext();
import toast from "react-hot-toast";

export function useChimeState() {
  const state = useContext(ChimeStateContext);

  if (!state) {
    throw new Error("useAppState must be used within AppStateProvider");
  }

  return state;
}

export default function ChimeStateProvider({ children }) {
  const theme = useTheme();
  const logger = useLogger();
  const router = useRouter();
  const meetingEvent = useMeetingEvent();
  const [region, setRegion] = useState("us-east-1");
  const [meetingMode, setMeetingMode] = useState(
    AvailableMeetingModes.Attendee
  );

  const { setChimeRoute } = useVideocallContext();
  const { userInfo } = useAppContext();
  const [onStartVideoEnabled, setOnStartVideoEnabled] = useState(false);
  const [priorityBasedPolicy, setPriorityBasedPolicy] = useState();
  const [enableSimulcast, setEnableSimulcast] = useState(false);
  const [keepLastFrameWhenPaused, setKeepLastFrameWhenPaused] = useState(false);
  const [isEchoReductionEnabled, setIsEchoReductionEnabled] = useState(false); //keep it as false. Echo reduction is a premium feature. So let us not use it. Kept it for now to make it easy for us to offer it at a later date if needed
  const [videoTransformCpuUtilization, setCpuPercentage] = useState(
    VideoFiltersCpuUtilization.CPU40Percent
  );
  const { isOnline, isOffline } = useIsOnline();

  React.useEffect(() => {
    if (isOffline) {
      toast.error(
        "You seem to be having network connectivity issues. Check meeting info in more options for details",
        {
          containerStyle: {
            top: 20,
            left: 20,
            bottom: 100,
            right: 20,
          },
        }
      );
    }
  }, [isOffline]);

  const [activeVideoTransformOption, setActiveVideoTransformOption] = useState(
    userInfo?.settings?.chimeSettings?.videoTransformOption ?? "NONE"
  );
  const [isVoiceFocusEnabled, setIsVoiceFocusEnabled] = React.useState(true);

  const [imageBlob, setImageBlob] = useState(undefined);
  const [blurStrength, setBlurstrength] = useState(
    activeVideoTransformOption?.includes("BLUR")
      ? VideoTransformOptions[activeVideoTransformOption].value
      : 0
  );
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    activeVideoTransformOption?.includes("REPLACEMENT")
      ? VideoTransformOptions[activeVideoTransformOption].value
      : null
  );
  const [changedUserPreferencesFlag, setChangedUserPreferencesFlag] =
    useState(false);

  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  useEffect(() => {
    if (activeVideoTransformOption?.includes("BLUR"))
      setBlurstrength(VideoTransformOptions[activeVideoTransformOption].value);
    if (activeVideoTransformOption?.includes("REPLACE")) {
      setBackgroundImageUrl(
        VideoTransformOptions[activeVideoTransformOption].value
      );
      loadReplacementImageBlob(
        VideoTransformOptions[activeVideoTransformOption].value
      );
    }
  }, [activeVideoTransformOption]);

  React.useEffect(() => {
    if (
      meetingEvent &&
      (meetingEvent.name === "meetingEnded" ||
        meetingEvent.name === "meetingFailed" ||
        meetingEvent.name === "meetingStartFailed")
    ) {
      router.push(removeIdFromRoute({ paramKey: "meetingId" }), {
        shallow: true,
      });
    }
  }, [meetingEvent]);

  async function fetchImageBlob(imageUrl) {
    const image = await fetch(imageUrl);
    const imageBlob = await image.blob();
    return imageBlob;
  }

  async function loadReplacementImageBlob(url) {
    if (url) {
      const blob = await fetchImageBlob(url);
      if (blob !== null) {
        setImageBlob(blob);
      }
      return blob;
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = 500;
      canvas.height = 500;
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        const grd = ctx.createLinearGradient(0, 0, 250, 0);
        grd.addColorStop(0, theme.palette.primary.main);
        grd.addColorStop(1, theme.palette.primary.light);
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 500, 500);
        canvas.toBlob(function (blob) {
          if (blob !== null) {
            setImageBlob(blob);
          }
        });
      }
      return imageBlob;
    }
  }

  useEffect(() => {
    /* Load a canvas that will be used
     as the replacement image for Background Replacement */
    loadReplacementImageBlob(backgroundImageUrl);
  }, []);

  const toggleVoiceFocus = () => {
    setIsVoiceFocusEnabled((current) => !current);
  };

  const toggleSimulcast = () => {
    setEnableSimulcast((current) => !current);
  };

  const togglePriorityBasedPolicy = () => {
    if (priorityBasedPolicy) {
      setPriorityBasedPolicy(undefined);
    } else {
      setPriorityBasedPolicy(new VideoPriorityBasedPolicy(logger));
    }
  };

  const toggleKeepLastFrameWhenPaused = () => {
    setKeepLastFrameWhenPaused((current) => !current);
  };

  const setCpuUtilization = (filterValue) => {
    setCpuPercentage(filterValue);
  };

  const toggleEchoReduction = () => {
    setIsEchoReductionEnabled((current) => !current);
  };

  const providerValue = {
    isVoiceFocusEnabled,
    toggleVoiceFocus,
    videoTransformCpuUtilization,
    imageBlob,
    isEchoReductionEnabled,
    region,
    setRegion,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,

    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    toggleSimulcast,
    setCpuUtilization,
    toggleEchoReduction,
    setImageBlob,
    blurStrength,
    backgroundImageUrl,
    activeVideoTransformOption,
    setActiveVideoTransformOption,
    meetingMode,
    isVoiceFocusEnabled,
    setIsVoiceFocusEnabled,
    setMeetingMode,
    loadReplacementImageBlob,
    onStartVideoEnabled,
    setOnStartVideoEnabled,
    changedUserPreferencesFlag,
    setChangedUserPreferencesFlag,
  };

  return (
    <ChimeStateContext.Provider value={providerValue}>
      {children}
    </ChimeStateContext.Provider>
  );
}
