"use";
// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { SnackbarProvider } from "notistack";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import infoFill from "@iconify/icons-eva/info-fill";
import alertCircleFill from "@iconify/icons-eva/alert-circle-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
// material
import { alpha, useTheme } from "@mui/material/styles";
import { Box, GlobalStyles, IconButton } from "@mui/material";

import closeFill from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = true; //theme.palette.mode === 'light';
  return (
    <GlobalStyles
      styles={{
        "& .SnackbarContainer-root": {
          marginBottom: 50,
        },
        "& .SnackbarContent-root": {
          display: "flex",
          width: "100%",
          padding: theme.spacing(0.5),
          margin: theme.spacing(0.25, 0),
          boxShadow: theme.customShadows.z8,
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey[isLight ? 800 : 600],
          backgroundColor: `${
            theme.palette.grey[isLight ? 300 : 0]
          } !important`,

          "&.SnackbarItem-variantError": {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${alpha(
              theme.palette.secondary.lighter,
              0.95
            )} !important`,
          },
          "&.SnackbarItem-variantWarning": {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${alpha(
              theme.palette.primary.lighter,
              0.95
            )} !important`,
          },
          "&.SnackbarItem-variantSuccess": {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${alpha(
              theme.palette.background.neutral,
              0.95
            )} !important`,
          },
          "&.SnackbarItem-variantInfo": {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${alpha(
              theme.palette.background.paper,
              0.95
            )} !important`,
          },
        },
        "& .SnackbarItem-message": {
          padding: "0 !important",
          display: "flex",

          flexGrow: 1,

          overflowWrap: "break-word",
          fontWeight: theme.typography.fontWeightMedium,
        },
        "& .SnackbarItem-action": {
          marginRight: 0,
          marginLeft: 0,
          color: theme.palette.action.active,
          "& svg": { width: 20, height: 20 },
        },
      }}
    />
  );
}

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        border: "4px solid yellow",
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

ChimeNotistackProvider.propTypes = {
  children: PropTypes.node,
};

export default function ChimeNotistackProvider({ children }) {
  const { chimeNotistackRef } = useVideocallContext();

  const onClickDismiss = (key) => {
    chimeNotistackRef.current.closeSnackbar(key);
  };
  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={200000}
        ref={chimeNotistackRef}
        action={(key) => {
          return (
            <IconButton color="secondary" onClick={() => onClickDismiss(key)}>
              <Icon icon={closeFill} width={24} height={24} />
            </IconButton>
          );
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        hideIconVariant
        iconVariant={{
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          error: <SnackbarIcon icon={infoFill} color="error" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          info: <SnackbarIcon icon={alertCircleFill} color="info" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
