import React, { Fragment } from "react";
import { App21FirebaseProvider } from "providers/Firebase";
import { useAppContext } from "@app21/core";

export default function ChatCommsWrapper({ children }) {
  // const { isVisitorFlag, userInfo } = useAppContext();
  // const isBotFlag = userInfo?.emailId === 'app21chimerecorder@trryst.com';
  // const isChatFeaturesEnabled = !(isVisitorFlag || isBotFlag);

  const { isVisitorFlag } = useAppContext();
  const isChatFeaturesEnabled = !isVisitorFlag;

  if (isChatFeaturesEnabled) {
    return (
      <>
        <App21FirebaseProvider>{children}</App21FirebaseProvider>
      </>
    );
  } else return <Fragment>{children}</Fragment>;
}
