import MeetingCompleted from "./MeetingCompleted";
import GenericTimelineTemplate from "./GenericTimelineTemplate";

export const supportedTimelineTemplateList = ["MEETING-COMPLETED"];

export const timelineTemplate = {
  [`MEETING-COMPLETED`]: MeetingCompleted,
  [`default`]: GenericTimelineTemplate,
  [`general`]: GenericTimelineTemplate,
  [null]: GenericTimelineTemplate,
  [undefined]: GenericTimelineTemplate,

  // TODO: Add Feed Mapping
};
