// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// material
import { createTheme } from "@mui/material/styles";
// hooks
//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";

import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// ----------------------------------------------------------------------

const themeDirection = "ltr";
const isLight = true; //themeMode === 'light';

const themeOptions = {
  palette: isLight
    ? { ...palette.light, mode: "light" }
    : { ...palette.dark, mode: "dark" },
  shape,
  typography,
  breakpoints,
  direction: themeDirection,
  shadows: isLight ? shadows.light : shadows.dark,
  customShadows: isLight ? customShadows.light : customShadows.dark,
  spacing: 4,
};

const theme = createTheme(themeOptions);
theme.components = componentsOverride(theme);

export default theme;
