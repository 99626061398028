// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography, Card, CardHeader, CardContent } from "@mui/material";
import { useAppContext } from "@app21/core";
import { SuiteActivityTimeline } from "blocks/views/Notifications";

export default function ExecutiveSuiteActivityPanel({}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <CardHeader
        disableTypography
        title={
          <Typography variant="overline" color="primary.darker">
            Activities in Suite
          </Typography>
        }
      />

      <SuiteActivityTimeline />
    </Card>
  );
}
