// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Children } from "react";
import { Collapse, IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { MVerticalRotateIconButton } from "blocks/atoms/@material-extend";

const StyledRootSpan = styled("span")(({ theme }) => ({
  width: "100%",
  height: " 100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));
const StyledCollapseSpan = styled("span")(({ theme }) => ({
  zIndex: 500,
  display: "flex",
  justifyContent: "flex-end",
  marginTop: -40,
  alignSelf: "flex-end",
  width: 40,
}));

export default function ExpandPanel({
  maxItems = 5,
  children,
  className,
  ...rest
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded(!expanded);
  };
  const countArray = Children.toArray(children)?.length;
  const basicPart = children?.slice(0, maxItems);
  const expandPart = children?.slice(maxItems, countArray);

  return (
    <StyledRootSpan {...rest}>
      <>{basicPart}</>
      {countArray > maxItems && (
        <StyledCollapseSpan>
          <MVerticalRotateIconButton
            size="small"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore fontSize="inherit" />
          </MVerticalRotateIconButton>
        </StyledCollapseSpan>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <>{expandPart}</>
      </Collapse>
    </StyledRootSpan>
  );
}
