// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleNotificationCard from "blocks/views/Notifications/foundations/SimpleNotificationCard";
import { getS3ImageWrapper } from "@app21/core";

const UserDetachedOrganization = ({ notification, viewContext, index }) => {
  const { payload } = notification;

  const data = {
    message: `${
      payload?.detachedUserFullName || "User"
    } has been removed from the Organization ${payload?.organizationFullName} `,
    avatar: getS3ImageWrapper(payload?.detachedUserImage, "person"),
    subHeader: notification.createdAt,
  };
  return (
    <SimpleNotificationCard
      data={data}
      notification={notification}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default UserDetachedOrganization;
