// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useContext } from "react";
import { useChatChannels, useAppContext } from "@app21/core";
import { LoadingSpinner } from "blocks/atoms";
import { usePresence } from "@pubnub/react-chat-components";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
const ChatContext = React.createContext({});
import { usePubNub } from "pubnub-react";
import {
  ThemeAtom,
  CurrentChannelAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { MainChat } from "blocks/modules/TrrystChat/features";

export const useChatContext = () => {
  const state = useContext(ChatContext);
  if (!state) {
    throw new Error("useChatContext must be used within ChatProvider");
  }
  return state;
};

const TrrystChatProvider = ({ children }) => {
  const [currentChannel, setCurrentChannel] = useAtom(CurrentChannelAtom);
  const [theme] = useAtom(ThemeAtom);

  const {
    userInfo,
    selectedSuiteId,
    activeChatId,
    activeChatType,
    checkAccessRules,
  } = useAppContext();

  const pubnubChatClient = usePubNub();
  React.useEffect(() => {
    if (userInfo && pubnubChatClient) {
      pubnubChatClient.setUUID(userInfo?._id);
      // pubnubChatClient._config._addPnsdkSuffix(
      //   "chat-components",
      //   "RCC/__VERSION__"
      // );
    }
  }, [userInfo]);

  const {
    data: allChannelsForUser,
    status: allChannelsStatus,
    apiError: allChannelsApiError,
  } = useChatChannels({
    objectId: userInfo?._id,
    objectType: "user",
  });

  const [chatInfoContext, setChatInfoContext] = React.useState(null);
  const [isAuthorizedToAccessChatChannel, setIsAuthorizedToAccessChatChannel] =
    React.useState(true);
  const handleChangeChatInfoContext = (value) => {
    setChatInfoContext(value);
  };

  const [sidebarActive, setSidebarActive] = React.useState(false);
  const handleSidebarToggle = (event, val) => {
    if (typeof val == "boolean") {
      setSidebarActive(val);
    } else setSidebarActive((old) => !old);
  };

  const allChannelsIds = allChannelsForUser?.channelIds ?? [];

  const allChannelsData = React.useMemo(() => {
    if (allChannelsForUser) {
      return allChannelsForUser.data;
    } else return [];
  }, [allChannelsForUser, selectedSuiteId]);

  const selectedChannel = React.useMemo(
    () =>
      allChannelsData?.find((channel) => channel.id === currentChannel) ?? {},
    [allChannelsData, currentChannel]
  );

  const [inputsForUnreadCounts, setInputsForUnreadCounts] =
    React.useState(null);

  const [unreadMessageCounts, setUnreadMessageCounts] = React.useState({});
  const [totalUnreadMessageCount, setTotalUnreadMessageCount] =
    React.useState(0);

  const [chatNotificationsInMeeting, updateChatNotificationsInMeeting] =
    React.useState(null);

  React.useEffect(() => {
    if (allChannelsForUser) {
      let inputsForUnreadCounts = {
        channels: [],
        channelTimetokens: [],
        data: [],
      };

      allChannelsForUser?.data?.map((channel) => {
        let memberDetail = channel?.members?.find(
          (m) => m?.id === userInfo?._id
        );
        const timetoken = memberDetail?.custom.lastReadTimetoken
          ? memberDetail.custom.lastReadTimetoken
          : Date.now() * 10000; // convert from Date time to Pubnub timetoken format

        inputsForUnreadCounts.channels.push(channel.id);
        inputsForUnreadCounts.channelTimetokens.push(timetoken);
        inputsForUnreadCounts.data.push({
          channel: channel.id,
          channelTimetoken: timetoken,
        });
      });

      setInputsForUnreadCounts(inputsForUnreadCounts);
    }
  }, [allChannelsForUser]);

  const sumValue = (obj) => Object.values(obj ?? {}).reduce((a, b) => a + b, 0);

  React.useEffect(() => {
    inputsForUnreadCounts &&
      pubnubChatClient.messageCounts(
        {
          channels: inputsForUnreadCounts.channels,
          channelTimetokens: inputsForUnreadCounts.channelTimetokens,
        },
        (status, response) => {
          if (response?.channels) {
            setUnreadMessageCounts(response?.channels);
            setTotalUnreadMessageCount(sumValue(response.channels));
          }
        }
      );
  }, [inputsForUnreadCounts]);

  // React.useEffect(() => {
  //   // want this to be called only when there is a change in the number of channels.
  //   // all channels for user get updated on updating last read time tokens as well
  //   // hence this registration is put into a separate useEffect which tracks the
  //   // length of the channelIds.
  //   if (
  //     window.localStorage.getItem("fcm_token") &&
  //     pubnubChatClient &&
  //     allChannelsForUser?.channelIds &&
  //     window.localStorage.getItem("fcm_token")
  //   )
  //     pubnubChatClient.push
  //       .addChannels({
  //         pushGateway: "gcm",
  //         channels: allChannelsForUser.channelIds,
  //         device: window.localStorage.getItem("fcm_token"),
  //       })
  //       .then(() => console.log("Registered PubNub FCM"));
  // }, [allChannelsForUser?.channelIds?.length]);

  const [viewChatContext, setViewChatContext] = React.useState(
    activeChatType ?? "suite"
  );

  React.useEffect(() => {
    const currentChannelObj = allChannelsData?.data?.find(
      (ch) => ch.id === activeChatId
    );
    if (!currentChannelObj?.metadata.suiteId.includes(selectedSuiteId))
      setIsAuthorizedToAccessChatChannel(false);
  }, [activeChatId]);

  React.useEffect(() => {
    if (activeChatType) {
      setViewChatContext(activeChatType);
    } else setViewChatContext("suite");
  }, [activeChatType]);

  const handleChangeViewContext = (context) => {
    setViewChatContext(context);
    if (context === "suite") {
      setCurrentChannel(`${selectedSuiteId}-CHAT`);
    }
  };

  const { accessFlag: canAccessMembers } =
    selectedChannel.type === "SUITES" || selectedChannel.type === "DOCKETS"
      ? checkAccessRules({
          entity: "USERS-VISIBILITY",
          action:
            selectedChannel.type === "SUITES"
              ? "VIEW-ALL-SUITE-USERS"
              : "VIEW-ALL-DOCKET-USERS",
          featureName: "CHAT",
          isInvitee: true,
        })
      : { accessFlag: true };

  const { accessFlag: canDeleteChat } = checkAccessRules({
    entity: selectedChannel.type !== "DOCKETS" ? "CHAT" : "DOCKET",
    action:
      selectedChannel.type !== "DOCKETS"
        ? "DELETE-CHANNEL-CHAT"
        : "DELETE-CHAT",
    featureName: "CHAT",
    isInvitee: true,
    isCreator: selectedChannel?.metadata?.creatorId === userInfo?._id,
  });
  const { accessFlag: canRespondToChat } = checkAccessRules({
    entity: selectedChannel.type === "SUITES" ? "CHAT" : "DOCKET",
    action:
      selectedChannel.type === "SUITES"
        ? "RESPOND-SUITE-CHAT"
        : "PARTICIPATE-CHAT",
    featureName: "CHAT",
    isInvitee: true,
    isCreator: selectedChannel?.metadata?.creatorId === userInfo?._id,
  });
  const chatPermissions = {
    canAccessMembers,
    canRespondToChat,
    canDeleteChat,
    default: true,
  };
  React.useEffect(() => {
    activeChatId &&
      activeChatId !== currentChannel &&
      setCurrentChannel(activeChatId);
  }, [activeChatId]);

  const [presenceData] = usePresence({
    channels: allChannelsIds?.length
      ? allChannelsIds
      : currentChannel
      ? [currentChannel]
      : [],
  });

  const presentUUIDs = currentChannel
    ? presenceData[currentChannel]?.occupants?.map((o) => o.uuid)
    : [];

  const chatContextValues = {
    allChannelsIds,
    allChannelsData,
    viewChatContext,
    handleChangeViewContext,
    chatNotificationsInMeeting,
    updateChatNotificationsInMeeting,
    viewChatContext,
    chatInfoContext,
    handleChangeChatInfoContext,
    sidebarActive,
    setSidebarActive,
    handleSidebarToggle,
    presentUUIDs,
    selectedChannel,
    // unread message counts for all user channels across orgs and suites
    inputsForUnreadCounts,
    chatPermissions,
    isAuthorizedToAccessChatChannel,
    unreadMessageCounts,
    setUnreadMessageCounts,
    totalUnreadMessageCount,
    setTotalUnreadMessageCount,
    sumValue,
  };

  React.useEffect(() => {
    if (allChannelsApiError && allChannelsStatus != "loading") {
      toast.error(allChannelsApiError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  }, [allChannelsApiError]);

  const handleError = (e) => {
    if (
      (e.status?.operation === "PNPublishOperation" &&
        e.status?.statusCode === 403) ||
      e.message.startsWith("Publish failed")
    ) {
      alert("Your message was blocked.");
    }
  };

  if (
    allChannelsStatus === "loading" ||
    !allChannelsData ||
    !inputsForUnreadCounts
  )
    return <LoadingSpinner variant="exp" />;
  else
    return (
      <ChatContext.Provider value={{ ...chatContextValues }}>
        <MainChat
          theme={theme}
          users={allChannelsData[0]?.members || []}
          currentChannel={currentChannel}
          channels={allChannelsIds}
          onError={handleError}
        >
          {children}
        </MainChat>
      </ChatContext.Provider>
    );
};

export default TrrystChatProvider;
