// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  List,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import { useIntl } from "react-intl";
import UpcomingMeetingCard from "blocks/views/SuiteDashboard/foundations/AttentionActionCards/UpcomingMeetingCard";
import RsvpMeetingCard from "blocks/views/SuiteDashboard/foundations/AttentionActionCards/RsvpMeetingCard";
import SurveyResponseNotificationCard from "blocks/views/SuiteDashboard/foundations/AttentionActionCards/SurveyResponseNotificationCard";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";
export default function ExecutiveSuiteActionsPanel({}) {
  const {
    needingAttentionItemsFlag,
    upcomingMeetings,
    meetingsToRSVP,
    openSurveysAndResolutions,
  } = useDashboardViewsDataContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  if (needingAttentionItemsFlag) {
    return (
      <Card sx={{ height: "100%" }}>
        <CardHeader
          disableTypography
          title={
            <Typography variant="overline" color="primary.darker">
              {`${t("SuiteDashboard.NeedingAttentionItems.headline")}`}
            </Typography>
          }
        />
        <CardContent sx={{ p: 1 }}>
          <List dense disablePadding sx={{ mt: 1, mb: 1 }}>
            {Boolean(upcomingMeetings?.length) &&
              upcomingMeetings?.map((meeting, index) => (
                <UpcomingMeetingCard key={index} meeting={meeting} />
              ))}

            {Boolean(meetingsToRSVP?.length) &&
              meetingsToRSVP?.map((meeting, index) => (
                <RsvpMeetingCard key={index} meeting={meeting} />
              ))}
            {Boolean(openSurveysAndResolutions?.length) &&
              openSurveysAndResolutions?.map((survey, index) => (
                <SurveyResponseNotificationCard key={index} survey={survey} />
              ))}
          </List>
        </CardContent>
      </Card>
    );
  } else return null;
}
