// below function is used for Pubnub chat message to see if message if file type

import path from "path";

export const specialCharactersList = [
  "'",
  "%",
  "*",
  "&",
  "{",
  "}",
  "\\",
  "@",
  "=",
  "?",
  "!",
  "<=",
  ">=",
  "/",
  "^",
  "`",
  "~",
  ";",
  ":",
];

export const fileViewerSupportedFileTypes = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "rtf",
  "gif",
  "mp4",
  "avi",
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "html",
  "htm",
  "tiff",
  "txt",
  "mpg",
  "mpeg",
  "odt",
  "csv",
];
export const formatBytes = (a, b = 2, k = 1000) => {
  let d = Math.floor(Math.log(a) / Math.log(k));
  return 0 === a
    ? "0 B"
    : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
        " " +
        ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
};

export function isFilePayload(message = {}) {
  return message.file !== undefined;
}

export function getChatFilename(s3Key = "") {
  let originalFileName = path.basename(s3Key ?? "");
  let fileName = originalFileName;
  const fileNameFirstPart = originalFileName?.split("-").shift();
  if (parseInt(fileNameFirstPart) > 10000000) {
    fileName = originalFileName.replace(fileNameFirstPart + "-", "");
  }
  return fileName ?? "";
}

export function getFileOrFoldernameFromS3Key(s3Key = "") {
  if (s3Key.endsWith("/")) {
    return s3Key.split("/").slice(0, -1).pop();
  }
  return s3Key.split("/").pop();
}

export function isFileViewerSupportedExtension(ext) {
  return fileViewerSupportedFileTypes.includes(ext);
}
