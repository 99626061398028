// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import { Typography, Stack, Box } from "@mui/material";
import { format } from "date-fns";
import Label from "blocks/atoms/uicomponents/Label";
import RSVPHandlerChip from "blocks/modules/Scheduler/foundations/RSVPHandlerChip";
import {
  convertToSchedulerFormat,
  useAppContext,
  useHandleMeetingActions,
  populateUsers,
} from "@app21/core";
import AddToCalendarButton from "blocks/modules/Scheduler/foundations/AddToCalendarButton";
import ShowMeetingTimeComponent from "./ShowMeetingTimeComponent";
import _ from "lodash";
import { AttentionActionListItem, ProfilesChipsList } from "blocks/atoms";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";

import toast from "react-hot-toast";

const RsvpMeetingCard = ({ meeting }) => {
  const currentDate = new Date();
  const { fetchSuiteInfo, fetchDocketInfo } = useOrgsAndSuitesInfoContext();
  const pastMeetingFlag = meeting.meetingJSON.start < currentDate.valueOf();
  const { handleMeetingActions } = useHandleMeetingActions();
  const { userInfo } = useAppContext();

  let userRsvpStatus = meeting.meetingJSON?.members?.find(
    (attendee) => attendee._id === userInfo._id
  )?.rsvpStatus;

  const handleRSVPChange = async (status) => {
    // needed for scheduler format matching.
    meeting.meetingJSON.id = meeting._id;

    await handleMeetingActions(
      {
        action: "RSVP-UPDATE",
        meetingJSON: meeting.meetingJSON,
        changedrsvpStatus: status,
        meetingId: meeting._id,
      },
      null,
      null
    );
  };

  const getLabelString = () => {
    let suiteString = fetchSuiteInfo(meeting.suiteId)?.fullName;
    let docketString = fetchDocketInfo(meeting.docketId)?.title;

    let labelString = suiteString;
    if (docketString) {
      labelString += `> ${docketString}`;
    }
    return labelString ?? "Meeting";
  };
  return (
    <AttentionActionListItem
      actionLabel={getLabelString()}
      actionTheme="secondary"
      actionOriginatorId={meeting?.meetingJSON?.createdBy}
      actionTitle={meeting.meetingJSON.summary}
      SecondaryComponent={<ShowMeetingTimeComponent meeting={meeting} />}
      TertiaryComponent={
        <ProfilesChipsList
          dense
          usersList={meeting?.meetingJSON?.members}
          maxItems={4}
        />
      }
      actionButton={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              alignItems: "center",
            }}
          >
            {!pastMeetingFlag && (
              <AddToCalendarButton
                appointmentData={convertToSchedulerFormat(meeting)}
                iconOnly
              />
            )}
            <RSVPHandlerChip
              rsvpStatus={userRsvpStatus}
              handleRSVPChange={handleRSVPChange}
              pastMeetingFlag={pastMeetingFlag}
            />
          </Stack>
        </Box>
      }
    />
  );
};

export default RsvpMeetingCard;
