// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Box,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { getFileIcon, UserAvatar } from "blocks/atoms";
import { format } from "date-fns";
import {
  useHandleAddRemoveIdFromRoute,
  useHandleRouterPush,
} from "@app21/core";
import { useRouter } from "next/router";
import { useIsMobileBreakpoint } from "hooks";
import clip from "text-clipper";
import GridViewFileCard from "./GridViewFileCard";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";
import { getDateTimeFormat } from "utils/getDateFormats";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 0,
    borderRadius: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.7rem",
    padding: 3,
  },
}));
const getRandomInt = (min, max) => {
  return min + Math.floor(Math.random() * Math.floor(max - min));
};

const DataSuiteFileActivitySummaryPanel = ({ viewType = "listView" }) => {
  const {
    currentFilesInSuite = [],
    fileAnalyticsMap = [],
    isSuiteManager = false,
  } = useDashboardViewsDataContext();
  const [period, setPeriod] = React.useState("week");
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const { loadRoute } = useHandleRouterPush();
  const router = useRouter();
  const isMobile = useIsMobileBreakpoint();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  page > 0
    ? Math.max(0, (1 + page) * rowsPerPage - currentFilesInSuite.length)
    : 0;

  const handlePeriod = (event, newPeriod) => {
    if (newPeriod !== null) {
      setPeriod(newPeriod);
    }
  };
  const handleActivityRowClick = (activity) => {
    let newRoutePath = addIdToRoute({
      paramKey: "viewFile",
      paramValue: activity.key,
    });
    router.push(newRoutePath, newRoutePath, { shallow: true });
  };

  if (!Boolean(currentFilesInSuite?.length)) {
    return (
      <FlowBox>
        <Typography variant="body1" sx={{ mt: 2 }}>
          There are no recent file additions in your suite.
        </Typography>
        {isSuiteManager && (
          <Button
            variant="outlined"
            onClick={() => loadRoute("VIEW-DATAROOM", {})}
            sx={{ mt: 2, alignSelf: "flex-start" }}
          >
            Get Started
          </Button>
        )}
      </FlowBox>
    );
  }
  if (viewType === "gridView") {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {currentFilesInSuite
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((fileObj, index) => (
              <GridViewFileCard
                key={index}
                fileObj={fileObj}
                fileMap={fileAnalyticsMap}
                isSuiteManager={isSuiteManager}
                handleFileView={handleActivityRowClick}
              />
            ))}
        </Box>
        {currentFilesInSuite?.length > 10 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={currentFilesInSuite?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: 2 }}
          />
        )}
      </Box>
    );
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <ToggleButtonGroup
            size={"small"}
            value={period}
            exclusive
            onChange={handlePeriod}
            sx={{ m: 1 }}
            aria-label="Analytics Period"
          >
            <ToggleButton value="week" aria-label="7 Days">
              7 Days
            </ToggleButton>
            <ToggleButton value="month" aria-label="30 Days">
              30 Days
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Box>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
            >
              <StyledTableCell
                sx={{ maxWidth: 50 }}
                style={{ boxShadow: "inset 0px 0 0 #fff" }}
              ></StyledTableCell>
              <StyledTableCell>Document Name</StyledTableCell>
              {!isMobile && isSuiteManager && (
                <StyledTableCell sx={{ maxWidth: 75 }} align="center">
                  Uploader
                </StyledTableCell>
              )}
              <StyledTableCell sx={{ maxWidth: 75 }} align="center">
                Date
              </StyledTableCell>
              {isSuiteManager && (
                <StyledTableCell
                  sx={{ maxWidth: 75 }}
                  align="center"
                  style={{ boxShadow: "inset 0px 0 0 #fff" }}
                >
                  Views
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentFilesInSuite
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((activity, index) => (
                <TableRow
                  hover
                  key={index}
                  onClick={() => handleActivityRowClick(activity)}
                  sx={{
                    cursor: "pointer",
                    p: 0,
                  }}
                >
                  <StyledTableCell component="th" scope="row" align="center">
                    {getFileIcon({
                      fileExt: activity?.fileType || "file",
                      size: "lg",
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {clip(activity.fileName, isMobile ? 18 : 40, {
                      breakWords: true,
                    })}
                  </StyledTableCell>
                  {!isMobile && isSuiteManager && (
                    <StyledTableCell component="th" scope="row" align="center">
                      <UserAvatar
                        userId={activity.uploadedBy}
                        style={{ margin: "auto" }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell component="th" scope="row" align="center">
                    {activity.lastUpdated
                      ? format(
                          new Date(activity?.lastUpdated ?? null),
                          getDateTimeFormat({
                            startDate: activity?.lastUpdated,
                          })
                        )
                      : " - "}
                  </StyledTableCell>
                  {isSuiteManager && (
                    <StyledTableCell component="th" scope="row" align="center">
                      {fileAnalyticsMap && fileAnalyticsMap.has(activity.key)
                        ? fileAnalyticsMap.get(activity.key).totalViews
                        : "-"}
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {currentFilesInSuite?.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={currentFilesInSuite?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default DataSuiteFileActivitySummaryPanel;
