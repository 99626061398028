// // !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// export { ChatProvider, useChatContext, ChatContext } from 'providers/ChatProvider';
// export { SettingsProvider, useSettingsContext } from 'providers/SettingsProvider';
// export * from 'providers/ChatProvider';
// export * from 'providers/SettingsProvider';
// export * from 'providers/LayoutProvider';
// export { default as ChatProvider } from 'providers/ChatProvider';
// export { default as LayoutProvider } from 'providers/LayoutProvider';
// export * from 'providers/LayoutProvider';
// export * from 'providers/ChatProvider';
export { useFilesContext, default as FilesProvider } from "./FilesProvider";
export {
  useVideocallContext,
  VideocallProvider,
} from "./TrrystVideocallProvider";
export {
  useChatContext,
  default as TrrystChatProvider,
} from "./TrrystChatProvider";
export {
  useAPIErrorHandlerContext,
  default as APIErrorHandlerProvider,
} from "./APIErrorHandlerProvider";
export {
  useOrgsAndSuitesInfoContext,
  default as OrgsAndSuitesInfoProvider,
} from "./OrgsAndSuitesInfoProvider";
export {
  default as DashboardViewsDataProvider,
  useDashboardViewsDataContext,
} from "./DashboardViewsDataProvider";
