// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { useAppContext, useHandleAddRemoveIdFromRoute } from "@app21/core";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useRouter } from "next/router";
import _ from "lodash";

export function useHandleLeaveVideocall() {
  const {
    setIsUserTriggeredConference,
    setChimeRoute,
    setIsRecordingBotEnabled,
    // setActiveBackgroundImageBlob,
    // setBlurStrength,
    setIsRecording,
    setIsCollaborationActive,
    setEndMeetingForAllReceived,
  } = useVideocallContext();

  const { setChimeViewMode } = useAppContext();
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();
  const router = useRouter();

  async function leaveVideocall({ meetingManager = null }) {
    setChimeViewMode("normal");
    setIsRecordingBotEnabled(false);
    setIsUserTriggeredConference(false);
    setChimeRoute("ended");
    setIsRecording(false);
    setIsCollaborationActive(false);
    setEndMeetingForAllReceived(false);
    if (meetingManager) await meetingManager.leave();
    let pushToPath = removeIdFromRoute({ paramKey: "meetingId" });

    //router.query might have an action entry which needs to be added back if relevant

    if (_.isEqual(Object.keys(router.query), ["action"]))
      pushToPath.query = { ...pushToPath.query, ...router?.query };
    router.push(pushToPath);
  }
  return { leaveVideocall };
}
