// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useRouter } from "next/router";
import { Typography, Link, ListItem } from "@mui/material";
import {
  useSuite,
  useHandleRouterPush,
  useHandleAddRemoveIdFromRoute,
} from "@app21/core";

import path from "path";
import toast from "react-hot-toast";

export const SuiteLinkSnippet = ({ suiteId }) => {
  const router = useRouter();

  const {
    data: suiteInfo,
    status: suiteInfoStatus,
    apiError: suiteInfoApiError,
  } = useSuite(suiteId);

  React.useEffect(() => {
    if (suiteInfoApiError && suiteInfoStatus != "loading") {
      toast.error(suiteInfoApiError.message);
    }
  }, [suiteInfoApiError]);
  return (
    <ListItem>
      <Link component="button" onClick={() => router.push("/")}>
        <Typography variant="body2">{suiteInfo?.fullName}</Typography>
      </Link>
    </ListItem>
  );
};

export const DocketLinkSnippet = ({ docket }) => {
  const { loadRoute } = useHandleRouterPush();

  return (
    <ListItem
      button
      onClick={() => loadRoute("VIEW-DOCKET", { docketId: docket._id })}
    >
      <Typography variant="body2">{docket?.title} </Typography>
    </ListItem>
  );
};

export const FileLinkSnippet = ({ fileId }) => {
  const router = useRouter();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();

  const fileName = path.basename(decodeURIComponent(fileId));
  return (
    <ListItem
      button
      onClick={() => {
        let newRoutePath = addIdToRoute({
          paramKey: "viewFile",
          paramValue: fileId,
        });
        router.push(newRoutePath, newRoutePath, { shallow: true });
      }}
    >
      <Typography variant="body2">{fileName} </Typography>
    </ListItem>
  );
};
