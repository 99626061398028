// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import theme from "styles/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// hooks
import GlobalStyles from "./globalStyles";
import { CssBaseline } from "@mui/material";

export default function ThemeConfig({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />

        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
