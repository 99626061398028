import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useAtom } from "jotai";
import {
  showTasksSidebarFlagAtom,
  selectedTasksListAtom,
  selectedTaskActionAtom,
  viewTaskContextAtom,
  viewTasksFilterContextAtom,
  selectedBreakoutsInTaskFiltersAtom,
} from "blocks/modules/Tasks/tasks-atoms";
import { useQueryClient } from "react-query";
import {
  useUsersInSuite,
  useAppContext,
  useSuite,
  useDockets,
  executeApi,
  useSelectedBucketId,
  useHandleRouterPush,
  checkIfNull,
  useTasks,
  getRolePrivileges,
  useHandleTaskActions,
  useDocketsInSuite,
} from "@app21/core";

import { useMediaQuery, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import { processTasksData } from "blocks/modules/Tasks/foundations/processTasksData";

const TasksContext = React.createContext({});

export const useTasksContext = () => {
  const state = useContext(TasksContext);
  if (!state) {
    throw new Error("useTasksContext must be used within TasksProvider");
  }
  return state;
};
const TasksProvider = ({
  viewContext = "suite",
  actionContext = "list",
  isMobile = false,
  children,
}) => {
  const {
    meetingId,
    checkAccessRules,
    userInfo = {},
    chimeViewMode,
    viewFile,
    selectedSuiteId,
    selectedDocketId,
    taskId: selectedTaskId,
  } = useAppContext();

  const confirm = useConfirm();
  const theme = useTheme();
  const [viewTasksFilterContext, setViewTasksFilterContext] = useAtom(
    viewTasksFilterContextAtom
  );
  const [selectedBreakoutsInTaskFilters, setSelectedBreakoutsInTaskFilters] =
    useAtom(selectedBreakoutsInTaskFiltersAtom);

  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const {
    data: baseDockets,
    status: baseDocketsStatus,
    apiError: baseDocketsApiError,
  } = useDocketsInSuite(selectedSuiteId);
  const selectedBucketId = useSelectedBucketId(null, true);

  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);

  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(selectedSuiteId);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const { handleTaskActions } = useHandleTaskActions();
  const isDocket = viewContext === "docket" || Boolean(selectedDocketId);
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;

  const [selectedTaskUniqueMembers, setSelectedTaskUniqueMembers] =
    React.useState(null);

  const contextId = isDocket ? selectedDocketId : selectedSuiteId;
  const { data: fetchedTasks, status: fetchedTasksStatus } = useTasks({
    objectId: contextId,
    idType: isDocket ? "docket" : "mytasks",
  });

  const processedTasksInfo = React.useMemo(() => {
    return processTasksData({
      fetchedTasks,
      viewContext,
      contextId,
      usersInSuite,
      userInfo,
      theme,
    });
  }, [fetchedTasks, viewContext, contextId, usersInSuite, userInfo]);

  React.useEffect(() => {
    if (selectedDocketId) {
      setViewTasksFilterContext(["breakoutsFilter"]);
      setSelectedBreakoutsInTaskFilters([selectedDocket]);
    }
  }, [selectedDocketStatus]);

  React.useEffect(() => {
    if (selectedTaskId) {
      const task = fetchedTasks?.allTasks?.find(
        (task) => task._id === selectedTaskId
      );
      setSelectedTask(task);
      let selectedTaskAssignees = task?.assignees ?? [];

      setSelectedTaskUniqueMembers(
        task ? [...new Set([task.createdBy, ...selectedTaskAssignees])] : []
      );
    } else {
      setSelectedTask(null);
      setSelectedTaskUniqueMembers(null);
    }
  }, [selectedTaskId, fetchedTasks, fetchedTasksStatus]);

  const { loadRoute } = useHandleRouterPush();

  const fileViewerFlag = !checkIfNull(viewFile);

  const [selectedTasksList, setSelectedTasksList] = useAtom(
    selectedTasksListAtom
  );
  const [selectedTaskAction, setSelectedTaskAction] = useAtom(
    selectedTaskActionAtom
  );
  const [showTasksSidebarFlag, setShowTasksSidebarFlag] = useAtom(
    showTasksSidebarFlagAtom
  );
  const [viewTaskContext, setViewTaskContext] = useAtom(viewTaskContextAtom);

  const isCreator =
    viewContext === "DOCKET"
      ? selectedDocket?.createdBy === userInfo?._id
      : false;
  const isInvitee =
    viewContext === "DOCKET"
      ? (selectedDocket?.members ?? []).includes(userInfo?._id)
      : false;
  const xsSize = useMediaQuery("(min-width:700px)");
  const mdSize = useMediaQuery("(min-width:1200px)");

  const smallSizeFlag =
    !xsSize || (!mdSize && chimeViewMode === "normal" && Boolean(meetingId));

  const clipTextLength = smallSizeFlag || viewContext !== "DOCKET" ? 10 : 30;

  const { accessFlag: canAddNew } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "ADD-DOCKET-ACTIONS" : "ADD-ACTIONS",
    featureName: isDocket ? "DOCKET-ACTIONS" : "ACTIONS",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });

  const checkCanEdit = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "DOCKET" : "SUITE",
      action: isDocket ? "UPDATE-RESOLUTION" : "UPDATE-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const checkCanDelete = (rowData) =>
    checkAccessRules({
      entity: viewContext === "docket" ? "DOCKET" : "ACTIONS",
      action:
        viewContext === "docket" ? "DELETE-DOCKET-ACTIONS" : "DELETE-ACTIONS",
      featureName: viewContext === "docket" ? "DOCKET-ACTIONS" : "ACTIONS",
      ...getRolePrivileges({
        userId: userInfo?._id,
        baseObject: rowData,
        members: rowData?.assignees ?? [],
      }),
    })?.accessFlag;

  const checkCanView = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "DOCKET" : "SUITE",
      action: isDocket ? "VIEW-DOCKET-ACTIONS" : "VIEW-ACTIONS",
      featureName: isDocket ? "DOCKET-ACTIONS" : "ACTIONS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const tasksPermissions = {
    canAddNew,
    checkCanView,
    checkCanEdit,
    checkCanDelete,
    default: true,
  };
  const fetchDownloadFileUrl = React.useCallback(
    async (file, protectFlag, protectText) => {
      let downloadUrlResponse = protectFlag
        ? await executeApi("PROTECT-FILE-S3", {
            bucket: selectedBucketId,
            key: file.id,
            calledBy: userInfo?._id,
          })
        : await executeApi("FETCH-SIGNED-URL", {
            bucket: selectedBucketId,
            key: file.id,
            urlTypeRequested: "get",
            contentDisposition: "attachment",
            contentType: file.fileType, //FIXME: Raghu - can you check this.. content type is not there here..
          });

      return downloadUrlResponse;
    },
    [selectedBucketId, userInfo?._id]
  );
  React.useEffect(() => {
    if (selectedDocketId) {
      setViewTaskContext("showdockets");
    }
  }, [selectedDocketId]);

  const handleTaskCreate = async (taskId, formData) => {
    await handleTaskActions(
      { action: "ADD-TASK", taskId: taskId, taskData: formData },
      null,
      null
    );
  };

  const handleTaskDelete = async (rowData) => {
    if (rowData) {
      confirm({
        description: "Do you want to delete the task?",
      })
        .then(async () => {
          await handleTaskActions(
            { action: "DELETE-TASK", taskId: rowData._id },
            null,
            null
          );
          toast.success("Task has been deleted");
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    }
  };

  const handleTaskView = (taskId) => {
    if (isDocket) {
      loadRoute("VIEW-DOCKETTASK", { taskId: taskId });
    } else {
      loadRoute("VIEW-TASK", { taskId: taskId });
    }
  };
  const handleTaskEdit = async (taskId) => {
    if (isDocket) {
      loadRoute("EDIT-DOCKETTASK", { taskId: taskId });
    } else loadRoute("EDIT-TASK", { taskId: taskId });
  };

  const handleTaskSave = async (formData, taskId) => {
    await handleTaskActions(
      {
        action: "UPDATE-TASK",
        taskId: taskId ?? selectedTaskId,
        taskData: formData,
      },
      null,
      null
    );
  };

  const tasksContextValues = {
    smallSizeFlag,
    viewContext,
    actionContext,
    isMobile,
    isCreator,
    isInvitee,
    fileViewerFlag,
    loadRoute,
    meetingId,
    xsSize,
    mdSize,
    baseDockets,
    baseDocketsStatus,
    selectedDocketStatus,
    selectedDocket,
    selectedSuite,
    selectedSuiteStatus,
    isDocket,
    isDocketOwner,
    usersInSuite,
    usersInSuiteStatus,
    tasksPermissions,
    selectedTaskId,
    selectedTask,
    selectedTaskUniqueMembers,
    fetchedTasks,
    fetchedTasksStatus,
    processedTasksInfo,
    handleTaskActions,
    handleTaskCreate,
    handleTaskDelete,
    handleTaskEdit,
    handleTaskView,
    handleTaskSave,
  };

  return (
    <TasksContext.Provider value={{ ...tasksContextValues }}>
      {children}
    </TasksContext.Provider>
  );
};
export default TasksProvider;
