import { COLORPALETTE } from "blocks/atoms/constants/appColors";
import { contrastColor } from "contrast-color";
import { getNumberFromString } from "utils/getNumberFromString";

export const getBackgroundColorFromString = (string) => {
  //stringToColor(string);
  let colorNum = getNumberFromString(string, COLORPALETTE.length ?? 255);
  return COLORPALETTE[colorNum];
};

export const getContrastColorFromString = (string) =>
  contrastColor({ bgColor: getBackgroundColorFromString(string) });
