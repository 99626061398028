// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button } from "@mui/material";
import { useHandleRouterPush } from "@app21/core";

import { AttentionActionListItem } from "blocks/atoms";

const SurveyResponseNotificationCard = ({ survey }) => {
  const { loadRoute } = useHandleRouterPush();

  return (
    <AttentionActionListItem
      actionTitle={survey?.survey?.decisionName}
      actionOriginatorId={survey?.createdBy}
      //SecondaryComponent={<ShowMeetingTimeComponent />}
      actionLabel={"Survey"}
      actionTheme="info"
      actionButton={
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 1 }}
          onClick={() =>
            loadRoute("RESPOND-SURVEY", { decisionId: survey?._id })
          }
        >
          Respond
        </Button>
      }
    />
  );
};

export default SurveyResponseNotificationCard;
