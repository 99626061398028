// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { useQuery } from "react-query";

const getInfo = async (userId: string) => {
  const response = await (
    await fetch(`${process.env.AWS_APIPATH}/meetings/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calledBy: userId }),
    })
  ).json();
  return response;
};

export const useUserMeetings = (userId: string) => {
  const result = useQuery(
    ["allMeetings", `${userId}`],
    async () => await getInfo(userId)
  );
  return result;
};
