// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useRouter } from "next/router";
import { Button, Typography } from "@mui/material";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { useAppContext, useUsersInSuite } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";

const AccessCheckWrapper = ({ children }) => {
  const router = useRouter();

  const { selectedSuiteId, userInfo } = useAppContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);

  if (usersInSuiteStatus === "loading") {
    return (
      <LoadingSpinner
        flowScreenFlag={true}
        variant="exp"
        randomSeedString="AccessCheckWrapper"
      />
    );
  } else if (
    usersInSuiteStatus === "success" &&
    router.pathname.startsWith("/user/organization/suite")
  ) {
    if (usersInSuite?.some((user) => user._id === userInfo._id)) {
      return children;
    } else
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "80vh",
            width: "80vw",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Typography variant="h6">
            You do not have access to this suite
          </Typography>
          <Button
            onClick={() => {
              router.push("/user");
            }}
            variant="contained"
            color="primary"
            sx={{ my: 3 }}
          >
            Back to Main Dashboard
          </Button>
        </div>
      );
  } else return children;
};

export default AccessCheckWrapper;
