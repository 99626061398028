// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import SimpleTimelineItem from "blocks/views/Notifications/foundations/SimpleTimelineItem";
import { getS3ImageWrapper } from "@app21/core";

const MeetingCompleted = ({ timelineItem, viewContext, index }) => {
  const { payload } = timelineItem;
  let dummyPicture = `images/suitestockimages/suiteImage-5.jpg`;

  let fileSummaryInfo;
  if (payload?.files?.length > 1) {
    fileSummaryInfo = `${payload.files?.length} files.`;
  } else {
    fileSummaryInfo = `${payload?.files && payload?.files[0]?.fileName}`;
  }
  const data = {
    message: `${payload?.uploadingUserFullName || "Suite Member"} has added ${
      fileSummaryInfo || "a file"
    }`,
    avatar: getS3ImageWrapper(payload?.uploadingUserImage || dummyPicture),
    subHeader: timelineItem?.createdAt,
  };
  return (
    <SimpleTimelineItem
      data={data}
      timelineItem={timelineItem}
      viewContext={viewContext}
      index={index}
    />
  );
};

export default MeetingCompleted;
