// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Box,
  Tooltip,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { UserAvatar, Label, AvatarGroupWithMore } from "blocks/atoms";
import { useDashboardViewsDataContext } from "providers/DashboardViewsDataProvider";

const MembersPanel = ({ sx = {} }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { usersInSuite } = useDashboardViewsDataContext();

  return (
    <Card sx={{ flexGrow: 1, height: "100%", ...sx }}>
      <CardHeader
        sx={{ pt: 1 }}
        disableTypography
        title={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="overline"
              color="primary.darker"
              sx={{ lineHeight: 2 }}
            >
              {`${t("SuiteDashboard.MembershipBox.membershipSubtitle")}  `}
            </Typography>
            <Label variant="ghost" color="primary" sx={{ ml: 1, p: 2 }}>
              {usersInSuite?.length}
            </Label>
          </Box>
        }
      />
      <CardContent sx={{ pt: 1, pb: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          {/*<Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            ({' '}
            <Typography variant="h6" color="textSecondary" style={{ marginRight: 5 }}>
              {added}
            </Typography>
            <AddCircle style={{ fontSize: 12, marginRight: 10, color: 'green' }} />
            <Typography variant="h6" color="textSecondary" style={{ marginRight: 5 }}>
              {removed}
            </Typography>
            <RemoveCircle color="error" style={{ fontSize: 12 }} /> )
      </Box>*/}
        </Box>
      </CardContent>
      <CardActions sx={{ pl: 2 }}>
        <AvatarGroupWithMore
          max={9}
          sx={{ "& .MuiAvatar-root": { width: 32, height: 32 } }}
        >
          {usersInSuite?.map((user, index) => (
            <UserAvatar key={index} size={35} userId={user?._id} />
          ))}
        </AvatarGroupWithMore>
      </CardActions>
    </Card>
  );
};

export default MembersPanel;
