// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from "react";
import {
  useMeetingManager,
  useNotificationDispatch,
  Severity,
  ActionType,
} from "amazon-chime-sdk-component-library-react";
import { useRouter } from "next/router";
import { useHandleAddRemoveIdFromRoute } from "@app21/core";
import { useVideocallContext } from "../VideocallProvider";

const NoMeetingRedirect = ({ children }) => {
  const dispatch = useNotificationDispatch();
  const meetingManager = useMeetingManager();
  const router = useRouter();
  const { chimeRoute, setChimeRoute } = useVideocallContext();
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  const payload = {
    severity: Severity.INFO,
    message: "No meeting found, please enter a valid meeting Id",
    autoClose: true,
  };

  useEffect(() => {
    if (!meetingManager.meetingSessionConfiguration && chimeRoute !== "base") {
      setChimeRoute("ended");
      dispatch({
        type: ActionType.ADD,
        payload: payload,
      });
      //router.push(removeIdFromRoute({ paramKey: "meetingId" }));
    }
  }, []);

  return <>{children}</>;
};

export default NoMeetingRedirect;
